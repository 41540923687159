import React, { forwardRef, useContext, Fragment, useState, useEffect } from "react";
import Axios from "axios";
import { AuthContext } from "../../Helpers/AuthContext";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";


import Heading from "../Heading";
import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ExportToExcel } from './Read/ExportToExcel'

function Read() {
  const { authState, setAuthState } = useContext(AuthContext);
  
  const [studentList, setStudentList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const fileName = `Student with FMT status`;
  useEffect(() => {
    setStudentList([]);
    setExcelData([]);
    let readFMTStudent = new FormData();
    readFMTStudent.append("currentUserRole", authState.role);
    readFMTStudent.append("currentUserID", authState.id);
    
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}FMTStudent/read.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: readFMTStudent,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setStudentList(result.data);
        let newArray = [];
        result.data.map((item) => {
          let obj = {
            "Student Code": item.studentCode,
            "English Name": item.englishName,
            "Chinese Name": item.chineseName,
            "FMT/HP": item.FMTText,
            "Status": item.status,
            "Guardian Phone Number": item.guardianPhoneNumber,
            "Age": item.age,
            "Class Code": item.classCode,
            "Attendance Taken Count": item.attendanceCount,
            "Attendance Date": item.attendanceDate,
            "Enrolment Teacher": item.enrolmentTeacherName,
            "Teacher Name": item.teacherName,
            "Trainer Name": item.trainerName,
            "Business Type": item.businessType,
            "School Name": item.schoolName,
            "Center Name": item.centerName
          };
          newArray.push(obj);
        });
        setExcelData(newArray);
      }
    });
  }, []);

  const columns = [
    { title: "Student Code", field: "studentCode", editable: "never" },
    { title: "English Name", field: "englishName", editable: "never" },
    { title: "Chinese Name", field: "chineseName", editable: "never" },
    { title: "FMT/HP", field: "FMTText", editable: "never" },
    { title: "Status", field: "status", editable: "never" },
    { title: "Guardian Phone Number", field: "guardianPhoneNumber", editable: "never" },
    { title: "Age", field: "age", editable: "never" },
    { title: "Class Code", field: "classCode", editable: "never" },
    { title: "Attendance Taken Count", field: "attendanceCount", editable: "never" },
    { title: "Attendance Date", field: "attendanceDate", editable: "never" },
    { title: "Taken Care ET", field: "enrolmentTeacherName", editable: "never" },
    { title: "Teacher Name", field: "teacherName", editable: "never" },
    { title: "Trainer Name", field: "trainerName", editable: "never" },
    { title: "Business Type", field: "businessType", editable: "never" },
    { title: "School Name", field: "schoolName", editable: "never" },
    { title: "Center Name", field: "centerName", editable: "never" },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readFMTStudentList">
          <Container fluid className="rounded bg-searchbox mb-3">
            <Row className="p-2">
              <Col sm={12} className="mb-1">
                <Heading content="Export to excel" design="h5" />
              </Col>
              <Col sm={12} className="mb-12">
                <ExportToExcel apiData={excelData} fileName={fileName} />
              </Col>
            </Row>
          </Container>
          <MaterialTable
            title=""
            columns={columns}
            data={studentList}
            icons={tableIcons}
            key={studentList.length}
            options={{
              sorting: true,
              filtering: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              paging: true,
              pageSize: 25,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [25, 50, 75, 100],
              exportButton: true,
              exportAllData: true,
              toolbar: true,
              exportFileName: "FMT Student List",
              //actionsColumnIndex: -1,
              selection: false,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: true,
              tableLayout: "auto",
              rowStyle: {
                overflowWrap: "break-word",
              },
              headerStyle: { position: "sticky", top: 0, zIndex: 1 },
              maxBodyHeight: "75vh",
            }}
          />

        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;