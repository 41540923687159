import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import { BiUndo } from "react-icons/bi";
import { toast } from "react-toastify";

import Heading from "../Heading";
import ClassCode from "../ClassCode";
import Class from "./Update/Class";
import Student from "./Update/Student";

function Update() {
  const navigate = useNavigate();
  const { classID, classDate } = useParams();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("class");
  const [loading, setLoading] = useState(false);
  const [canEdit,setCanEdit] = useState(false);
  const currentDate = moment();

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    classID: classID,
    attendanceID: [],
    classCode: "",
    teacherID: "",
    trainerID: "",
    studentList: [],
    startTime: "00:00",
    endTime: "00:00",
    classDate: classDate,
  });

  useEffect(() => {
    let updateAttendance = new FormData();
    updateAttendance.append("userID", authState.id);
    updateAttendance.append("classID", classID);
    updateAttendance.append("classDate", classDate);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/update.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updateAttendance,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setSubmitValue((submitValue) => ({
          ...submitValue,
          attendanceID: result.data.attendanceID,
          classCode: result.data.classCode,
          teacherID: result.data.attendanceInfoArr[0].teacherID,
          trainerID: result.data.attendanceInfoArr[0].trainerID,
          studentList: result.data.studentArr,
          startTime: result.data.attendanceInfoArr[0].startTime,
          endTime: result.data.attendanceInfoArr[0].endTime,
          createdDate: result.data.createdDate,
        }));

        setAttendanceArr(result.data.attendanceArr);
        setLearningProgressArr(result.data.learningProgressArr);
        setHomeworkArr(result.data.homeworkArr);
        setRemarkArr(result.data.remarksArr);
        if((authState.id === '6577' )|| (currentDate.diff(moment(classDate).format("YYYY-MM-DD"), 'days') <= 5  && authState.id === '17')|| currentDate.diff(moment(classDate).format("YYYY-MM-DD"), 'days') <= 3){
          setCanEdit(true);
        }
      }
    });
  }, [classID, classDate, authState.id]);

  //Attendance
  const [attendanceArr, setAttendanceArr] = useState([]);
  const studentAttendanceInfo = (e, index) => {
    let tempAttendance = attendanceArr;
    tempAttendance[index] = e.value;
    setAttendanceArr((tempAttendance) => [...tempAttendance]);
  };
  const studentAttendanceOverall = async (e, index) => {
    let tempAttendanceOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempAttendanceOverall.push(e.value);
      setAttendanceArr(tempAttendanceOverall);
    }
  };

  //Learning Progress
  const [learningProgressArr, setLearningProgressArr] = useState([]);
  const studentLearningProgressInfo = (e, index) => {
    let tempLearningProgress = learningProgressArr;
    tempLearningProgress[index] = e.value;
    setLearningProgressArr((tempLearningProgress) => [...tempLearningProgress]);
  };

  const studentLearningProgressOverall = async (e, index) => {
    let tempLearningProgressOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempLearningProgressOverall.push(e.value);
      setLearningProgressArr(tempLearningProgressOverall);
    }
  };

  //Homeowrk
  const [homeworkArr, setHomeworkArr] = useState([]);

  const studentHomeworkInfo = (e, index) => {
    let tempHomework = homeworkArr;
    tempHomework[index] = e.value;
    setHomeworkArr((tempHomework) => [...tempHomework]);
  };

  const studentHomeworkOverall = async (e, index) => {
    let tempHomeworkOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempHomeworkOverall.push(e.value);
      setHomeworkArr(tempHomeworkOverall);
    }
  };

  //Remarks
  const [remarkArr, setRemarkArr] = useState([]);
  const studentRemarkInfo = (e, index) => {
    let tempRemark = remarkArr;
    tempRemark[index] = e.target.value;
    setRemarkArr((tempRemark) => [...tempRemark]);
  };
  const studentRemarkOverall = async (e, index) => {
    let tempRemarkOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempRemarkOverall.push(e.target.value);
      setRemarkArr(tempRemarkOverall);
    }
  };

  let submitBtn = false;
  if (attendanceArr.length > 0 && learningProgressArr.length > 0) {
    if (
      attendanceArr.length === submitValue.studentList.length &&
      learningProgressArr.length === submitValue.studentList.length
    ) {
      const isUndefined = (arr) => arr.every((e) => e !== undefined);

      for (var i = 0; i < attendanceArr.length; i++) {
        if (isUndefined(attendanceArr) && isUndefined(learningProgressArr)) {
          submitBtn = true;
        }
      }
    }
  }

  const submitForm = () => {
    setLoading(true);
    let submitAttendance = [];
    
    
    for (var i = 0; i < submitValue.studentList.length; i++) {
      submitAttendance.push({
        studentID: submitValue.studentList[i],
        attendanceID: submitValue.attendanceID[i],
        attendance: attendanceArr[i],
        learningProgress: learningProgressArr[i],
        homework: homeworkArr[i],
        remarks: remarkArr[i],
      });
    }
    const submitAttendanceJson = JSON.stringify(submitAttendance);

    let updateStudent = new FormData();
    updateStudent.append("id", submitValue.id);
    updateStudent.append("classID", submitValue.classID);
    updateStudent.append("classCode", submitValue.classCode);
    updateStudent.append("teacherID", submitValue.teacherID);
    updateStudent.append("trainerID", submitValue.trainerID);
    updateStudent.append("startTime", submitValue.startTime);
    updateStudent.append("endTime", submitValue.endTime);
    updateStudent.append("classDate", submitValue.classDate);
    updateStudent.append("studentAttendance", submitAttendanceJson);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/updateID.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updateStudent,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);

        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);

        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate(`/attendance`, {state:{ classCode: submitValue.classCode }})
          //navigate("/attendance"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createAttendance" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content={`Update Attendance`} design="h3" />
              <Heading content={<ClassCode id={classID} />} design="h5" />
              {}
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/attendanceSearch">
                  <BiUndo /> Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="class" title="Class">
                <Class
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                  setKey={setKey}
                />
              </Tab>
              <Tab eventKey="student" title="Student">
                <Student
                  attendanceArr={attendanceArr}
                  studentAttendanceOverall={studentAttendanceOverall}
                  studentAttendanceInfo={studentAttendanceInfo}
                  learningProgressArr={learningProgressArr}
                  studentLearningProgressOverall={
                    studentLearningProgressOverall
                  }
                  studentLearningProgressInfo={studentLearningProgressInfo}
                  homeworkArr={homeworkArr}
                  studentHomeworkOverall={studentHomeworkOverall}
                  studentHomeworkInfo={studentHomeworkInfo}
                  remarkArr={remarkArr}
                  studentRemarkOverall={studentRemarkOverall}
                  studentRemarkInfo={studentRemarkInfo}
                  submitValue={submitValue}
                  setKey={setKey}
                />
              </Tab>
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
              { canEdit ? (
                <>
                  { loading ? (
                    <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      <span>Processing...</span>
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={submitForm}
                      disabled={!submitBtn}
                    >
                      Submit{" "}
                    </Button>
                  )}
                  </>
                ) : (
                  null
                )}


                
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Update;
