import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Select from "react-select";
import Axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import Gender from "../../../Data/Gender";
import ClassType from "../../../Data/ClassType";
import Category from "../../../Data/StudentCategory";
import Currency from "currency-codes/data";
import Status from "../../../Data/Status";
import StopStatus from "../../../Data/StopStatus";
import StudentStatus from "../../../Data/StudentStatus";

import { BiChevronLeft } from "react-icons/bi";

function Student({ submitValue, setSubmitValue, setKey }) {
  const optionInput = "form-select";
  const textInput = "form-control";
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolStateOptions, setSchoolStateOptions] = useState([]);

  let classTypeOptions = [];
  ClassType.map((item) => {
    classTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let categoryOptions = [];
  Category.map((item) => {
    categoryOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });
  let currencyOptions = [];
  Currency.map((item) => {
    currencyOptions.push(
      <option value={item.code} key={item.code}>
        {item.currency} ({item.code})
      </option>
    );
  });

  let statusOptions = [];
  Status.map((item) => {
    statusOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let studentStatusOptions = [];
  StudentStatus.map((item) => {
    studentStatusOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/school.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/schoolState.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setSchoolOptions(
          result[0].data.map(({ id, name, state }) => ({
            label: name,
            id: id,
            state: state,
          }))
        );

        setSchoolStateOptions(
          result[1].data.map(({ state }) => ({
            label: state,
            value: state,
          }))
        );
      })
    );
  }, [submitValue.classID]);

  let schoolExistList = [];
  schoolOptions.map((item) => {
    schoolExistList.push(<option value={item.label} />);
  });

  const collectionFee = event => {
    if (event.target.checked) {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        collectByTeacher: "1",
      }))
    } else {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        collectByTeacher: "0",
      }))
    }
  };



  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">English Name</label>
            <input
              type="text"
              className={textInput}
              name="englishName"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  englishName: e.target.value.toUpperCase(),
                }))
              }
              value={submitValue.englishName}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Chinese Name</label>
            <input
              type="text"
              className={textInput}
              name="chineseName"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  chineseName: e.target.value.toUpperCase(),
                }))
              }
              value={submitValue.chineseName}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Alternative Phone Number</label>
            <PhoneInput
              international
              countryCallingCodeEditable={true}
              defaultCountry="MY"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  kidPhoneNumber: e,
                }))
              }
              value={submitValue.kidPhoneNumber}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Gender</label>
            <Select
              options={Gender}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  gender: e.value,
                }))
              }
              value={Gender.find((obj) => obj.value === submitValue.gender)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Age</label>
            <input
              type="number"
              min="1"
              className={textInput}
              name="age"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  age: e.target.value,
                }))
              }
              value={submitValue.age}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <label className="form-label required">Type</label>
              <label className="form-label required">Category</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    classType: e.target.value,
                  }))
                }
                value={submitValue.classType}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {classTypeOptions}
              </select>

              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    category: e.target.value,
                  }))
                }
                value={submitValue.category}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {categoryOptions}
              </select>
            </div>
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <label className="form-label required">Currency</label>
              <label className="form-label required">Course Fee</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    currency: e.target.value,
                  }))
                }
                value={submitValue.currency}
              >
                {currencyOptions}
              </select>
              <input
                type="number"
                min="0"
                step=".01"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    courseFee: e.target.value,
                  }))
                }
                value={submitValue.courseFee}
              />
            </div>
            <input type="checkbox"  checked={submitValue.collectByTeacher === "1"}
            onChange={collectionFee}
            /> Course fee will be collect by teacher
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">FMT/HP Status</label>
            <Select
              options={Status}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  tempStudentStatus: e.value,
                }))
              }
              value={Status.find(
                (obj) => obj.value == submitValue.tempStudentStatus
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">School State</label>
            <Select
              options={schoolStateOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  schoolState: e.value,
                }))
              }
              value={schoolStateOptions.find(
                (obj) => obj.value === submitValue.schoolState
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">School Name</label>
            <input
              type="text"
              className={textInput}
              list="schoolListOptionInStudent"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  schoolName: e.target.value,
                }))
              }
              value={submitValue.schoolName}
            />
            <datalist id="schoolListOptionInStudent">
              {schoolExistList}
            </datalist>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Status</label>
            <Select
              options={StudentStatus}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  status: e.value,
                }))
              }
              value={StudentStatus.find(
                (obj) => obj.value === submitValue.status
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Stop</label>
            <Select
              options={StopStatus}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  stopStatus: e.value,
                }))
              }
              value={StopStatus.find(
                (obj) => obj.value === submitValue.stopStatus
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                <BiChevronLeft /> Previous
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Student;
