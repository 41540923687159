import React, { useState, useEffect } from "react";
import Axios from "axios";

function StudentLatestLearningProgress(props) {
  const userID = props.id;
  const [viewLearningProgress, setViewLearningProgress] = useState([]);

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentLatestLearningProgress.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {
       
        setViewLearningProgress(result.data.title);

        // if (.title == "") {
            if((result.data === undefined || result.data.length == 0)) {
                setViewLearningProgress("No Learning Progress Yet")
        } else {
            setViewLearningProgress(result.data)
        }
    });
  }, [userID]);

  return <>{viewLearningProgress}</>;
}

export default StudentLatestLearningProgress;