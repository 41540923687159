import React, { useContext, useEffect, useState, Fragment } from "react";
import Axios from "axios";
import { AuthContext } from "../../../Helpers/AuthContext";
import { toast } from "react-toastify";
import { TbReplace } from "react-icons/tb";
import ReactTooltip from "react-tooltip";
import Name from "../../Name";

function Replace({ classID, classDate, realteacherID }) {
  const { authState, setAuthState } = useContext(AuthContext);
  const [teacherID, setTeacherID] = useState("");

  useEffect(() => {
    let getInfo = new FormData();
    getInfo.append("classID", classID);
    getInfo.append("classDate", classDate);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/replacement.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: getInfo,
    }).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        setTeacherID(response.data[0].teacherID);
      }
    });
  }, [classID]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {realteacherID !== teacherID && (
        <>
          <TbReplace
            className="text-danger"
            data-tip
            data-for={`toolTip-${teacherID}`}
          />
          <ReactTooltip
            id={`toolTip-${teacherID}`}
            place="top"
            type="dark"
            effect="float"
          >
            <span><Name id={teacherID} /></span>
          </ReactTooltip>
        </>
      )}
    </AuthContext.Provider>
  );
}

export default Replace;
