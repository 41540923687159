import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import { Navbar } from "react-bootstrap";

import Logo from "../Logo";
import Name from "../Name";

import {
  BsPieChart,
  BsPeople,
  BsPerson,
  BsGrid3X3Gap,
  BsBook,
  BsBuilding,
  BsCheckSquare,
  BsShieldLock,
  BsBoxArrowRight,
  BsShield,
  BsBriefcase,
  BsWrench,
  BsWallet2,
  BsGift,
  BsPatchCheck
} from "react-icons/bs";

function Teacher() {
  const { authState, setAuthState } = useContext(AuthContext);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({
      id: "",
      role: "",
      phoneNumber: "",
      status: false,
    });
    window.location = `${process.env.REACT_APP_CLIENT_DOMAIN}`;
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
      <div className="sidebarfixed d-flex flex-column flex-shrink-0 pt-3 bg-theme text-white min-vh-100 h-100">
          <Navbar.Brand href="/" className="mx-auto">
            <Logo design="rounded-circle" width="40" height="40" />
            <span className="d-none d-sm-inline text-white">
              UkuBear Management
            </span>
          </Navbar.Brand>
          <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start mx-auto p-2">
            <li className="nav-item">
              <a
                href="/"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsPieChart className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline fs-6">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/student"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsBook className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Student</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/fmtstudent"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsPatchCheck className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">FMT/HP</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/class"
                className="nav-link link-light text-decoration-non align-middle px-0"
              >
                <BsPerson className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Class</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/attendance"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsCheckSquare className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Attendance</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/payment"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsWallet2 className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Payment</span>
              </a>
            </li>
          </ul>
          <hr />
          <div className="dropdown mx-auto pb-0 pb-sm-2">
            <a
              href="#"
              className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p className="ms-1 fw-bold d-none d-sm-inline">
                <Name id={authState.id} />{" "}
              </p>
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              <li>
                <a className="dropdown-item" href="/settings">
                  <BsWrench className="icon" /> Settings
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" onClick={logout}>
                  <BsBoxArrowRight className="icon" />
                  Sign Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Teacher;
