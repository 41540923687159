const RequestReceiptOptions= [

  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export default RequestReceiptOptions;
