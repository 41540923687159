import React from "react";
import { Row, Col } from "react-bootstrap";

import Heading from "../Heading";

function Card(props) {
  return (
    <Col lg={3} md={6} sm={4} className="mb-2 mb-md-4">
      <div className="card card-stats mb-4 mb-xl-0">
        <div className="card-body">
          <Row>
            <Col sm={9}>
              <Heading
                content={props.content}
                design="h6 card-title text-uppercase mb-0 text-truncate"
              />
              <span className="h2 font-weight-bold mb-0">{props.amount}</span>
            </Col>
            <Col className="col-auto d-flex align-items-center bg-bear rounded-circle shadow opacity-75">
              {props.icon}
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
}

export default Card;
