import React, {
  forwardRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { AuthContext } from "../../../Helpers/AuthContext";
import { toast } from "react-toastify";

import { AiOutlineCheck } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { FiLoader } from "react-icons/fi";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import Heading from "../../Heading";
import { BiUndo } from "react-icons/bi";
import { GiPayMoney } from "react-icons/gi";

function ViewInstallmentPlan() {
  const { studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [installmentPlan, setInstallmentPlan] = useState([]);
  const [currentStudentInformation, setCurrentStudentInformation] = useState([[]]);

  useEffect(() => {
    let installmentInformation = new FormData();
    installmentInformation.append("studentID", studentID);
    installmentInformation.append("action", "readInstallmentPlan");
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/installmentPlan.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: installmentInformation,
    }).then((result) => {
      
      if (result.data[0].error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data[0].warn) {
        setCurrentStudentInformation(result.data[1]);
        return toast.warn(
          <Fragment>
            <span>{result.data[0].warn}</span>
          </Fragment>
        );
      } else {
        setInstallmentPlan(result.data[0]);
        setCurrentStudentInformation(result.data[1]);
      }
    });
  }, [studentID]);

  const columns = [

    { title: "Material Installment Details", field: "materialInstallmentDetails", editable: "never" },
    { title: "Amount", field: "amount", editable: "never" },
    { title: "Created Date", field: "createdDate", editable: "never", type: 'number' },
    { title: "Payment Slip Date", field: "paymentSlipDate", editable: "never", type: 'number' },
    {
      title: "Status",
      field: "status",
      editable: "never",
      lookup: {
        0: "Unpaid",//<Col><MdOutlineUnpublished style={{ fontSize: "1.5em" }} /></Col>,
        1: <Col><AiOutlineCheck style={{ color: "green", fontSize: "1.5em" }} /></Col>,
        2: <Col><FiLoader style={{ fontSize: "1.5em" }} /></Col>,
        3: <Col><ImCross style={{ color: "red", fontSize: "1.5em" }} /></Col>,
        5: <Col><FiLoader style={{ color: "black", fontSize: "1.5em" }} /></Col>,
      }
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="addPayment" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content={"Installment Plan for " + currentStudentInformation[0].englishName + " " + currentStudentInformation[0].chineseName} design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/payment">
                  <BiUndo /> Cancel
                </Button>{" "}
                <Button
                  variant="primary"
                  size="sm"
                  href={`/addInstallmentPlan/${studentID}`}
                >
                  <GiPayMoney /> Plan
                </Button>
              </div>
            </Col>
          </Row>
          <div id="readInstallmentPlan">
            <MaterialTable
              title=""
              columns={columns}
              data={installmentPlan}
              icons={tableIcons}
              key={installmentPlan.length}
              options={{
                sorting: true,
                filtering: true,
                search: true,
                searchFieldAlignment: "right",
                searchAutoFocus: true,
                searchFieldVariant: "standard",
                paging: true,
                pageSize: 25,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [25, 50, 75, 100],
                exportButton: true,
                exportAllData: true,
                exportFileName: "Material Installment List",
                //actionsColumnIndex: -1,
                selection: false,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                grouping: false,
                columnsButton: true,
                tableLayout: "auto",
                rowStyle: {
                  overflowWrap: "break-word",
                },
                headerStyle: { position: "sticky", top: 0, zIndex: 1 },
                maxBodyHeight: "75vh",
              }}
            />
          </div>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default ViewInstallmentPlan;
