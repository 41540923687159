import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { Col, Container, Row, Button, Spinner, Alert } from "react-bootstrap";
import Heading from "../Heading";
import { BiUndo } from "react-icons/bi";
import { toast } from "react-toastify";
import TeacherType from "../../Data/TeacherType";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Create() {
  const { authState, setAuthState } = useContext(AuthContext);
  const optionInput = "form-select";
  const textInput = "form-control";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let teacherTypeOptions = [];
  TeacherType.map((item) => {
    teacherTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    name: "",
    nickName: "",
    password: "Ukubear@2022",
    phoneNumber: "",
    role: "",
    teacherType: "",
  });

  let submitBtn = false;
  if (
    submitValue.name !== "" &&
    submitValue.phoneNumber !== "" &&
    submitValue.role !== ""
  ) {
    if (submitValue.role == 6 && submitValue.teacherType === "") {
      submitBtn = false;
    } else {
      submitBtn = true;
    }
  }

  const submitForm = () => {
    setLoading(true);
    let createAccount = new FormData();
    createAccount.append("id", submitValue.id);
    createAccount.append("name", submitValue.name);
    createAccount.append("nickName", submitValue.nickName);
    createAccount.append("phoneNumber", submitValue.phoneNumber);
    createAccount.append("role", submitValue.role);
    createAccount.append("teacherType", submitValue.teacherType);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Account/create.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: createAccount,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
    } else if (result.data.warn) {
        setLoading(false);
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/account"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createAccount" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content="New Account" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-danger" size="sm" href="/account">
                <BiUndo /> Cancel
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    name: e.target.value.toUpperCase(),
                  }))
                }
                value={submitValue.name}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label">Nick Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    nickName: e.target.value.toUpperCase(),
                  }))
                }
                value={submitValue.nickName}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Phone Number</label>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="MY"
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    phoneNumber: e,
                  }))
                }
                value={submitValue.phoneNumber}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label">Password</label>
              <input
                type="text"
                disabled
                className={textInput}
                value={submitValue.password}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Role</label>
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    role: e.target.value,
                  }))
                }
                value={submitValue.role}
              >
                <option value="">Please Select</option>
                <option value="1">Admin</option>
                <option value="2">Enrollment Teacher</option>
                <option value="3">Scheduler</option>
                <option value="4">Guardian</option>
                <option value="5">Trainer</option>
                <option value="6">Teacher</option>
              </select>
            </Col>
            {submitValue.role === "6" ? (
              <Col sm={12} md={6} className="mb-3">
                <label className="form-label">Teacher Type</label>
                <select
                  className={optionInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      teacherType: e.target.value,
                    }))
                  }
                  value={submitValue.teacherType}
                >
                  <option value="">Please Select</option>
                  {teacherTypeOptions}
                </select>
              </Col>
            ) : null}
          </Row>
          <Row className="mx-3">
            <Alert variant="danger">
              <Alert.Heading>
                Steps to create based on Role. Please follow the rules below!
              </Alert.Heading>
              <hr />
              <div className="mx-2">
                <h4>Admin</h4>
                <p>
                  1. <span className="fw-bold">Actual</span> Phone Number as{" "}
                  <span className="fw-bold">Admin</span>
                </p>
              </div>
              <div className="mx-2">
                <h4>Enrollment Teacher</h4>
                <p className="mb-0">
                  1. <span className="fw-bold">Actual</span> Phone Number as{" "}
                  <span className="fw-bold">Enrollment Teacher</span>
                </p>
                <p>
                  2. Add <span className="fw-bold">+4</span> Phone Number as{" "}
                  <span className="fw-bold">Admin</span>
                </p>
              </div>
              <div className="mx-2">
                <h4>Scheduler</h4>
                <p className="mb-0">
                  1. <span className="fw-bold">Actual</span> Phone Number as{" "}
                  <span className="fw-bold">Scheduler</span>
                </p>
                <p>
                  2. Add <span className="fw-bold">+4</span> Phone Number as{" "}
                  <span className="fw-bold">Admin</span>
                </p>
              </div>
              <div className="mx-2">
                <h4>Guardian</h4>
                <p>
                  1. <span className="fw-bold">Actual</span> Phone Number as{" "}
                  <span className="fw-bold">Guardian</span>
                </p>
              </div>
              <div className="mx-2">
                <h4>Trainer</h4>
                <p className="mb-0">
                  1. <span className="fw-bold">Actual</span> Phone Number as{" "}
                  <span className="fw-bold">Teacher</span>
                </p>
                <p className="mb-0">
                  2. Add <span className="fw-bold">+4</span> Phone Number as{" "}
                  <span className="fw-bold">Admin</span>
                </p>
                <p>
                  3. Add <span className="fw-bold">+5</span> Phone Number as{" "}
                  <span className="fw-bold">Trainer</span>
                </p>
              </div>
              <div className="mx-2">
                <h4>Teacher</h4>
                <p>
                  1. <span className="fw-bold">Actual</span> Phone Number as{" "}
                  <span className="fw-bold">Teacher</span>
                </p>
              </div>
            </Alert>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
