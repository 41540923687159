import Index from "../Pages/Index";

import Student from "../Pages/Student";
import CreateStudent from "../Components/Student/Create";
import UpdateStudent from "../Components/Student/Update";

import Guardian from "../Pages/Guardian";

import Center from "../Pages/Center";
import CreateCenter from "../Components/Center/Create";

import School from "../Pages/School";
import CreateSchool from "../Components/School/Create";

import Settings from "../Pages/Settings";

import Payment from "../Pages/Payment";

import AddPaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/AddPayment";
import ViewPaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/ViewPayment";
import UpdatePaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/UpdatePayment";
import VerifyPaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/VerifyPayment";
import ViewInstallmentPlanRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/ViewInstallmentPlan";
import AddInstallmentPlanRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/AddInstallmentPlan";
import Attendance from "../Pages/Attendance";
import CreateAttendance from "../Components/Attendance/Create";
import UpdateAttendance from "../Components/Attendance/Update";

import FMTStudent from "../Pages/FMTStudent";

import PaymentCollection from "../Pages/PaymentCollection";

const Routes = [
  { path: "/", name: "home", element: <Index /> },

  { path: "/student", name: "student", element: <Student /> },
  { path: "/createStudent", name: "createStudent", element: <CreateStudent /> },
  {
    path: "/updateStudent/:studentID",
    name: "updateStudent",
    element: <UpdateStudent />,
  },

  { path: "/guardian", name: "guardian", element: <Guardian /> },

  { path: "/center", name: "center", element: <Center /> },
  { path: "/createCenter", name: "createCenter", element: <CreateCenter /> },

  { path: "/school", name: "school", element: <School /> },
  { path: "/createSchool", name: "createSchool", element: <CreateSchool /> },

  { path: "/settings", name: "settings", element: <Settings /> },

  { path: "/attendance", name: "attendance", element: <Attendance /> },
  { path: "/payment", name: "payment", element: <Payment /> },
  {
    path: "/addPaymentRecruitmentTeacher/:studentID",
    name: "addPayment",
    element: <AddPaymentRecruitmentTeacher />,
  },
  {
    path: "/viewPaymentRecruitmentTeacher/:studentID",
    name: "viewPayment",
    element: <ViewPaymentRecruitmentTeacher />,
  },
  {
    path: "/updatePaymentRecruitmentTeacher/:paymentID/:studentID",
    name: "updatePayment",
    element: <UpdatePaymentRecruitmentTeacher />,
  },
  {
    path: "/verifyPaymentRecruitmentTeacher/:paymentID/:studentID",
    name: "verifyPayment",
    element: <VerifyPaymentRecruitmentTeacher />,
  },
  {
    path: "/viewInstallmentPlanRecruitmentTeacher/:studentID",
    name: "viewInstallmentPlan",
    element: <ViewInstallmentPlanRecruitmentTeacher />,
  },
  {
    path: "/addInstallmentPlanRecruitmentTeacher/:studentID",
    name: "addInstallmentPlan",
    element: <AddInstallmentPlanRecruitmentTeacher />,
  },

  { path: "/fmtstudent", name: "fmtstudent", element: <FMTStudent /> },

  {
    path: "/paymentCollection",
    name: "paymentCollection",
    element: <PaymentCollection />,
  },
];

export default Routes;
