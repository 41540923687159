import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import Heading from "../../Heading";
import MaterialInstallmentDetails from "./AddInstallmentPlan/MaterialInstallmentDetails";
import { BiUndo } from "react-icons/bi";

function AddInstallmentPlan() {
  const { studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("paymentDetails");

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    studentOriID: studentID,
    studentID: studentID,
    installmentPlan: "",
    materialID: "",
    newX: "",
    newY: ""
  });

  useEffect(() => {
    let studentInformation = new FormData();
    studentInformation.append("studentID", studentID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/getStudentInformation.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: studentInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setSubmitValue((submitValue) => ({
          ...submitValue,
          studentID: result.data[0].id,
          englishName: result.data[0].englishName,
          chineseName: result.data[0].chineseName,
          
        }));
      }
    });
  }, [studentID]);

  let submitBtn = false;
  if (
    submitValue.installmentPlan !== "" &&
    submitValue.materialID !== "" &&
    submitValue.newX !== undefined && 
    submitValue.newX &&
    submitValue.newY !== undefined 
    && submitValue.newY
  ) {
    submitBtn = true;
  }

  const submitForm = () => {
    let addInstallmentPlan = new FormData();

    addInstallmentPlan.append("currentUser", submitValue.id);
    
    addInstallmentPlan.append("action", "addInstallmentPlan");
    addInstallmentPlan.append("studentID", submitValue.studentID);
    addInstallmentPlan.append("materialID", submitValue.materialID);
    addInstallmentPlan.append("installmentPlan", submitValue.installmentPlan);
    addInstallmentPlan.append("newX", submitValue.newX);
    addInstallmentPlan.append("newY", submitValue.newY);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/installmentPlan.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: addInstallmentPlan,

    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/viewInstallmentPlanRecruitmentTeacher/"+ submitValue.studentID),
        ];

      }
    });
  };
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="addPayment" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content={`Add Installment for ${submitValue.englishName} ${submitValue.chineseName}`} design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-secondary" size="sm" className="float-end" href={`/viewInstallmentPlanRecruitmentTeacher/${studentID}`}>
                <BiUndo /> Back
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="paymentDetails" title="Payment Details">
                <MaterialInstallmentDetails
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                />
              </Tab>
              
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {submitBtn && (
                  <Button variant="primary" onClick={submitForm}>
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default AddInstallmentPlan;
