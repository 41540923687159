const State = [
  {
    value: "Johor",
    label: "Johor",
  },
  {
    value: "Kedah",
    label: "Kedah",
  },
  {
    value: "Kelantan",
    label: "Kelantan",
  },
  {
    value: "Kuala Lumpur",
    label: "Kuala Lumpur",
  },
  {
    value: "Labuan",
    label: "Labuan",
  },
  {
    value: "Melaka",
    label: "Melaka",
  },
  {
    value: "Negeri Sembilan",
    label: "Negeri Sembilan",
  },
  {
    value: "Pahang",
    label: "Pahang",
  },
  {
    value: "Perak",
    label: "Perak",
  },
  {
    value: "Perlis",
    label: "Perlis",
  },
  {
    value: "Pulau Pinang",
    label: "Pulau Pinang",
  },
  {
    value: "Sabah",
    label: "Sabah",
  },
  {
    value: "Sarawak",
    label: "Sarawak",
  },
  {
    value: "Selangor",
    label: "Selangor",
  },
  {
    value: "Terengganu",
    label: "Terengganu",
  },
];

export default State;
