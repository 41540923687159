import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';

function PaymentDetails({
  setKey,
  submitValue,
  setSubmitValue,

  studentMaterialFeeArr,
  materialFeeSelectedArr,
  materialFeeAmountArr,
  materialFeeSelectedGroupArr,

  setMaterialFeeType,
  materialFeeType
}) {
  const textInput = "form-control";
  const optionInput = "form-select";
  const { paymentID , studentID} = useParams();
  const [materialOptions, setMaterialOptions] = useState([]);
  const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);

  const [file, setFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([])
  const MAX_SIZE = 10; //file size max is 10mb

  useEffect(() => {
    let studentInformation = new FormData();
    studentInformation.append("studentID", studentID);
    studentInformation.append("paymentID", paymentID);
    studentInformation.append("requestOrigin", "verifyPayment");
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/getMaterialList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: studentInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {

        setMaterialOptions(
          result.data[0].map(({ displayText, amount, id, group }) => ({
            label: displayText,
            amount: amount,
            value: id,
            group: group
          }))
        );

        setSelectedMaterialOptions(
          result.data[1].map(({ displayText, amount, id, group }) => ({
            label: displayText,
            amount: amount,
            value: id,
            group: group
          }))
        );

        setMaterialFeeType(result.data[2][0]);

        var temp = [];
        var temp2 = [];
        var amount = 0;
        
        result.data[1].forEach(e => {
          temp.push(e.id);
          temp2.push(e.group);
          amount = amount + Number(e.amount);
        });
        studentMaterialFeeArr(temp, amount, temp2, 0);
      }

    });
  }, [studentID]);

  let str = "";
  for (let counter = 0; counter < selectedMaterialOptions.length; counter++) {
    str = str + ((selectedMaterialOptions[counter].label)) + "\n";
  }
  
  let paymentType;
  if (submitValue.category == "P" || submitValue.category == "E") {
    paymentType = <Col sm={12} md={6} className="mb-3">
      <label className="form-label required">Payment Type</label>
      <div className="input-group">
      <input
        disabled={true}
        className={textInput}
        value={submitValue.paymentYear}
      />
      <input
        disabled={true}
        className={textInput}
        value={submitValue.paymentType}
      />
      </div>
    </Col>
  }
  if (submitValue.category == "U" || submitValue.category == "K") {
    paymentType = <Col sm={12} md={6} className="mb-3">
      <label className="form-label required">Payment Type</label>
      <div className="input-group">
      <input
        disabled={true}
        className={textInput}
        value={submitValue.paymentYear}
      />
      <input
        disabled={true}
        className={textInput}
        value={submitValue.paymentType}
      />
      </div>
    </Col>
  }
  let overpayOrUnderpayStatus="";
  if(submitValue.overpayOrUnderpay==0){
    overpayOrUnderpayStatus = "-";
  }else if (submitValue.overpayOrUnderpay<0){
    overpayOrUnderpayStatus = "Overpay";
  }else if (submitValue.overpayOrUnderpay>0){
    overpayOrUnderpayStatus = "Underpay";
  }

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Request Receipt</label>
            <input
              disabled={true}
              className={textInput}
              value={submitValue.requestReceipt}
            />
          </Col>
          {paymentType}
          
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Overpay or Underpay</label>
            <div className="input-group">
            <input
              disabled={true}
              className={textInput}
              value={overpayOrUnderpayStatus}
            />
              <input
              disabled={true}
              className={textInput}
              value={submitValue.currency+Math.abs(submitValue.overpayOrUnderpay).toFixed(2)}
            />
            </div>
            
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Business Type</label>
            <input
              disabled={true}
              className={textInput}
              value={submitValue.businessType}
            />
          </Col>
          <hr></hr>

          <h4>Payment Details</h4>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Course Fee Amount</label>
            <input
              disabled={true}
              type="number"
              step="any"
              className={textInput}
              value={Number (submitValue.courseFee)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Material Fee</label>
            <TextareaAutosize
              disabled={true}
              className={textInput}
              //value={JSON.stringify(selectedMaterialOptions[0].label)}
              value={str}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Penalty Fee Amount</label>
            <input
              disabled={true}
              type="number"
              step="any"
              className={textInput}
              value={Number(submitValue.penaltyFee)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Total Fee</label>
            <input
              type="number"
              step="any"
              disabled
              className={textInput}
              value={Number( Number(submitValue.courseFee) + Number(submitValue.penaltyFee) + Number(submitValue.materialFee) ).toFixed(2)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Teacher Remark</label>
            <TextareaAutosize
              disabled={true}
              type="number"
              className={textInput}
              onChange={(e) => {
                e.target.style.height = "inherit";
                e.target.style.height = `${e.target.scrollHeight}px`;
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  teacherRemark: e.target.value,
                }));
              }}
              value={submitValue.teacherRemark}
            />
          </Col>
          <br></br>
          <hr></hr>
        </Row>
        {/* <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row> */}
      </Container>
    </div >
  );

}

export default PaymentDetails;
