import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Accordion } from "react-bootstrap";

import StudentList from "./StudentList";

import moment from "moment";

function LongCard({ classList, searchTerm }) {
  return (
    <Row className="justify-content-md-center mx-5 my-2">
      <Col sm={12}>
        <Accordion defaultActiveKey="0">
          {classList
          .filter((classList) => {
            if (searchTerm === "") {
              return classList;
            } else if (
              classList.classCode
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return classList;
            }
            return false;
          })
          .map((classList, index) => {
            const classID = classList.id;
            const classCode = classList.classCode;
            const learningType = classList.learningType;
            const day = classList.day;
            const lessonPlan = classList.lessonPlan;
            const language = classList.language;
            const status = classList.status;
            const startTime = moment(classList.startTime, "hh:mm:ss").format(
              "hh:mm a"
            );
            const endTime = moment(classList.endTime, "hh:mm:ss").format(
              "hh:mm a"
            );

            let learningTypeBG;
            switch (learningType) {
              case "O":
                learningTypeBG = "Online Class";
                break;
              default:
                learningTypeBG = "Physical Class";
            }

            return (
              <Accordion.Item eventKey={classID} key={index}>
                <Accordion.Header><Link 
                    className="link-dark text-decoration-none"
                    to="/attendance"
                    state={{classCode: classCode}}
                    >{classCode} </Link> - {status == "1" ? "Active" : "Inactive"} - {day} - {startTime} - {endTime} - {learningTypeBG} - {lessonPlan} - {language}
                </Accordion.Header>
                <Accordion.Body>
                  <StudentList classID={classID} />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Col>
    </Row>
  );
}

export default LongCard;
