import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import { FaChalkboardTeacher, FaUserGraduate, FaUserTie } from "react-icons/fa";
import { GiTeacher, GiWhistle } from "react-icons/gi";
import { RiParentFill } from "react-icons/ri";

import Card from "./Card";

function Admin() {
  const [countUser, setCountUser] = useState([
    {
      student: 0,
      class: 0,
      teacher: 0,
      trainer: 0,
      guardian: 0,
      salesperson: 0,
    },
  ]);

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Index/admin.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setCountUser(result.data);
      }
    });
  }, []);

  return (
    <>
    <Card
        content="Total Active Student"
        amount={countUser.activeStudent}
        icon={<FaUserGraduate size={35} />}
      />
      <Card
        content="Total Student"
        amount={countUser.student}
        icon={<FaUserGraduate size={35} />}
      />
      <Card
        content="Total Class"
        amount={countUser.class}
        icon={<FaChalkboardTeacher size={35} />}
      />
      <Card
        content="Total Teacher"
        amount={countUser.teacher}
        icon={<GiTeacher size={35} />}
      />
      <Card
        content="Total Trainer"
        amount={countUser.trainer}
        icon={<GiWhistle size={35} />}
      />
      <Card
        content="Total Guardian"
        amount={countUser.guardian}
        icon={<RiParentFill size={35} />}
      />
      <Card
        content="Total Enrollment Teacher"
        amount={countUser.salesperson}
        icon={<FaUserTie size={35} />}
      />
    </>
  );
}

export default Admin;
