import React, { useContext, Fragment, useState, useEffect } from "react";
import Axios from "axios";
import { AuthContext } from "../../Helpers/AuthContext";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import { FcAlarmClock, FcCalendar, FcPlus, FcInfo } from "react-icons/fc";
import { FaEye } from "react-icons/fa";
import {
  FaLanguage,
  FaSchool,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { GiTeacher, GiWhistle } from "react-icons/gi";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import moment from "moment";
import ReactPaginate from "react-paginate";

import ReactTooltip from "react-tooltip";

import Heading from "../Heading";
import Name from "../Name";
import Venue from "../Venue";
import Record from "./Read/Record";

import { useLocation } from "react-router-dom";

function Read(props) {

  const { authState, setAuthState } = useContext(AuthContext);
  const [classList, setClassList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/read.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        if (authState.role != 6) {
          setClassList(result.data);
        } else {
          setClassList(
            result.data.filter(({ teacherID }) => {
              if (teacherID === authState.id) {
                return classList;
              }
              return false;
            })
          );
        }
      }
    });

    if (location.state === null) {
      setSearchTerm("");
    } else {
      setSearchTerm(location.state.classCode);
    }
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  var cardPerPage = 15;
  const pagesVisited = pageNumber * cardPerPage;
  const pageCount = Math.ceil(classList.length / cardPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readAttendance">
          <Container>
            <Row>
              <Col className="col-md-4 ms-auto">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Class Code"
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  value={searchTerm}
                />
              </Col>
            </Row>
          </Container>

          {classList

            .filter((classList) => {
              if (searchTerm === "") {
                return classList;
              } else if (
                classList.classCode
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return classList;
              }
              return false;
            })

            .map((classList, key) => {
              const classID = classList.id;
              const classCode = classList.classCode;
              const teacherID = classList.teacherID;
              const trainerID = classList.trainerID;
              const classDay = classList.day;
              const remark = classList.remark;
              const status = classList.status;
              const learningType = classList.learningType;
              const venue = classList.venue;
              const venueID = classList.venueID;
              const startTime = moment(classList.startTime, "hh:mm:ss").format(
                "hh:mm a"
              );
              const endTime = moment(classList.endTime, "hh:mm:ss").format(
                "hh:mm a"
              );
              const language = classList.language;

              return (
                <div key={classID} className="mb-5">
                  <div className="mb-2">
                    <Heading
                      content={classCode}
                      design="h3 d-inline align-middle"
                    />
                    {status == "1" ? (
                      <>
                      <p className="d-inline align-middle">
                        <a
                          className="text-decoration-none align-middle"
                          href={`/createAttendance/${classID}`}
                          data-tip
                          data-for="createAttendance"
                        >
                          <FcPlus size={28} />
                        </a>
                        <ReactTooltip
                          id="createAttendance"
                          place="top"
                          type="dark"
                          effect="float"
                        >
                          <span>Add Attendance</span>
                        </ReactTooltip>
                      </p>
                      <p className="d-inline align-middle">
                      <a
                        className="text-decoration-none align-middle"
                        href={`${process.env.REACT_APP_SERVER_DOMAIN}Attendance/readPaymentDetailsByClass.php?classID=${classID}`}
                        data-tip
                        data-for="viewAttendancePayment"
                        target="_blank"
                      >
                        <FaEye size={28} />
                      </a>
                      <ReactTooltip
                        id="viewAttendancePayment"
                        place="top"
                        type="dark"
                        effect="float"
                      >
                        <span>View Attendance & Payment</span>
                      </ReactTooltip>
                    </p></>
                    ):
                    <p className="d-inline align-middle">
                        <a
                          className="text-decoration-none align-middle"
                          data-tip
                          data-for="cannotCreateAttendance"
                        >
                          <BiXCircle size={28} className="text-danger" />
                        </a>
                        <ReactTooltip
                          id="cannotCreateAttendance"
                          place="top"
                          type="dark"
                          effect="float"
                        >
                          <span>Inactive Class</span>
                        </ReactTooltip>
                      </p>
                    }
                    
                  </div>
                  <div className="mb-2 ms-2">
                    {learningType == "P" && (
                      <>
                        <FaSchool
                          className="icon d-inline"
                          data-tip
                          data-for={`${classID}-school`}
                        />
                        <ReactTooltip
                          id={`${classID}-school`}
                          place="bottom"
                          type="dark"
                          effect="float"
                        >
                          <span>{venue}</span>
                        </ReactTooltip>
                        <Heading
                          content={<Venue id={venueID} venueType={venue} />}
                          design="fs-6 d-inline"
                        />{" "}
                        |
                      </>
                    )}
                    {status == 1 ? (
                      <BiCheckCircle
                        className="icon d-inline"
                        data-tip
                        data-for="status"
                      />
                    ) : (
                      <BiXCircle
                        className="icon d-inline"
                        data-tip
                        data-for="status"
                      />
                    )}
                    <ReactTooltip
                      id="status"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Status</span>
                    </ReactTooltip>
                    <Heading
                      content={status == 1 ? "Active" : "Inactive"}
                      design="fs-6 d-inline"
                    />{" "}
                    |
                    <GiTeacher
                      className="icon d-inline"
                      data-tip
                      data-for="teacher"
                    />
                    <ReactTooltip
                      id="teacher"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Teacher</span>
                    </ReactTooltip>
                    <Heading
                      content={<Name id={teacherID} />}
                      design="fs-6 d-inline"
                    />{" "}
                    |
                    <GiWhistle
                      className="icon d-inline"
                      data-tip
                      data-for="trainer"
                    />
                    <ReactTooltip
                      id="trainer"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Trainer</span>
                    </ReactTooltip>
                    <Heading
                      content={<Name id={trainerID} />}
                      design="fs-6 d-inline"
                    />{" "}
                    |{" "}
                    <FcCalendar
                      className="icon d-inline"
                      data-tip
                      data-for="date"
                    />
                    <ReactTooltip
                      id="date"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Day</span>
                    </ReactTooltip>
                    <Heading content={classDay} design="fs-6 d-inline" /> |{" "}
                    <FcAlarmClock
                      className="icon d-inline"
                      data-tip
                      data-for="time"
                    />
                    <ReactTooltip
                      id="time"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Time</span>
                    </ReactTooltip>
                    <Heading
                      content={`${startTime} - ${endTime}`}
                      design="fs-6 d-inline"
                    />{" "}
                    |{" "}
                    <FaLanguage
                      className="icon d-inline"
                      data-tip
                      data-for="language"
                    />
                    <ReactTooltip
                      id="language"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Language</span>
                    </ReactTooltip>
                    <Heading content={language} design="fs-6 d-inline" />
                    {remark !== null || "" && (
                      <>
                        {" "}
                        |{" "}
                        <FcInfo
                          className="icon d-inline"
                          data-tip
                          data-for={`${classID}-${remark}`}
                        />
                        <ReactTooltip
                          id={`${classID}-${remark}`}
                          place="bottom"
                          type="dark"
                          effect="float"
                        >
                          <span>{remark}</span>
                        </ReactTooltip>
                      </>
                    )}
                  </div>
                  <Record classID={classID} realteacherID={teacherID} />
                </div>
              );
            })
            .slice(pagesVisited, pagesVisited + cardPerPage)}
          <div className="w-100">
            <ReactPaginate
              previousLabel={<FaChevronLeft />}
              nextLabel={<FaChevronRight />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginateBtn"}
              // previousLinkClassName={"page-item btn"}
              // nextLinkClassName={"page-item btn"}
              disabledClassName={"disabled"}
              activeClassName={"paginateBtnActive"}
            />
          </div>
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;
