const StudentStatus= [

    {
      value: "Active",
      label: "Active",
    },
    {
      value: "OH",
      label: "OH",
    },
    {
        value: "WFO",
        label: "Transferring",
      },
      {
        value: "Withdraw",
        label: "Withdraw",
      },
  ];
  
  export default StudentStatus;
  