import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import { BiUndo } from "react-icons/bi";
import moment from "moment";

import Heading from "../Heading";
import Class from "./Create/Class";
import Student from "./Create/Student";

function Create() {
  const navigate = useNavigate();
  const { classID } = useParams();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("class");
  const [loading, setLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    classID: classID,
    classCode: "",
    teacherID: "",
    trainerID: "",
    studentList: [],
    startTime: "00:00",
    endTime: "00:00",
    classDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    let updateClass = new FormData();
    updateClass.append("classID", classID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Class/update.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updateClass,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setSubmitValue((submitValue) => ({
          ...submitValue,
          classCode: result.data[0].classCode,
          teacherID: result.data[0].teacherID,
          trainerID: result.data[0].trainerID,
          startTime: result.data[0].startTime,
          endTime: result.data[0].endTime,
          studentList: result.data["studentList"][0],
        }));
      }
    });
  }, [classID]);

  //Attendance
  const [attendanceArr, setAttendanceArr] = useState([]);
  const studentAttendanceInfo = (e, index) => {
    let tempAttendance = attendanceArr;
    tempAttendance[index] = e.value;
    setAttendanceArr((tempAttendance) => [...tempAttendance]);
  };
  const studentAttendanceOverall = async (e, index) => {
    let tempAttendanceOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempAttendanceOverall.push(e.value);
      setAttendanceArr(tempAttendanceOverall);
    }
  };

  //Learning Progress
  const [learningProgressArr, setLearningProgressArr] = useState([]);
  const studentLearningProgressInfo = (e, index) => {
    let tempLearningProgress = learningProgressArr;
    tempLearningProgress[index] = e.value;
    setLearningProgressArr((tempLearningProgress) => [...tempLearningProgress]);
  };

  const studentLearningProgressOverall = async (e, index) => {
    let tempLearningProgressOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempLearningProgressOverall.push(e.value);
      setLearningProgressArr(tempLearningProgressOverall);
    }
  };

  //Homework
  const [homeworkArr, setHomeworkArr] = useState([]);

  const studentHomeworkInfo = (e, index) => {
    let tempHomework = homeworkArr;
    tempHomework[index] = e.value;
    setHomeworkArr((tempHomework) => [...tempHomework]);
  };

  const studentHomeworkOverall = async (e, index) => {
    let tempHomeworkOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempHomeworkOverall.push(e.value);
      setHomeworkArr(tempHomeworkOverall);
    }
  };

  //Remarks
  const [remarkArr, setRemarkArr] = useState([]);
  const studentRemarkInfo = (e, index) => {
    let tempRemark = remarkArr;
    tempRemark[index] = e.target.value;
    setRemarkArr((tempRemark) => [...tempRemark]);
  };
  const studentRemarkOverall = async (e, index) => {
    let tempRemarkOverall = [];
    for (var i = 0; i < index.length; i++) {
      tempRemarkOverall.push(e.target.value);
      setRemarkArr(tempRemarkOverall);
    }
  };

  let submitBtn = false;
  if (attendanceArr.length > 0 && learningProgressArr.length > 0) {
    if (
      attendanceArr.length === submitValue.studentList.length &&
      learningProgressArr.length === submitValue.studentList.length
    ) {
      const isUndefined = (arr) => arr.every((e) => e !== undefined);

      for (var i = 0; i < attendanceArr.length; i++) {
        if (isUndefined(attendanceArr) && isUndefined(learningProgressArr)) {
          submitBtn = true;
        }
      }
    }
  }

  const submitForm = () => {
    //setLoading(true);
    let submitAttendance = [];
    
    
    for (var i = 0; i < submitValue.studentList.length; i++) {
      if (remarkArr[i] === undefined) {
        remarkArr[i] = null;
      }
      submitAttendance.push({
        studentID: submitValue.studentList[i],
        attendance: attendanceArr[i],
        learningProgress: learningProgressArr[i],
        homework: homeworkArr[i],
        remarks: remarkArr[i],
      });
    }
    const submitAttendanceJson = JSON.stringify(submitAttendance);

    let createStudent = new FormData();
    createStudent.append("id", submitValue.id);
    createStudent.append("classID", submitValue.classID);
    createStudent.append("classCode", submitValue.classCode);
    createStudent.append("teacherID", submitValue.teacherID);
    createStudent.append("trainerID", submitValue.trainerID);
    createStudent.append("startTime", submitValue.startTime);
    createStudent.append("endTime", submitValue.endTime);
    createStudent.append("classDate", submitValue.classDate);
    createStudent.append("studentAttendance", submitAttendanceJson);
    
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/create.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: createStudent,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        setLoading(false);
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate(`/attendance`, {state:{ classCode: submitValue.classCode }})
          //navigate("/attendance"),
          //navigate(`/attendance`, state={ classCode: submitValue.classCode })
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createAttendance" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading
                content={`New Attendance for ${submitValue.classCode}`}
                design="h5"
              />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-danger" size="sm" href="/attendance">
                <BiUndo /> Cancel
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="class" title="Class">
                <Class
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                  setKey={setKey}
                />
              </Tab>
              <Tab eventKey="student" title="Student">
                <Student
                  attendanceArr={attendanceArr}
                  studentAttendanceOverall={studentAttendanceOverall}
                  studentAttendanceInfo={studentAttendanceInfo}
                  learningProgressArr={learningProgressArr}
                  studentLearningProgressOverall={
                    studentLearningProgressOverall
                  }
                  studentLearningProgressInfo={studentLearningProgressInfo}
                  homeworkArr={homeworkArr}
                  studentHomeworkOverall={studentHomeworkOverall}
                  studentHomeworkInfo={studentHomeworkInfo}
                  remarkArr={remarkArr}
                  studentRemarkOverall={studentRemarkOverall}
                  studentRemarkInfo={studentRemarkInfo}
                  submitValue={submitValue}
                  setKey={setKey}
                />
              </Tab>
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
