import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import { BiUndo } from "react-icons/bi";

import Heading from "../Heading";
import Info from "./Update/Info";
import Schedule from "./Update/Schedule";

function Update() {
  const { classID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("info");
  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    classID: classID,
    teacherID: "",
    trainerID: "",
    studentList: [],
    studentOptions: [],
    learningType: "",
    category: "",
    day: "",
    startTime: "00:00",
    endTime: "00:00",
    lessonPlan: "",
    language: "",
    remark: "",
    status: "",
  });

  useEffect(() => {
    let updateClass = new FormData();
    updateClass.append("classID", classID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Class/update.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updateClass,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setSubmitValue((submitValue) => ({
          ...submitValue,
          teacherID: result.data[0].teacherID,
          trainerID: result.data[0].trainerID,
          learningType: result.data[0].learningType,
          category: result.data[0].category,
          day: result.data[0].day,
          startTime: result.data[0].startTime,
          endTime: result.data[0].endTime,
          lessonPlan: result.data[0].lessonPlan,
          language: result.data[0].language,
          remark: result.data[0].remark,
          status: result.data[0].status,
          studentList: result.data["studentList"][0],
          studentOption: result.data["studentOption"][0],
          venue: result.data[0].venue,
          venueState: result.data[0].venueState,
          venueID: result.data[0].venueID,
        }));

        if (result.data[0].remark == null) {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            remark: "",
          }));
        }

        if (result.data[0].venue == null || result.data[0].venue == "") {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            venue: "Center",
          }));
        }

        if (result.data[0].venueState == null) {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            venueState: "",
          }));
        }

        if (result.data[0].venueID == null) {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            venueID: "",
          }));
        }

        setSubmitValue((submitValue) => ({
          ...submitValue,
          studentOption: submitValue.studentOption.map(
            ({ id, studentCode, englishName, chineseName }) => ({
              label: `${studentCode}-${englishName}-${chineseName}`,
              id: id,
            })
          ),
          studentList: submitValue.studentList.map(
            ({ id, studentCode, englishName, chineseName }) => ({
              label: `${studentCode}-${englishName}-${chineseName}`,
              id: id,
            })
          ),
        }));
      }
    });
  }, [classID]);

  let submitBtn = false;

  if (
    submitValue.teacherID !== "" &&
    submitValue.trainerID !== "" &&
    submitValue.newStudentList !== "" &&
    submitValue.learningType !== "" &&
    submitValue.category !== "" &&
    submitValue.lessonPlan !== "" &&
    submitValue.language !== "" &&
    submitValue.day !== "" &&
    submitValue.startTime !== "00:00" &&
    submitValue.endTime !== "00:00"
  ) {
    submitBtn = true;
  }

  const submitForm = () => {
    setLoading(true);

    // convert from array to json
    const studentArr = JSON.stringify(submitValue.studentList);

    let updateID = new FormData();
    updateID.append("id", submitValue.id);
    updateID.append("classID", submitValue.classID);
    updateID.append("teacherID", submitValue.teacherID);
    updateID.append("trainerID", submitValue.trainerID);
    updateID.append("studentList", studentArr);
    updateID.append("learningType", submitValue.learningType);
    updateID.append("category", submitValue.category);
    updateID.append("day", submitValue.day);
    updateID.append("startTime", submitValue.startTime);
    updateID.append("endTime", submitValue.endTime);
    updateID.append("lessonPlan", submitValue.lessonPlan);
    updateID.append("language", submitValue.language);
    updateID.append("remark", submitValue.remark);
    updateID.append("status", submitValue.status);
    updateID.append("venue", submitValue.venue);
    updateID.append("venueState", submitValue.venueState);
    updateID.append("venueID", submitValue.venueID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Class/updateID.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: updateID,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/class"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createClass" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content="Updating Class" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/class">
                  <BiUndo /> Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="info" title="Info">
                <Info
                  setSubmitValue={setSubmitValue}
                  submitValue={submitValue}
                  setKey={setKey}
                />
              </Tab>
              <Tab eventKey="schedule" title="Schedule">
                <Schedule
                  setSubmitValue={setSubmitValue}
                  submitValue={submitValue}
                  setKey={setKey}
                />
              </Tab>
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Update;
