import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import Heading from "../../Heading";
import PaymentDetails from "./VerifyPayment/PaymentDetails";
import ConfirmPaymentDetails from "./VerifyPayment/ConfirmPaymentDetails";
import { BiUndo } from "react-icons/bi";

function VerifyPayment() {
  const { paymentID, studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("paymentDetails");
  const [materialFeeType, setMaterialFeeType] = useState();
  const [buttonSaveText, setButtonSaveText] = useState("Save");
  const [buttonRejectText, setButtonRejectText] = useState("Reject");
  const [buttonVerifyText, setButtonVerifyText] = useState("Verify");
  const [submitBtn, setSubmitBtn] = useState(true);
  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    paymentID: paymentID,
    englishName: "",
    chineseName: "",
    category: "",
    currency: "",
    studentID: studentID,
    originalPaymentSlip: [],
    newPaymentSlip: []
  });

  const [materialFeeSelectedArr, setMaterialFeeSelectedArr] = useState([]);
  const [materialFeeAmountArr, setMaterialFeeAmountArr] = useState([]);
  const [materialFeeSelectedGroupArr, setMaterialFeeSelectedGroupArr] = useState([]);
  const studentMaterialFeeArr = (e, f, g, index) => {
    let tempMaterialFeeSelected = materialFeeSelectedArr;
    tempMaterialFeeSelected[index] = e;
    setMaterialFeeSelectedArr((tempMaterialFeeSelected) => [...tempMaterialFeeSelected]);

    let tempMaterialFeeAmount = materialFeeAmountArr;
    tempMaterialFeeAmount[index] = f;
    setMaterialFeeAmountArr((tempMaterialFeeAmount) => [...tempMaterialFeeAmount]);
    
    let tempMaterialFeeSelectedGroup = materialFeeSelectedGroupArr;
    tempMaterialFeeSelectedGroup[index] = g;
    setMaterialFeeSelectedGroupArr((tempMaterialFeeSelectedGroup) => [...tempMaterialFeeSelectedGroup]);
  };

  useEffect(() => {
    let updatePayment = new FormData();
    updatePayment.append("paymentID", paymentID);
    updatePayment.append("action", "setupVerifyForm");
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/verifyPayment.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updatePayment,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        if (result.data[0][0].status != '2' ) {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            confirmCourseFee: result.data[0][0].confirmCourseFee,
            confirmPenaltyFee: result.data[0][0].confirmPenaltyFee,
            confirmDiscountAmount: result.data[0][0].confirmDiscountAmount,
            confirmRegistrationFee: result.data[0][0].confirmRegistrationFee,
            confirmPaymentYear: result.data[0][0].confirmPaymentYear,
            confirmPaymentType: result.data[0][0].confirmPaymentType,
          }));
        } else {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            confirmCourseFee: result.data[0][0].courseFee,
            confirmPenaltyFee: result.data[0][0].penaltyFee,
            confirmDiscountAmount: result.data[0][0].discountAmount,
            confirmRegistrationFee: result.data[0][0].registrationFee,
            confirmPaymentYear: result.data[0][0].paymentYear,
            confirmPaymentType: result.data[0][0].paymentType,
          }));
        }
        setSubmitValue((submitValue) => ({
          ...submitValue,
          studentID: result.data[1][0].id,
          englishName: result.data[1][0].englishName,
          chineseName: result.data[1][0].chineseName,
          currency: result.data[1][0].currency,
          category: result.data[1][0].category,
          overpayOrUnderpay: result.data[1][0].overpayOrUnderpay,
          businessType: result.data[1][0].businessType,

          paymentID: result.data[0][0].id,
          paymentYear: result.data[0][0].paymentYear,
          paymentType: result.data[0][0].paymentType,
          courseFee: result.data[0][0].courseFee,
          penaltyFee: result.data[0][0].penaltyFee,
          discountAmount: result.data[0][0].discountAmount,
          registrationFee: result.data[0][0].registrationFee,
          materialFee: result.data[0][0].materialFee,

          confirmOverpayOrUnderpayStatus: 0,
          confirmOverpayOrUnderpayAmount: 0,
          teacherRemark: result.data[0][0].reference,
          accountRemark: result.data[0][0].accountRemark,
          requestReceipt: result.data[0][0].requestReceipt,
          transactionDate: result.data[0][0].paymentSlipDate,
          status: result.data[0][0].status,
          originalPaymentSlip: result.data[2],
        }));

      }
    });
  }, [paymentID]);

  const submitForm = (action) => {
    setButtonSaveText("Processing");
    setButtonRejectText("Processing");
    setButtonVerifyText("Processing");
    setSubmitBtn(true);
    let updateID = new FormData();
    let submitPayment = [];

    updateID.append("paymentID", paymentID);
    updateID.append("studentID", studentID);
    updateID.append("action", action);
    updateID.append("confirmPaymentYear", submitValue.confirmPaymentYear);
    updateID.append("confirmPaymentType", submitValue.confirmPaymentType);
    updateID.append("confirmCourseFee", submitValue.confirmCourseFee);
    updateID.append("materialFeeSelectedArr", materialFeeSelectedArr);
    updateID.append("materialFeeSelectedGroupArr", materialFeeSelectedGroupArr);
    updateID.append("materialFeeAmountArr", materialFeeAmountArr);
    updateID.append("confirmPenaltyFee", submitValue.confirmPenaltyFee);
    updateID.append("confirmDiscountAmount", submitValue.confirmDiscountAmount);
    updateID.append("confirmRegistrationFee", submitValue.confirmRegistrationFee);
    updateID.append("accountRemark", submitValue.accountRemark);
    updateID.append("confirmOverpayOrUnderpayAmount", submitValue.confirmOverpayOrUnderpayAmount);
    updateID.append("confirmOverpayOrUnderpayStatus", submitValue.confirmOverpayOrUnderpayStatus);
    updateID.append("materialFeeType", materialFeeType);
    updateID.append("currentUser", authState.id);
    for (let i = 0; i < submitValue.newPaymentSlip.length; i++) {
      updateID.append("paymentSlip[]", submitValue.newPaymentSlip[i]);
    }

    submitPayment.push({
      materialFeeSelected: materialFeeSelectedArr[0],
      materialFeeSelectedGroupArr: materialFeeSelectedGroupArr[0]
    });

    const submitPaymentJson = JSON.stringify(submitPayment);

    updateID.append("submitPaymentJson", submitPaymentJson);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/verifyPayment.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: updateID,

    }).then((result) => {
      if (result.data.error) {
        setButtonSaveText("Save");
        setButtonRejectText("Reject");
        setButtonVerifyText("Verify");
        setSubmitBtn(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setButtonSaveText("Save");
        setButtonRejectText("Reject");
        setButtonVerifyText("Verify");
        setSubmitBtn(false);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate(`/viewpayment/${studentID}`),
        ];

      }
    });
  };
  let rejectButton = "";
  let successButton = "";
  let saveButton = "";
  if (submitValue.status == "2" || submitValue.status == "5") {
    rejectButton = <Button variant="danger" disabled={submitBtn}
      onClick={() => {
        if (window.confirm("Reject this payment slip?")) {
          submitForm("rejectPayment")
        }
      }
      }>
      {buttonRejectText}{" "}
    </Button>
    successButton = <Button variant="success" disabled={submitBtn}
      onClick={() => {
        submitForm("verifyPayment")
      }
      }>
      {buttonVerifyText}{" "}
    </Button>
    saveButton = <Button variant="primary" disabled={submitBtn}
      onClick={() => {
        submitForm("savePayment")
      }
      }>
      {buttonSaveText}{" "}
    </Button>
  }
  

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createStudent" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content="Verify Payment" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-secondary" size="sm" className="float-end" href={`/viewpayment/${studentID}`}>
                <BiUndo /> Back
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="paymentDetails" title="Payment Details">
                <PaymentDetails
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}

                  studentMaterialFeeArr={studentMaterialFeeArr}
                  materialFeeSelectedArr={materialFeeSelectedArr}
                  materialFeeAmountArr={materialFeeAmountArr}
                  materialFeeSelectedGroupArr={materialFeeSelectedGroupArr}
                  
                  setMaterialFeeType={setMaterialFeeType}
                  materialFeeType={materialFeeType}
                />
                <ConfirmPaymentDetails
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}

                  studentMaterialFeeArr={studentMaterialFeeArr}
                  materialFeeSelectedArr={materialFeeSelectedArr}
                  materialFeeAmountArr={materialFeeAmountArr}
                  materialFeeSelectedGroupArr={materialFeeSelectedGroupArr}

                  setMaterialFeeType={setMaterialFeeType}
                  materialFeeType={materialFeeType}
                />
              </Tab>
            </Tabs>
          </Row>
          {/* <Row>
         <Col sm={10} lg={2}>
         {JSON.stringify(submitValue)}
         </Col>
          </Row> */}
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {saveButton}
                {rejectButton}
                {successButton}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default VerifyPayment;
