import React, { useState, useEffect } from "react";
import Axios from "axios";

function StudentAge(props) {
  const userID = props.id;
  const [viewAge, setViewAge] = useState([]);

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentAge.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {
      if (result.data === undefined || result.data.length == 0) {
        setViewAge("-");
      } else {
        setViewAge(result.data);
      }
    });
  }, [userID]);

  return (
    <a className="fs-7 text-decoration-none text-dark">
      {viewAge.age !== "0" && (
        <span>
          {" ("}
          {viewAge.age}
          {") "}
        </span>
      )}
    </a>
  );
}

export default StudentAge;
