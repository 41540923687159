import React, {
  forwardRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import { AuthContext } from "../../Helpers/AuthContext";
import { toast } from "react-toastify";
import { BiEdit, BiLogIn, BiTrash } from "react-icons/bi";
import { FaEye } from "react-icons/fa";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

function Read() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [studentList, setStudentlist] = useState([]);
  const [allowDelete, setAllowDelete] = useState(false);

  useEffect(() => {
    let readByID = new FormData();
    readByID.append("id", authState.id);
    if (authState.role == 2) {
      Axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/readbySalesPerson.php/`,
        config: {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        },
        data: readByID,
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
          setStudentlist(result.data);
        }
      });
    } else if (authState.role == 6) {
      Axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/readbyTeacher.php/`,
        config: {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        },
        data: readByID,
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
          setStudentlist(result.data);
        }
      });
    } else {
      
      Axios({
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/read.php/`,
        config: {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        },
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
            setStudentlist(result.data);
            console.log(studentList);
            if(authState.id=='6577'){

            }
        }
      });
    }
  }, []);

  const handleDelete = (id) => {
    let deleteStudent = new FormData();
    deleteStudent.append("studentID", id);
    deleteStudent.append("userID", authState.id);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/delete.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: deleteStudent,
    }).then((response) => {
        if (response.data.success) {
          // Student deletion successful
          // Remove the deleted student from the studentList
          const updatedStudentList = studentList.filter(
            (student) => student.id !== id
          );
          setStudentlist(updatedStudentList);
  
          toast.success("Student deleted successfully!");
        } else {
          // Student deletion failed
          toast.error("Failed to delete student.");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while deleting the student.");
      });
  };

  const columns = [
    { title: "Student ID", field: "studentCode", editable: "never" },
    { title: "Class Code", field: "classCode", editable: "never" },
    { title: "Date", field: "createdDate", editable: "never" },
    { title: "First Class Date", field: "FirstClassDate", editable: "never" },
    {
      title: "Guardian Phone Number",
      field: "GuardianPhoneNumber",
      filterPlaceholder: "Filter by Phone Number",
      editable: "never",
    },
    {
      title: "Class Location",
      field: "classLocation",
      editable: "never",
    },
    { title: "Center Name", field: "centerName", editable: "never" },
    { title: "School Name", field: "schoolName", editable: "never" },
    {
      title: "English Name",
      field: "englishName",
      editable: "never",
    },
    { title: "Chinese Name", field: "chineseName", editable: "never" },
    {
      title: "Alternative Phone Number",
      field: "phoneNumber",
      editable: "never",
    },
    { title: "Business Type", field: "businessType" },
    {
      title: "FMT/HP",
      field: "tempStudentStatus",
      lookup: { 0: "-", 1: "FMT", 2: "Holiday Programme", 3: "T1C" },
    },
    { title: "Gender", field: "gender", editable: "never" },
    { title: "Age", field: "age", editable: "never" },
    { title: "Country", field: "country", editable: "never" },
    { title: "Course Fee", field: "courseFee", editable: "never" },
    {
      title: "Category",
      field: "category",
      lookup: { P: "Primary", E: "PEK", U: "UOC", K: "Kindergarten" },
    },
    {
      title: "Class Type",
      field: "classType",
      lookup: { O: "Online", P: "Physical" },
    },
    {
      title: "Taken Care ET",
      field: "SalesPerson",
      editable: "never",
    },
    {
      title: "Recruit By",
      field: "recruitByET",
      editable: "never",
    },
    { title: "Withdrawal Date", field: "withdrawalDate", editable: "never" },
    {
      title: "Status", field: "status", editable: "never",
      lookup: { Active: "Active", Withdraw: "Withdraw", OH: "OH", WFO: "Transferring" },
    },
    {
      title: "Stop", field: "stop", editable: "never",
      lookup: { Y: "Yes", N: "No"},
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readStudent">
          <MaterialTable
            title=""
            columns={columns}
            data={studentList}
            icons={tableIcons}
            key={studentList.length}
            options={{
              sorting: true,
              filtering: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              paging: true,
              pageSize: 25,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [25, 50, 75, 100],
              exportButton: true,
              exportAllData: true,
              exportFileName: "Student List",
              selection: false,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: true,
              tableLayout: "auto",
              rowStyle: {
                overflowWrap: "break-word",
              },
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "75vh",
            }}
            actions={[
              {
                icon: () => (
                  <BiEdit style={{ color: "blue", fontSize: "1em" }} />
                ),
                tooltip: "Edit",
                isFreeAction: false,
                onClick: (event, rowData) =>
                  navigate(`/updateStudent/${rowData.id}`),
              },
              {
                icon: () => (
                  <FaEye style={{ color: "blue", fontSize: "1em" }} />
                ),
                tooltip: "View Payment & Attendance",
                isFreeAction: false,
                onClick: (event, rowData) =>
                  window.open(`${process.env.REACT_APP_SERVER_DOMAIN}Student/readPaymentDetails.php?studentID=${rowData.id}`, '_blank', 'noopener,noreferrer'),
              },
              rowData => ({
                icon: () => (
                  <BiTrash style={{ color: "red", fontSize: "1em" }} />
                ),
                tooltip: "Delete",
                isFreeAction: false,
                onClick: (event, rowData) => {
                  const confirmed = window.confirm("Are you sure you want to delete this student?");
                  if (confirmed) { 
                    handleDelete(rowData.id);
                  }
                },
                hidden: rowData.attendanceCount === false || authState.id != "6577" ,
              }),
            ]}
          />
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;
