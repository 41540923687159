import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import { BiUndo } from "react-icons/bi";

import Heading from "../Heading";
import Info from "./Create/Info";
import Schedule from "./Create/Schedule";

function Create() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("info");
  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    teacherID: "",
    trainerID: "",
    studentList: {},
    learningType: "",
    category: "",
    day: "",
    startTime: "00:00",
    endTime: "00:00",
    lessonPlan: "",
    language: "",
    remark: "",
    venue: "Center",
    venueState: "",
    venueID: "",
  });

  let submitBtn = false;

  if (
    submitValue.teacherID !== "" &&
    submitValue.trainerID !== "" &&
    submitValue.studentList !== "" &&
    submitValue.learningType !== "" &&
    submitValue.category !== "" &&
    submitValue.lessonPlan !== "" &&
    submitValue.language !== "" &&
    submitValue.day !== "" &&
    submitValue.startTime !== "00:00" &&
    submitValue.endTime !== "00:00"
  ) {
    submitBtn = true;
  }

  const submitForm = () => {
    setLoading(true);
    //get studentID
    const studentArr = JSON.stringify(submitValue.studentList);

    let createClass = new FormData();
    createClass.append("id", submitValue.id);
    createClass.append("teacherID", submitValue.teacherID);
    createClass.append("trainerID", submitValue.trainerID);
    createClass.append("studentList", studentArr);
    createClass.append("learningType", submitValue.learningType);
    createClass.append("category", submitValue.category);
    createClass.append("day", submitValue.day);
    createClass.append("startTime", submitValue.startTime);
    createClass.append("endTime", submitValue.endTime);
    createClass.append("lessonPlan", submitValue.lessonPlan);
    createClass.append("language", submitValue.language);
    createClass.append("remark", submitValue.remark);
    createClass.append("venue", submitValue.venue);
    createClass.append("venueState", submitValue.venueState);
    createClass.append("venueID", submitValue.venueID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Class/create.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: createClass,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/class"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createClass" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content="Create New Class" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/class">
                  <BiUndo /> Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="info" title="Info">
                <Info
                  setSubmitValue={setSubmitValue}
                  submitValue={submitValue}
                  setKey={setKey}
                />
              </Tab>
              <Tab eventKey="schedule" title="Schedule">
                <Schedule setSubmitValue={setSubmitValue} setKey={setKey} />
              </Tab>
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
