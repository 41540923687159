import React, {
  forwardRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "axios";
import { AuthContext } from "../../../Helpers/AuthContext";
import { toast } from "react-toastify";

import { BiEdit } from "react-icons/bi";
import { BiCheckSquare } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { FiLoader } from "react-icons/fi";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import Heading from "../../Heading";
import { BiUndo } from "react-icons/bi";

function ViewPayment() {
  const { studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [currentStudentInformation, setCurrentStudentInformation] = useState([[]]);
  
  useEffect(() => {
    let studentInformation = new FormData();
    studentInformation.append("studentID", studentID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/readPaymentHistory.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: studentInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setPaymentHistory(result.data[0]);
        setCurrentStudentInformation(result.data[1]);
      }
    });
  }, [studentID]);
  
  const columns = [
    { title: "Payment Year", field: "paymentYear", editable: "never" },
    { title: "Payment Details", field: "paymentType", editable: "never" },
    { title: "Payment Slip Date", field: "paymentSlipDate", editable: "never" },
    { title: "Course Fee", field: "courseFee", editable: "never",type: 'number' },
    { title: "Material Fee", field: "materialFee", editable: "never",type: 'number' },
    { title: "Penalty Fee", field: "penaltyFee", editable: "never", type: 'number' },
    { title: "Registration Fee", field: "registrationFee", editable: "never", type: 'number' },
    { title: "Total Amount", field: "totalAmount", editable: "never",type: 'number' },
    { title: "Confirm Payment Slip Date", field: "confirmPaymentSlipDate", editable: "never" },
    { title: "Confirm Payment Year", field: "confirmPaymentYear", editable: "never", type: 'number' },
    { title: "Confirm Payment Type", field: "confirmPaymentType", editable: "never", type: 'number' },
    { title: "Confirm Course Fee", field: "confirmCourseFee", editable: "never",type: 'number' },
    { title: "Confirm Material Fee", field: "confirmMaterialFee", editable: "never",type: 'number' },
    { title: "Confirm Penalty Fee", field: "confirmPenaltyFee", editable: "never", type: 'number' },
    { title: "Confirm Registration Fee", field: "confirmRegistrationFee", editable: "never", type: 'number' },
    { title: "Confirm Discount Amount", field: "confirmDiscountAmount", editable: "never",type: 'number' },
    { title: "Confirm Overpay or Underpay", field: "overpayOrUnderpayText", editable: "never", type: 'number' },
    { title: "Confirm Total Amount", field: "confirmTotalAmount", editable: "never",type: 'number' },
    {
      title: "Status",
      field: "status",
      editable: "never",
      lookup: { 
        0: "Unpaid",//<Col><ImCross style={{ color: "red", fontSize: "1.5em" }}/></Col>,
        1: <Col><AiOutlineCheck style={{ color: "green", fontSize: "1.5em" }} /></Col>,
        2: <Col><FiLoader style={{ color: "black", fontSize: "1.5em" }} /></Col>,
        3: <Col><ImCross style={{ color: "red", fontSize: "1.5em" }}/></Col>, 
        5: <Col><FiLoader style={{ color: "black", fontSize: "1.5em" }}/></Col>,
      }
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="addPayment" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content={"Payment history for "+currentStudentInformation[0].englishName+ " " +currentStudentInformation[0].chineseName} design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <Button variant="outline-secondary" className="float-end" size="sm" href="/payment">
                <BiUndo /> Back
              </Button>
            </Col>
          </Row>
          <div id="readPaymentHistory">
            <MaterialTable
              title=""
              columns={columns}
              data={paymentHistory}
              icons={tableIcons}
              key={paymentHistory.length}
              options={{
                sorting: true,
                filtering: true,
                search: true,
                searchFieldAlignment: "right",
                searchAutoFocus: true,
                searchFieldVariant: "standard",
                paging: true,
                pageSize: 25,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [25, 50, 75, 100],
                exportButton: true,
                exportAllData: true,
                exportFileName: "Student Payment History",
                //actionsColumnIndex: -1,
                selection: false,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                grouping: false,
                columnsButton: true,
                tableLayout: "auto",
                rowStyle: {
                  overflowWrap: "break-word",
                },
                headerStyle: { position: "sticky", top: 0, zIndex: 1 },
                maxBodyHeight: "75vh",
              }}
              // actions={[
              //   rowData => ({
              //     icon: () => <BiEdit style={{ color: "blue" }} />,
              //     tooltip: "Edit payment",
              //     onClick: (event, rowData) => {
              //       if (rowData.status == 1 || rowData.status == 3){
              //         alert("You cannot edit the payment slip that has been verified or rejected.");
              //       }else {
              //         navigate(`/updatePayment/${rowData.id}/${studentID}`)  
              //       }},
              //   }),
              //   /*{
              //     icon: () => <BiCheckSquare style={{ color: "blue" }} />,
              //     tooltip: "Verify payment",
              //     isFreeAction: false,
              //     onClick: (event, rowData) =>
              //       navigate(`/verifyPayment/${rowData.id}/${studentID}`),
              //   },*/
              // ]}
            />
          </div>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default ViewPayment;
