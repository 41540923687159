import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import moment from "moment";

import StudentList from "./StudentList";

import { FcAlarmClock, FcCalendar, FcReadingEbook } from "react-icons/fc";
import { FaChalkboardTeacher, FaLanguage } from "react-icons/fa";
import { BiXCircle, BiCheckCircle } from "react-icons/bi";

function ShortCard({ classList, searchTerm }) {
  return (
    <Row xs={1} md={2} className="mx-5 my-2 gx-4 gy-2">
      {classList
        .filter((classList) => {
          if (searchTerm === "") {
            return classList;
          } else if (
            classList.classCode.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return classList;
          }
          return false;
        })
        .map((classList, index) => {
          const classID = classList.id;
          const classCode = classList.classCode;
          const learningType = classList.learningType;
          const day = classList.day;
          const lessonPlan = classList.lessonPlan;
          const language = classList.language;
          const status = classList.status;

          let pillDesign;
          if (status == 1 ) {
            pillDesign = "badge rounded-pill bg-warning bg-gradient p-2 text-dark bg-opacity-50";
          } else {
            pillDesign = "badge rounded-pill bg-danger bg-gradient p-2 text-dark bg-opacity-50";
          }
          const startTime = moment(classList.startTime, "hh:mm:ss").format(
            "hh:mm a"
          );
          const endTime = moment(classList.endTime, "hh:mm:ss").format(
            "hh:mm a"
          );

          let learningTypeBG;
          switch (learningType) {
            case "O":
              learningTypeBG = "Online Class";
              break;
            default:
              learningTypeBG = "Physical Class";
          }
          return (
            <Col key={index}>
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>
                    <Link
                      className="link-dark text-decoration-none"
                      to="/attendance"
                      state={{ classCode: classCode }}
                    >
                      {classCode}{" "}
                    </Link>
                  </Card.Title>
                  <div className="d-flex flex-wrap align-items-center text-start shortListDetail mb-4">
                    <p className={pillDesign}>
                      {status == 1 ? (
                        <>
                          <BiCheckCircle className="mx-1" /> Active
                        </>
                      ) : (
                        <>
                          <BiXCircle className="mx-1" />
                          Inactive
                        </>
                      )}
                    </p>
                    <p className={pillDesign}>
                      <FcCalendar className="mx-1" /> {day}
                    </p>
                    <p className={pillDesign}>
                      <FcAlarmClock className="mx-1" /> {startTime} - {endTime}
                    </p>
                    <p className={pillDesign}>
                      <FaChalkboardTeacher className="mx-1" /> {learningTypeBG}
                    </p>
                    <p className={pillDesign}>
                      <FcReadingEbook className="mx-1" /> {lessonPlan}
                    </p>
                    <p className={pillDesign}>
                      <FaLanguage className="mx-1" /> {language}
                    </p>
                  </div>
                  <StudentList classID={classID} />
                </Card.Body>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
}

export default ShortCard;
