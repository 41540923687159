import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";

import ClassType from "../../../Data/ClassType";
import Category from "../../../Data/StudentCategory";
import Currency from "currency-codes/data";
import Gender from "../../../Data/Gender";
import Status from "../../../Data/Status";
import Plan from "../../../Data/Plan";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { BiX, BiPlus, BiChevronLeft } from "react-icons/bi";

function StudentB2B({
  setKey,
  submitValue,
  setSubmitValue,
  studentList,
  setStudentList,

  handleStudentList,
  addRow,
  deleteRow,
}) {
  const optionInput = "form-select";
  const textInput = "form-control";
  const [installmentOptions, setInstallmentOptions] = useState([]);

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Installment/read.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setInstallmentOptions(
          result[0].data.map((installmentOption) => {
            return (
              <option key={installmentOption.id} value={installmentOption.id}>
                {installmentOption.title} - MYR {installmentOption.amount}
              </option>
            );
          })
        );
      })
    );
  }, [submitValue.classID]);

  let classTypeOptions = [];
  ClassType.map((item) => {
    classTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let categoryOptions = [];
  Category.map((item) => {
    categoryOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let currencyOptions = [];
  Currency.map((item) => {
    currencyOptions.push(
      <option value={item.code} key={item.code}>
        {item.currency} ({item.code})
      </option>
    );
  });

  let genderOptions = [];
  Gender.map((item) => {
    genderOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let statusOptions = [];
  Status.map((item) => {
    statusOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let planOptions = [];
  Plan.map((item) => {
    planOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });
  
  const collectionFee = event => {
    if (event.target.checked) {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        collectByTeacher: 1,
      }))
    } else {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        collectByTeacher: 0,
      }))
    }
  };


  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label required">Type</label>
              <label className="form-label required">Category</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    classType: e.target.value,
                  }))
                }
                value={submitValue.classType}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {classTypeOptions}
              </select>

              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    category: e.target.value,
                  }))
                }
                value={submitValue.category}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {categoryOptions}
              </select>
            </div>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <label className="form-label required">Currency</label>
              <label className="form-label required">Course Fee</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    currency: e.target.value,
                  }))
                }
                value={submitValue.currency}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {currencyOptions}
              </select>
              <input
                type="number"
                min="0"
                step=".01"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    courseFee: e.target.value,
                  }))
                }
                value={submitValue.courseFee}
              />
            </div>
            <input type="checkbox" 
            onChange={collectionFee}
            /> Course fee will be collect by teacher
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label">English Name</label>
              <label className="form-label">Chinese Name</label>
            </div>
          </Col>
          <Col sm={2}>
            <p>Guardian Number</p>
          </Col>
          <Col sm={2}>
            <p className="required">Gender</p>
          </Col>
          <Col sm={1}>
            <p className="required">Age</p>
          </Col>
          <Col sm={2}>
            <p>Status</p>
          </Col>
          {/* <Col sm={2}>
            <p>Installment</p>
          </Col>*/}
         {/* <Col sm={1}>
            <p>Plan</p>
          </Col> */}
        </Row>
        {studentList.map((input, index) => {
          return (
            <Row key={index} className="mb-3">
              <Col sm={4} className="px-1">
                <div className="input-group flex-nowrap">
                  <input
                    type="text"
                    className={textInput}
                    placeholder="English"
                    name="englishName"
                    onChange={(event) =>
                      handleStudentList(
                        index,
                        event.target.name,
                        event.target.value.toUpperCase()
                      )
                    }
                    value={studentList[index].englishName}
                  />
                  <input
                    type="text"
                    className={textInput}
                    placeholder="Chinese"
                    name="chineseName"
                    onChange={(event) =>
                      handleStudentList(
                        index,
                        event.target.name,
                        event.target.value.toUpperCase()
                      )
                    }
                    value={studentList[index].chineseName}
                  />
                </div>
              </Col>
              <Col sm={2} className="px-1">
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="MY"
                  onChange={(event) =>
                    handleStudentList(index, "guardianPhoneNumber", event)
                  }
                />
              </Col>
              <Col sm={2} className="px-1">
                <select
                  className={optionInput}
                  name="gender"
                  onChange={(event) =>
                    handleStudentList(
                      index,
                      event.target.name,
                      event.target.value
                    )
                  }
                  value={studentList[index].gender}
                >
                  <option disabled value="" key="Select...">
                    Select
                  </option>
                  {genderOptions}
                </select>
              </Col>
              <Col sm={1} className="px-1">
                <input
                  type="number"
                  className={textInput}
                  placeholder="Age"
                  min="1"
                  max="100"
                  name="age"
                  onChange={(event) =>
                    handleStudentList(
                      index,
                      event.target.name,
                      event.target.value
                    )
                  }
                  value={studentList[index].age}
                />
              </Col>
              <Col sm={2} className="px-1">
                <select
                  className={optionInput}
                  name="status"
                  onChange={(event) =>
                    handleStudentList(
                      index,
                      event.target.name,
                      event.target.value
                    )
                  }
                  value={studentList[index].status}
                >
                  {statusOptions}
                </select>
              </Col>

              {/* <Col sm={2} className="px-1">
                <select
                  className={optionInput}
                  name="installment"
                  onChange={(event) =>
                    handleStudentList(
                      index,
                      event.target.name,
                      event.target.value
                    )
                  }
                  value={studentList[index].installment}
                >
                  <option value="0" key="Select...">
                    Select
                  </option>
                  {installmentOptions}
                </select>
              </Col> */}
              {/* <Col sm={1} className="px-1">
                <select
                  className={optionInput}
                  name="plan"
                  onChange={(event) =>
                    handleStudentList(
                      index,
                      event.target.name,
                      event.target.value
                    )
                  }
                  value={studentList[index].plan}
                >
                  <option value="0" key="Select...">
                    Select
                  </option>
                  {planOptions}
                </select>
              </Col> */}
              <Col sm={1}>
                <Button variant="danger" onClick={() => deleteRow(index)}>
                  <BiX size={25} />
                </Button>
              </Col>
            </Row>
          );
        })}

        <Row className="justify-content-end mb-3">
          <Col sm={1}>
            <Button
              variant="success"
              onClick={addRow}
              disabled={studentList.length === 15}
            >
              <BiPlus size={25} />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                <BiChevronLeft /> Previous
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StudentB2B;
