const ClassStatus= [

    {
      value: "",
      label: "All",
    },
    {
      value: "1",
      label: "Active",
    },
    {
      value: "0",
      label: "Inactive",
    },
  ];
  
  export default ClassStatus;
  