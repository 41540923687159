import React, { useState, useEffect } from "react";
import Axios from "axios";
import BearIcon from "../Assests/Images/bearIcon.png";

function StudentFeeCollectionBy(props) {
  const userID = props.id;
  const [feeCollectionBy, setFeeCollectionBy] = useState([]);

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentFeeCollectionBy.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {

      if (result.data === undefined || result.data.length == 0) {
        setFeeCollectionBy("-");
      } else {
        setFeeCollectionBy(result.data);
      }
    });
  }, [userID]);

  return (
    <>
      {feeCollectionBy.collectByTeacher === "1" && (
          <img src={BearIcon} className="bearIcon"/>
      )}
    </>
  );
}

export default StudentFeeCollectionBy;
