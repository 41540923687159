import React, { Fragment, useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

import { Table, Row, Col } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Heading from "../../Heading";
import StudentName from "../../StudentName";
import StudentCode from "../../StudentCode";
import Replace from "./Replace";
import Indicator from "./Indicator";
import StudentLatestLearningProgress from "../../StudentLatestLearningProgress";
import StudentLatestPayment from "../../StudentLatestPayment";
import StudentFeeCollectionBy from "../../StudentFeeCollectionBy";
import StudentCourseFee from "../../StudentCourseFee";
import StudentAge from "../../StudentAge";

function Record({ classID, realteacherID, filterTerm }) {
  const [dateAttendance, setDateAttendance] = useState([]);
  const [getAttendanceDate, setAttendanceDate] = useState([]);
  const [getStudentNoAttendance, setGetStudentNoAttendance] = useState([]);

  useEffect(() => {
    let getInfo = new FormData();
    getInfo.append("classID", classID);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/getDate.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: getInfo,
    }).then((response) => {
      if (response.data.error) {
        return toast.error(
          <Fragment>
            <span>{response.data.error}</span>
          </Fragment>
        );
      } else {
        setDateAttendance(response.data);
      }
    });

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/getStudentID.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: getInfo,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setAttendanceDate(result.data);
      }
    });

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/studentList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: getInfo,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setGetStudentNoAttendance(result.data);
      }
    });
  }, [classID]);

  return (
    <div>
      {dateAttendance.warn ? (
        <Table striped bordered hover responsive className="ms-3">
          <thead>
            <tr>
              <th>Student Name</th>
            </tr>
          </thead>
          <tbody>
            {getStudentNoAttendance.map((getStudentNoAttendance, index) => {
              const studentID = getStudentNoAttendance.studentID;
              return (
                <tr key={index}>
                  <td className="tableStudent">
                    <p className="mb-0 fs-7">
                      <StudentCode id={studentID} /> -{" "}
                      <StudentName id={studentID} />
                      <StudentAge id={studentID} />
                    </p>
                    <p className="mb-0 fs-7">
                      <StudentLatestLearningProgress id={studentID} />
                    </p>
                    <p className="mb-0 fs-7">
                      <StudentFeeCollectionBy id={studentID} />{" "}
                      <StudentCourseFee id={studentID} />
                    </p>
                    <p className="mb-0 fs-7">
                      <StudentLatestPayment id={studentID} />
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Table striped bordered hover responsive className="ms-3">
          <thead>
            <tr>
              <th>Student Name</th>
              {dateAttendance
                .filter((dateAttendance) => {
                  if (filterTerm.startDate == "") {
                    return dateAttendance;
                  } else if (
                    filterTerm.startDate <= dateAttendance.classDateList
                  ) {
                    return dateAttendance;
                  }
                  return false;
                })

                .filter((dateAttendance) => {
                  if (filterTerm.endDate == "") {
                    return dateAttendance;
                  } else if (
                    filterTerm.endDate >= dateAttendance.classDateList
                  ) {
                    return dateAttendance;
                  }
                  return false;
                })

                .map((dateAttendance) => {
                  const dateList = dateAttendance.classDateList;
                  const keyID = dateAttendance.id;
                  return (
                    <>
                      {dateList === "" ? null : (
                        <th key={keyID}>
                          {moment(dateList).format("DD/MM/YYYY")}

                          <div className="float-end">
                            <Replace
                              realteacherID={realteacherID}
                              classID={classID}
                              classDate={moment(dateList).format("YYYY-MM-DD")}
                            />
                            <a href={`/updateAttendance/${classID}/${dateList}`}>
                              <BiPencil className="icon" />
                            </a>
                          </div>
                        </th>
                      )}
                    </>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {getAttendanceDate.map((getAttendanceDate, index) => {
              const studentID = getAttendanceDate.studentID;

              return (
                <tr key={index}>
                  <td className="tableStudent">
                    <p className="mb-0 fs-7">
                      <StudentCode id={studentID} /> -{" "}
                      <StudentName id={studentID} />
                      <StudentAge id={studentID} />
                    </p>
                    <p className="mb-0 fs-7">
                      <StudentLatestLearningProgress id={studentID} />
                    </p>
                    <p className="mb-0 fs-7">
                      <StudentFeeCollectionBy id={studentID} />{" "}
                      <StudentCourseFee id={studentID} />
                    </p>
                    <p className="mb-0 fs-7">
                      <StudentLatestPayment id={studentID} />
                    </p>
                  </td>

                  {dateAttendance
                    .filter((dateAttendance) => {
                      if (filterTerm.startDate == "") {
                        return dateAttendance;
                      } else if (
                        filterTerm.startDate <= dateAttendance.classDateList
                      ) {
                        return dateAttendance;
                      }
                      return false;
                    })

                    .filter((dateAttendance) => {
                      if (filterTerm.endDate == "") {
                        return dateAttendance;
                      } else if (
                        filterTerm.endDate >= dateAttendance.classDateList
                      ) {
                        return dateAttendance;
                      }
                      return false;
                    })
                    .map((object, index) => {
                      const attendance = getAttendanceDate[object.classDateList];
                      const indicatorDate = object.classDateList;
                      
                      var attendanceData = [];

                      if (attendance !== "" && attendance != null) {
                        attendanceData = attendance.split("=");
                      } else {
                        attendanceData[0] = "-";
                        attendanceData[1]="";
                      }
                      
                      return (
                        <td key={index} index={studentID}>
                          <p className="mb-0 fs-7">
                          {attendance !== "" ? (attendanceData[0]=="WFO" ? "Transferring":attendanceData[0]) : "-"}
                          
                          {attendanceData[1] !== "" ? <Tooltip title={attendanceData[1]}>
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip> :""}

                            <Indicator
                              indicatorDates={indicatorDate}
                              studentID={studentID}
                            />
                          </p>
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Record;
