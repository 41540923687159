import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Tab, Tabs, Spinner } from "react-bootstrap";

import Heading from "../Heading";
import Center from "./Update/Center";
import Guardian from "./Update/Guardian";
import Student from "./Update/Student";

import { BiUndo } from "react-icons/bi";

function Update() {
  const { studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("center");
  const [loading, setLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    studentID: studentID,
    businessType: "",
    salespersonID: "",
    centerID: "",
    centerState: "",
    classType: "",
    category: "",
    currency: "",
    courseFee: "",
    kidPhoneNumber: "",
    schoolName: "",
    schoolState: "",
    englishName: "",
    chineseName: "",
    guardianID: "",
    gender: "",
    age: "",
    tempStudentStatus: "",
    status: "",
    guardianPhoneNumber: "",
    guardianName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateRegion: "",
    zip: "",
    country: "",
    classLocation: "",
    collectByTeacher: "",
  });

  useEffect(() => {
    let updateStudent = new FormData();
    updateStudent.append("studentID", studentID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/update.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updateStudent,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        let kidPhone;
        if (result.data.studentInfo[0][0].phoneNumber > 0) {
          kidPhone = "+" + result.data.studentInfo[0][0].phoneNumber;
        } else {
          kidPhone = "";
        }

        setSubmitValue((submitValue) => ({
          ...submitValue,
          businessType: result.data.studentInfo[0][0].businessType,
          salespersonID: result.data.studentInfo[0][0].salespersonID,
          classType: result.data.studentInfo[0][0].classType,
          category: result.data.studentInfo[0][0].category,
          currency: result.data.studentInfo[0][0].currency,
          courseFee: result.data.studentInfo[0][0].courseFee,
          country: result.data.studentInfo[0][0].country,
          kidPhoneNumber: kidPhone,
          schoolName: result.data.studentInfo[0][0].schoolName,
          classLocation: result.data.studentInfo[0][0].classLocation,
          schoolState: result.data.studentInfo[0][0].schoolState,
          englishName: result.data.studentInfo[0][0].englishName,
          chineseName: result.data.studentInfo[0][0].chineseName,
          guardianID: result.data.studentInfo[0][0].guardianID,
          gender: result.data.studentInfo[0][0].gender,
          age: result.data.studentInfo[0][0].age,
          tempStudentStatus: result.data.studentInfo[0][0].tempStudentStatus,
          status: result.data.studentInfo[0][0].status,
          stopStatus: result.data.studentInfo[0][0].stop,
          collectByTeacher: result.data.studentInfo[0][0].collectByTeacher,
        }));

        //Center Info
        if (result.data.centerInfo[0].length > 0) {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            centerID: result.data.centerInfo[0][0].id,
            centerState: result.data.centerInfo[0][0].state,
          }));
        } else {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            centerID: 0,
            centerState: "",
          }));
        }

        //Guardian Info
        if (result.data.guardianInfo[0].length > 0) {
          const phone = "+" + result.data.guardianInfo[0][0].phoneNumber;
          setSubmitValue((submitValue) => ({
            ...submitValue,
            guardianPhoneNumber: phone,
            guardianName: result.data.guardianInfo[0][0].name,
            addressLine1: result.data.guardianInfo[0][0].addressLine1,
            addressLine2: result.data.guardianInfo[0][0].addressLine2,
            city: result.data.guardianInfo[0][0].city,
            stateRegion: result.data.guardianInfo[0][0].state,
            zip: result.data.guardianInfo[0][0].zip,
            country: result.data.guardianInfo[0][0].country,
          }));

          if (result.data.guardianInfo[0][0].addressLine1 == null) {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              addressLine1: "",
            }));
          }

          if (result.data.guardianInfo[0][0].addressLine2 == null) {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              addressLine2: "",
            }));
          }

          if (result.data.guardianInfo[0][0].city == null) {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              city: "",
            }));
          }

          if (result.data.guardianInfo[0][0].state == null) {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              stateRegion: "",
            }));
          }

          if (result.data.guardianInfo[0][0].zip == null) {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              zip: "",
            }));
          }
        } else {
          setSubmitValue((submitValue) => ({
            ...submitValue,
            guardianPhoneNumber: "",
            guardianName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            stateRegion: "",
            zip: "",
            country: "MY",
          }));
        }
        
      }
    });
  }, [studentID]);

  let submitBtn = false;

  if (submitValue.businessType === "B2B") {
    if (
      submitValue.businessType !== "" &&
      submitValue.salespersonID !== "" &&
      submitValue.centerID !== "" &&
      submitValue.classLocation !== "" &&
      submitValue.classType !== "" &&
      submitValue.category !== "" &&
      submitValue.courseFee > 0 &&
      submitValue.courseFee !== ""
    ) {
      submitBtn = true;
    }
  } else {
    if (
      submitValue.businessType !== "" &&
      submitValue.salespersonID !== "" &&
      submitValue.country !== "" &&
      submitValue.classType !== "" &&
      submitValue.classLocation !== "" &&
      submitValue.category !== "" &&
      submitValue.courseFee > 0 &&
      submitValue.courseFee !== "" &&
      submitValue.guardianPhoneNumber !== "" &&
      submitValue.gender !== "" &&
      submitValue.age !== "" &&
      (submitValue.englishName !== "" || submitValue.chineseName !== "")
    ) {
      submitBtn = true;
    }
  }
  console.log(submitValue);
  const submitForm = () => {
    setLoading(true);

    let updateID = new FormData();
    updateID.append("id", submitValue.id);
    updateID.append("studentID", submitValue.studentID);
    updateID.append("businessType", submitValue.businessType);
    updateID.append("salespersonID", submitValue.salespersonID);
    updateID.append("centerID", submitValue.centerID);
    updateID.append("centerState", submitValue.centerState);
    updateID.append("classType", submitValue.classType);
    updateID.append("category", submitValue.category);
    updateID.append("currency", submitValue.currency);
    updateID.append("courseFee", submitValue.courseFee);
    updateID.append("country", submitValue.country);
    updateID.append("kidPhoneNumber", submitValue.kidPhoneNumber);
    updateID.append("schoolName", submitValue.schoolName);
    updateID.append("schoolState", submitValue.schoolState);
    updateID.append("englishName", submitValue.englishName);
    updateID.append("chineseName", submitValue.chineseName);
    updateID.append("guardianID", submitValue.guardianID);
    updateID.append("gender", submitValue.gender);
    updateID.append("age", submitValue.age);
    updateID.append("tempStudentStatus", submitValue.tempStudentStatus);
    updateID.append("status", submitValue.status);
    updateID.append("stop", submitValue.stopStatus);
    updateID.append("guardianPhoneNumber", submitValue.guardianPhoneNumber);
    updateID.append("guardianName", submitValue.guardianName);
    updateID.append("addressLine1", submitValue.addressLine1);
    updateID.append("addressLine2", submitValue.addressLine2);
    updateID.append("city", submitValue.city);
    updateID.append("stateRegion", submitValue.stateRegion);
    updateID.append("zip", submitValue.zip);
    updateID.append("country", submitValue.country);
    updateID.append("classLocation", submitValue.classLocation);
    updateID.append("collectByTeacher", submitValue.collectByTeacher);
    
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/updateID.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: updateID,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/student"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createStudent" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content="Update Student" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/student">
                  <BiUndo /> Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="center" title="Center">
                <Center
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                />
              </Tab>
              <Tab eventKey="guardian" title="Guardian">
                <Guardian
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                />
              </Tab>
              <Tab eventKey="student" title="Student">
                <Student
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                />
              </Tab>
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
              {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Update{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Update;
