import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { getData } from "country-list";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import State from "../../../Data/State";

import { BiChevronRight, BiChevronLeft } from "react-icons/bi";

const countryOption = getData().sort();

function Guardian({ submitValue, setSubmitValue, setKey }) {
  const optionInput = "form-select";
  const textInput = "form-control";

  let countryOptions = [];
  countryOption.sort().map(({ code, name }) => {
    countryOptions.push(
      <option value={code} key={code}>
        {name}
      </option>
    );
  });

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Guardian Phone Number</label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="MY"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  guardianPhoneNumber: e,
                }))
              }
              value={submitValue.guardianPhoneNumber}
            />
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Guardian Name</label>
            <input
              type="text"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  guardianName: e.target.value.toUpperCase(),
                }))
              }
              value={submitValue.guardianName}
            />
          </Col>
          <Col sm={12} className="mb-3">
            <label className="form-label">Address Line 1</label>
            <input
              type="text"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  addressLine1: e.target.value,
                }))
              }
              value={submitValue.addressLine1}
            />
          </Col>
          <Col sm={12} className="mb-3">
            <label className="form-label">Address Line 2</label>
            <input
              type="text"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  addressLine2: e.target.value,
                }))
              }
              value={submitValue.addressLine2}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">City</label>
            <input
              type="text"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  city: e.target.value,
                }))
              }
              value={submitValue.city}
            />
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">State / Province / Region</label>

            <Select
              options={State}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  stateRegion: e.value,
                }))
              }
              value={State.find((obj) => obj.value === submitValue.stateRegion)}
            />
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">ZIP / Postal Code</label>
            <input
              type="number"
              min="0"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  zip: e.target.value,
                }))
              }
              value={submitValue.zip}
            />
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Country</label>

            <select
              className={optionInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  country: e.target.value,
                }))
              }
              value={submitValue.country}
            >
              {countryOptions}
            </select>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("center")}
              >
                <BiChevronLeft /> Previous
              </Button>{" "}
              <Button
                variant="outline-secondary"
                onClick={() => setKey("student")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Guardian;
