import React, { useEffect, useState } from "react";
import Select from "react-select";
import Axios from "axios";

import { Form, Button, Row, Col } from "react-bootstrap";
import { BiChevronLeft } from "react-icons/bi";

import StudentCode from "../../StudentCode";
import StudentName from "../../StudentName";
import Heading from "../../Heading";

function Student({
  attendanceArr,
  studentAttendanceOverall,
  studentAttendanceInfo,

  learningProgressArr,
  studentLearningProgressOverall,
  studentLearningProgressInfo,

  homeworkArr,
  studentHomeworkOverall,
  studentHomeworkInfo,

  remarkArr,
  studentRemarkOverall,
  studentRemarkInfo,

  setKey,
  submitValue,
}) {
  const [homeworkOptions, setHomeworkOptions] = useState([]);
  const [attendanceOptions, setAttendanceOptions] = useState([]);
  const [learningProgressOptions, setLearningProgressOptions] = useState([]);
  const emptyOption = { label: "Select...", value: "" };

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/homework.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/attendance.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/learningProgress.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setHomeworkOptions(
          result[0].data.map(({ id, title }) => ({
            label: title,
            value: id,
          }))
        );
        setHomeworkOptions((homeworkOptions) => [
          emptyOption,
          ...homeworkOptions,
        ]);

        setAttendanceOptions(
          result[1].data.map(({ value }) => ({
            label: value === "WFO" ? "Transferring" : value,
            value: value,
          }))
        );

        setLearningProgressOptions(
          result[2].data.map(({ id, title }) => ({
            label: title,
            value: id,
          }))
        );
      })
    );
  }, [submitValue.classID]);

  return (
    <div>
      <Row>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Student ID</th>
              <th scope="col">Name</th>
              <th scope="col">
                <p className="required mb-0">Attendance</p>
                <p className="fs-7 mb-0 text-muted">
                  Please fill in the column
                </p>
              </th>
              <th scope="col">
                <p className="required mb-0">Learning Progress</p>
                <p className="fs-7 mb-0 text-muted">
                  Please fill in the column
                </p>
              </th>
              <th scope="col">Homework</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" colSpan="2" className="text-center">
                <Heading content="Select for all" design="h4" />
              </th>
              <td>
                <Select
                  options={[
                    ...attendanceOptions.filter(option => option.value !== 'Transfer'),
                    {
                      ...attendanceOptions.find(option => option.value === 'Transfer'),
                      isDisabled: true
                    }
                  ]}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(e) =>
                    studentAttendanceOverall(e, submitValue.studentList)
                  }
                />
              </td>
              <td>
                <Select
                  options={learningProgressOptions}
                  // isOptionDisabled={(option) =>
                  //   option.value == "173" 
                  // }
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(e) =>
                    studentLearningProgressOverall(e, submitValue.studentList)
                  }
                />
              </td>
              <td>
                <Select
                  options={homeworkOptions}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(e) =>
                    studentHomeworkOverall(e, submitValue.studentList)
                  }
                />
              </td>
              <td>
                <Form.Control
                  as="textarea"
                  rows={2}
                  onChange={(e) =>
                    studentRemarkOverall(e, submitValue.studentList)
                  }
                />
              </td>
            </tr>
            {submitValue.studentList.map((studentID, index) => (
              <tr key={index}>
                <th scope="row">
                  <StudentCode id={studentID} />
                </th>
                <td>
                  <StudentName id={studentID} />
                </td>
                <td>
                  <Select
                    options={[
                      ...attendanceOptions.filter(option => option.value !== 'Transfer'),
                      {
                        ...attendanceOptions.find(option => option.value === 'Transfer'),
                        isDisabled: true
                      }
                    ]}
                    className="basic-single"
                    classNamePrefix="select"
                    
                    onChange={(e) => studentAttendanceInfo(e, index)}
                    value={attendanceOptions.filter(
                      (option) => option.value === attendanceArr[index]
                    )}
                  />
                </td>
                <td>
                  <Select
                    options={learningProgressOptions}
                    // isOptionDisabled={(option) =>
                    //   option.value == "173" 
                    // }
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(e) => studentLearningProgressInfo(e, index)}
                    value={learningProgressOptions.filter(
                      (option) => option.value === learningProgressArr[index]
                    )}
                  />
                </td>
                <td>
                  <Select
                    options={homeworkOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(e) => studentHomeworkInfo(e, index)}
                    value={homeworkOptions.filter(
                      (option) => option.value === homeworkArr[index]
                    )}
                  />
                </td>
                <td>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    onChange={(e) => studentRemarkInfo(e, index)}
                    value={remarkArr[index]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="float-end">
            <Button variant="outline-secondary" onClick={() => setKey("class")}>
              <BiChevronLeft /> Previous
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Student;
