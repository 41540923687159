import React from "react";
import { Col, Table } from "react-bootstrap";
import Heading from "../../../Heading";

function Calculate({ plan, submitValue }) {
    let price = submitValue.amount;
    let installment = plan;
    let newY;
    let newX;

    if (price !== 0 && installment !== 0) {
        newY = Math.trunc(price / installment);
        newX = (price - newY) / (installment - 1);
        if (newX % 1 !== 0) {
            newX = Math.trunc(newX);
            newY = price - newX * (installment - 1);
        }
    }

    return (
        <Col sm={4} className="text-center">
            <Heading content={`${plan} months`} design="h6" />
            <Table striped bordered hover size="sm" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Amount (MYR)</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: plan - 1 }).map((_, idx) => (
                        <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{newX !== undefined && newX}</td>
                        </tr>
                    ))}
                    <tr>
                        <td>{plan}</td>
                        <td>{newY !== undefined && newY}</td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th>Total</th>
                        <th> {submitValue.amount}</th>
                    </tr>
                </thead>
            </Table>
        </Col>
    );
}

export default Calculate;