import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import Read from "../Components/AttendanceSearch/Read";

import { Container, Row, Col } from "react-bootstrap";

function AttendanceTeacher() {
  const { authState, setAuthState } = useContext(AuthContext);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
    <div>
      <Container className="my-3 p-3">
        <Row className="my-2">
          <Col sm={10}>
            <Heading content="Manage Attendance Based on Teachers With Search Function " design="h4" />
          </Col>
        </Row>
        <Row className="my-5">
          <Read />
        </Row>
      </Container>
    </div>
    </AuthContext.Provider>
  );
}

export default AttendanceTeacher;