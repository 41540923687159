import Index from "../Pages/Index";

import Account from "../Pages/Account";
import CreateAccount from "../Components/Account/Create";
import UpdateAccount from "../Components/Account/Update";

import Salesperson from "../Pages/Salesperson";

import Student from "../Pages/Student";
import CreateStudent from "../Components/Student/Create";
import UpdateStudent from "../Components/Student/Update";

import Guardian from "../Pages/Guardian";

import Trainer from "../Pages/Trainer";

import Teacher from "../Pages/Teacher";

import Class from "../Pages/Class";
import CreateClass from "../Components/Class/Create";
import UpdateClass from "../Components/Class/Update";

import Attendance from "../Pages/Attendance";
import AttendanceTeacher from "../Pages/AttendanceTeacher";
import AttendanceSearch from "../Pages/AttendanceSearch";
import CreateAttendance from "../Components/Attendance/Create";
import UpdateAttendance from "../Components/Attendance/Update";

import Center from "../Pages/Center";
import CreateCenter from "../Components/Center/Create";

import School from "../Pages/School";
import CreateSchool from "../Components/School/Create";

import Settings from "../Pages/Settings";

import Installment from "../Pages/Installment";
import CreateInstallment from "../Components/Installment/Create";

import Payment from "../Pages/Payment";
import AddPayment from "../Components/Payment/Teacher/AddPayment";
import ViewPayment from "../Components/Payment/Teacher/ViewPayment";
import UpdatePayment from "../Components/Payment/Teacher/UpdatePayment";
import VerifyPayment from "../Components/Payment/Teacher/VerifyPayment";
import ViewInstallmentPlan from "../Components/Payment/Teacher/ViewInstallmentPlan";
import AddInstallmentPlan from "../Components/Payment/Teacher/AddInstallmentPlan";

import AddPaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/AddPayment";
import ViewPaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/ViewPayment";
import UpdatePaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/UpdatePayment";
import VerifyPaymentRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/VerifyPayment";
import ViewInstallmentPlanRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/ViewInstallmentPlan";
import AddInstallmentPlanRecruitmentTeacher from "../Components/Payment/RecruitmentTeacher/AddInstallmentPlan";

import SupplyChain from "../Pages/SupplyChain";
import FMTStudent from "../Pages/FMTStudent";
import Report from "../Pages/Report";

import PaymentCollection from "../Pages/PaymentCollection";
import RequestReceipt from "../Pages/RequestReceipt";


const Routes = [
  { path: "/", name: "home", element: <Index /> },
  { path: "/account", name: "account", element: <Account /> },
  { path: "/createAccount", name: "createAccount", element: <CreateAccount /> },
  {
    path: "/updateAccount/:accID",
    name: "updateAccount",
    element: <UpdateAccount />,
  },
  

  {
    path: "/enrollmentTeacher",
    name: "enrollmentTeacher",
    element: <Salesperson />,
  },

  { path: "/student", name: "student", element: <Student /> },
  { path: "/createStudent", name: "createStudent", element: <CreateStudent /> },
  {
    path: "/updateStudent/:studentID",
    name: "updateStudent",
    element: <UpdateStudent />,
  },

  { path: "/guardian", name: "guardian", element: <Guardian /> },

  { path: "/trainer", name: "trainer", element: <Trainer /> },

  { path: "/teacher", name: "teacher", element: <Teacher /> },

  { path: "/class", name: "class", element: <Class /> },
  { path: "/createClass", name: "createClass", element: <CreateClass /> },
  {
    path: "/updateClass/:classID",
    name: "updateClass",
    element: <UpdateClass />,
  },

  { path: "/attendance", name: "attendance", element: <Attendance /> },
  {
    path: "/attendanceTeacher",
    name: "attendanceTeacher",
    element: <AttendanceTeacher />,
  },
  {
    path: "/attendanceSearch",
    name: "attendanceSearch",
    element: <AttendanceSearch />,
  },
  {
    path: "/createAttendance/:classID",
    name: "createAttendance",
    element: <CreateAttendance />,
  },
  {
    path: "/updateAttendance/:classID/:classDate",
    name: "updateAttendance",
    element: <UpdateAttendance />,
  },

  { path: "/center", name: "center", element: <Center /> },
  { path: "/createCenter", name: "createCenter", element: <CreateCenter /> },

  { path: "/school", name: "school", element: <School /> },
  { path: "/createSchool", name: "createSchool", element: <CreateSchool /> },

  { path: "/settings", name: "settings", element: <Settings /> },

  { path: "/installment", name: "installment", element: <Installment /> },
  {
    path: "/createInstallment",
    name: "createInstallment",
    element: <CreateInstallment />,
  },

  { path: "/payment", name: "payment", element: <Payment /> },
  {
    path: "/addPayment/:studentID",
    name: "addPayment",
    element: <AddPayment />,
  },
  {
    path: "/viewPayment/:studentID",
    name: "viewPayment",
    element: <ViewPayment />,
  },
  {
    path: "/updatePayment/:paymentID/:studentID",
    name: "updatePayment",
    element: <UpdatePayment />,
  },
  {
    path: "/verifyPayment/:paymentID/:studentID",
    name: "verifyPayment",
    element: <VerifyPayment />,
  },
  {
    path: "/viewInstallmentPlan/:studentID",
    name: "viewInstallmentPlan",
    element: <ViewInstallmentPlan />,
  },
  {
    path: "/addInstallmentPlan/:studentID",
    name: "addInstallmentPlan",
    element: <AddInstallmentPlan />,
  },

  {
    path: "/addPaymentRecruitmentTeacher/:studentID",
    name: "addPayment",
    element: <AddPaymentRecruitmentTeacher />,
  },
  {
    path: "/viewPaymentRecruitmentTeacher/:studentID",
    name: "viewPayment",
    element: <ViewPaymentRecruitmentTeacher />,
  },
  {
    path: "/updatePaymentRecruitmentTeacher/:paymentID/:studentID",
    name: "updatePayment",
    element: <UpdatePaymentRecruitmentTeacher />,
  },
  {
    path: "/verifyPaymentRecruitmentTeacher/:paymentID/:studentID",
    name: "verifyPayment",
    element: <VerifyPaymentRecruitmentTeacher />,
  },
  {
    path: "/viewInstallmentPlanRecruitmentTeacher/:studentID",
    name: "viewInstallmentPlan",
    element: <ViewInstallmentPlanRecruitmentTeacher />,
  },
  {
    path: "/addInstallmentPlanRecruitmentTeacher/:studentID",
    name: "addInstallmentPlan",
    element: <AddInstallmentPlanRecruitmentTeacher />,
  },
  { path: "/supplychain", name: "supplychain", element: <SupplyChain />},
  { path: "/fmtstudent", name: "fmtstudent", element: <FMTStudent />},
    
  { path: "/report", name: "report", element: <Report />},

  {path: "/paymentCollection", name: "paymentCollection", element: <PaymentCollection />},
  { path: "/requestReceipt", name: "requestReceipt", element: <RequestReceipt />},

];

export default Routes;