import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import { getData } from "country-list";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import BusinessTypeOption from "../../../Data/BusinessType";
import State from "../../../Data/State";

import { BiChevronRight } from "react-icons/bi";

const countryOption = getData().sort();

function Guardian({
  submitValue,
  setSubmitValue,
  setKey,
  handleStudentList,
  studentList,
  authState,
}) {
  const optionInput = "form-select";
  const textInput = "form-control";
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [centerStateOptions, setCenterStateOptions] = useState([]);
  const [classLocationOptions, setClassLocationOptions] = useState([]);

  let businessTypeOptions = [];
  BusinessTypeOption.map((item) => {
    businessTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let countryOptions = [];
  countryOption.sort().map(({ code, name }) => {
    countryOptions.push(
      <option value={code} key={code}>
        {name}
      </option>
    );
  });

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}SalesPerson/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Center/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/classLocation.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/centerState.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setSalesPersonOptions(
          result[0].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
        setCenterOptions(
          result[1].data
            .filter(({ state }) => {
              if (submitValue.centerState === state) {
                return centerOptions;
              } else if (submitValue.centerState === "") {
                return centerOptions;
              }
              return false;
            })
            .map(({ id, name, state }) => ({
              label: name,
              value: id,
              state: state,
            }))
        );

        setCenterStateOptions(
          result[3].data.map(({ state }) => ({
            label: state,
            value: state,
          }))
        );

        setClassLocationOptions(
          result[2].data.map(({ name }) => ({
            label: name,
            value: name,
          }))
        );

        // setClassLocationOptions([...new Set(classLocationOptions)]);
      })
    );
  });
  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Business Type</label>
            <select
              className={optionInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  businessType: e.target.value,
                }))
              }
              defaultValue="B2C"
            >
              {businessTypeOptions}
            </select>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Enrollment Teacher</label>

            <Select
              options={salesPersonOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  salespersonID: e.value,
                }))
              }
              value={salesPersonOptions.find(
                (obj) => obj.value === submitValue.salespersonID
              )}
            />
          </Col>
        </Row>
        {submitValue.businessType === "B2B" && (
          <>
            <Row>
              <Col sm={12} md={6} className="mb-3">
                <label
                  className={
                    submitValue.businessType === "B2B"
                      ? "form-label required"
                      : "form-label"
                  }
                >
                  Center State
                </label>
                <Multiselect
                  singleSelect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onSearch={function noRefCheck() {}}
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      centerState: e[0].value,
                    }))
                  }
                  options={centerStateOptions}
                  placeholder="Select One Center"
                />
              </Col>

              <Col sm={12} md={6} className="mb-3">
                <label
                  className={
                    submitValue.businessType === "B2B"
                      ? "form-label required"
                      : "form-label"
                  }
                >
                  Center Name
                </label>
                <Multiselect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      centerID: "",
                    }))
                  }
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      centerID: e[0].value,
                      classLocation: e[0].label,
                    }))
                  }
                  options={centerOptions}
                  selectionLimit={1}
                  placeholder="Select Center Name"
                />
              </Col>
            </Row>
          </>
        )}
        <hr className="my-2 py-2" />
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Class Location</label>
            <Multiselect
              displayValue="label"
              avoidHighlightFirstOption
              onKeyPressFn={function noRefCheck() {}}
              onSearch={function noRefCheck() {}}
              onRemove={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  classLocation: "",
                }))
              }
              onSelect={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  classLocation: e[0].value,
                }))
              }
              selectedValues={
                submitValue.classLocation !== "" && [
                  {
                    label: submitValue.classLocation,
                    value: submitValue.classLocation,
                  },
                ]
              }
              options={classLocationOptions}
              selectionLimit={1}
              placeholder="Select Class Location"
            />
          </Col>
        </Row>

        {submitValue.businessType === "B2C" && (
          <>
            <hr className="my-2 py-2" />

            <Row>
              <Col sm={12} md={6} className="mb-3">
                <label className="form-label required">
                  Guardian Phone Number
                </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="MY"
                  onChange={(event) =>
                    handleStudentList(0, "guardianPhoneNumber", event)
                  }
                  value={studentList[0].guardianPhoneNumber}
                />
              </Col>

              <Col sm={12} md={6} className="mb-3">
                <label className="form-label">Guardian Name</label>
                <input
                  type="text"
                  className={textInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      guardianName: e.target.value.toUpperCase(),
                    }))
                  }
                  value={submitValue.guardianName}
                />
              </Col>
              <Col sm={12} className="mb-3">
                <label className="form-label">Address Line 1</label>
                <input
                  type="text"
                  className={textInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      addressLine1: e.target.value,
                    }))
                  }
                  value={submitValue.addressLine1}
                />
              </Col>
              <Col sm={12} className="mb-3">
                <label className="form-label">Address Line 2</label>
                <input
                  type="text"
                  className={textInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      addressLine2: e.target.value,
                    }))
                  }
                  value={submitValue.addressLine2}
                />
              </Col>
              <Col sm={12} md={6} className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className={textInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      city: e.target.value,
                    }))
                  }
                  value={submitValue.city}
                />
              </Col>

              <Col sm={12} md={6} className="mb-3">
                <label className="form-label">State / Province / Region</label>
                <Multiselect
                  singleSelect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      stateRegion: e[0].value,
                    }))
                  }
                  onSearch={function noRefCheck() {}}
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      stateRegion: e[0].value,
                    }))
                  }
                  options={State}
                />
              </Col>

              <Col sm={12} md={6} className="mb-3">
                <label className="form-label">ZIP / Postal Code</label>
                <input
                  type="number"
                  min="0"
                  className={textInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      zip: e.target.value,
                    }))
                  }
                  value={submitValue.zip}
                />
              </Col>

              <Col sm={12} md={6} className="mb-3">
                <label className="form-label required">Country</label>

                <select
                  className={optionInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      country: e.target.value,
                    }))
                  }
                  defaultValue="MY"
                >
                  {countryOptions}
                </select>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("student")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Guardian;
