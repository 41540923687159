import React from 'react';
import { Navbar } from "react-bootstrap";
import { BiCopyright } from "react-icons/bi";

function Footer() {

  const getCurrentYear = new Date().getFullYear();

  return (
    <Navbar fixed="bottom" bg="dark">
        <p className="text-light mx-2 my-1">
            <BiCopyright className="icon"/> {getCurrentYear} ukubear.com All Rights Reserved
        </p>
    </Navbar>
  )
}

export default Footer