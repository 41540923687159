import React, { useState, useEffect } from "react";
import Axios from "axios";
import LetteredAvatar from "react-lettered-avatar";
import randomColor from "randomcolor";

var color = randomColor();

function Profile(props) {
  const userID = props.id;
  const [viewStudentName, setViewStudentName] = useState("Ukubear user");

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentName.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {
      let name = result.data.studentName;
      let initials = name
        .split(" ")
        .map((word) => word[0])
        .join(" ")
        .slice(0, 6);

      setViewStudentName(initials);
    });
  }, [userID]);

  return (
    <LetteredAvatar
      twoLetter={false}
      name={viewStudentName}
      backgroundColor={color}
    />
  );
}

export default Profile;
