import React, { useState, useEffect } from "react";
import Axios from "axios";

function StudentCode(props) {
  const userID = props.id;
  const [viewStudentCode, setViewStudentCode] = useState("");

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);
    
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentCode.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then ((result) => {
      setViewStudentCode(result.data.studentCode);
    })
  }, [userID]);


  return <>{viewStudentCode}</>;
}

export default StudentCode