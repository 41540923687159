const Status = [
  {
    value: 0,
    label: "Normal",
  },
  {
    value: 1,
    label: "FMT",
  },
  {
    value: 2,
    label: "Holiday Programme",
  },
  {
    value: 3,
    label: "T1C",
  },
];

export default Status;
