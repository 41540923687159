import React from "react";
import Read from "./Teacher/Read"


function Teacher() {
    return (
        <>
        <Read />
        </>
    )
}

export default Teacher