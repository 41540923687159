import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import FMTStudent from "../Components/FMTStudent/Read";

import { Container, Row, Col } from "react-bootstrap";

function ReadSupplyChain() {
  const { authState, setAuthState } = useContext(AuthContext);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
    <div>
    <Container className="my-3">
          <Row className="">
            <Col>
            {authState.role === "1" && <Heading content="FMT Student List" design="h4" />}
            {authState.role === "2" && <Heading content="FMT Student List" design="h4" />}
            {authState.role === "6" && <Heading content="FMT Student List" design="h4" />}
            </Col>
          </Row>
          <Row className="my-5">
            {authState.role === "1" && <FMTStudent />}
            {authState.role === "2" && <FMTStudent />}
            {authState.role === "6" && <FMTStudent />}
          </Row>
          
        </Container>
    </div>
    </AuthContext.Provider>

  )
}

export default ReadSupplyChain