import React, { useState, useEffect } from "react";
import Axios from "axios";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { Container, Row, Col, Button } from "react-bootstrap";

import BusinessTypeOption from "../../../Data/BusinessType";

import { BiChevronRight } from "react-icons/bi";

function Center({ submitValue, setSubmitValue, setKey }) {
  const optionInput = "form-select";
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [centerStateOptions, setCenterStateOptions] = useState([]);
  const [classLocationOptions, setClassLocationOptions] = useState([]);

  let businessTypeOptions = [];
  BusinessTypeOption.map((item) => {
    businessTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}SalesPerson/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Center/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/classLocation.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/centerState.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setSalesPersonOptions(
          result[0].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
        setCenterOptions(
          result[1].data
            .filter(({ state }) => {
              if (submitValue.centerState === state) {
                return centerOptions;
              } else if (submitValue.centerState === "") {
                return centerOptions;
              }
              return false;
            })
            .map(({ id, name, state }) => ({
              label: name,
              value: id,
              state: state,
            }))
        );

        setCenterStateOptions(
          result[3].data.map(({ state }) => ({
            label: state,
            value: state,
          }))
        );

        setClassLocationOptions(
          result[2].data.map(({ name }) => ({
            label: name,
            value: name,
          }))
        );
      })
    );
  });

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Business Type</label>
            <select
              className={optionInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  businessType: e.target.value,
                }))
              }
              value={submitValue.businessType}
            >
              {businessTypeOptions}
            </select>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Enrollment Teacher</label>

            <Select
              options={salesPersonOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  salespersonID: e.value,
                }))
              }
              value={salesPersonOptions.find(
                (obj) => obj.value === submitValue.salespersonID
              )}
            />
          </Col>
        </Row>
        <Row>
        <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Class Location</label>
            <Multiselect
              displayValue="label"
              avoidHighlightFirstOption
              onKeyPressFn={function noRefCheck() {}}
              onSearch={function noRefCheck() {}}
              onRemove={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  classLocation: "",
                }))
              }
              onSelect={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  classLocation: e[0].value,
                }))
              }
              selectedValues={
                submitValue.classLocation !== "" && [
                  {
                    label: submitValue.classLocation,
                    value: submitValue.classLocation,
                  },
                ]
              }
              options={classLocationOptions}
              selectionLimit={1}
              placeholder="Select Class Location"
            />
          </Col>
        </Row>
        {submitValue.businessType === "B2B" && (
          <Row>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Center State</label>
              <Select
                options={centerStateOptions}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    centerState: e.value,
                  }))
                }
                value={centerStateOptions.find(
                  (obj) => obj.value === submitValue.centerState
                )}
              />
            </Col>

            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Center Name</label>
              <Select
                options={centerOptions}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    centerID: e.value,
                  }))
                }
                value={centerOptions.find(
                  (obj) => obj.value === submitValue.centerID
                )}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Center;
