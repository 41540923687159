import Index from "../Pages/Index";

import Student from "../Pages/Student";
import UpdateStudent from "../Components/Student/Update";

import Class from "../Pages/Class";

import Attendance from "../Pages/Attendance";
import CreateAttendance from "../Components/Attendance/Create";
import UpdateAttendance from "../Components/Attendance/Update";

import Settings from "../Pages/Settings";

import Payment from "../Pages/Payment";
import AddPayment from "../Components/Payment/Teacher/AddPayment";
import ViewPayment from "../Components/Payment/Teacher/ViewPayment";
import UpdatePayment from "../Components/Payment/Teacher/UpdatePayment";
import VerifyPayment from "../Components/Payment/Teacher/VerifyPayment";
import ViewInstallmentPlan from "../Components/Payment/Teacher/ViewInstallmentPlan";
import AddInstallmentPlan from "../Components/Payment/Teacher/AddInstallmentPlan";

import FMTStudent from "../Pages/FMTStudent";

const Routes = [
  { path: "/", name: "home", element: <Index /> },

  { path: "/student", name: "student", element: <Student /> },
  {
    path: "/updateStudent/:studentID",
    name: "updateStudent",
    element: <UpdateStudent />,
  },

  { path: "/class", name: "class", element: <Class /> },

  { path: "/attendance", name: "attendance", element: <Attendance /> },
  {
    path: "/createAttendance/:classID",
    name: "createAttendance",
    element: <CreateAttendance />,
  },
  {
    path: "/updateAttendance/:classID/:classDate",
    name: "updateAttendance",
    element: <UpdateAttendance />,
  },

  { path: "/settings", name: "settings", element: <Settings /> },

  { path: "/payment", name: "payment", element: <Payment /> },
  {
    path: "/addPayment/:studentID",
    name: "addPayment",
    element: <AddPayment />,
  },
  {
    path: "/viewPayment/:studentID",
    name: "viewPayment",
    element: <ViewPayment />,
  },
  {
    path: "/updatePayment/:paymentID/:studentID",
    name: "updatePayment",
    element: <UpdatePayment />,
  },
  {
    path: "/verifyPayment/:paymentID/:studentID",
    name: "verifyPayment",
    element: <VerifyPayment />,
  },
  {
    path: "/viewInstallmentPlan/:studentID",
    name: "viewInstallmentPlan",
    element: <ViewInstallmentPlan />,
  },
  {
    path: "/addInstallmentPlan/:studentID",
    name: "addInstallmentPlan",
    element: <AddInstallmentPlan />,
  },

  { path: "/fmtstudent", name: "fmtstudent", element: <FMTStudent /> },
];

export default Routes;
