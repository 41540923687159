var arr = [];
for (var i = (new Date().getFullYear())-1; i < (new Date().getFullYear())+2; i++) {
    arr.push({
        value: i,
        label: i
    });
}
const RequestReceiptOptions= arr;

export default RequestReceiptOptions;
