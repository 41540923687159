import React, { useContext,useState, useEffect } from "react";
import Axios from "axios";
import Select from "react-select";
import moment from "moment";
import { AuthContext } from "../../../Helpers/AuthContext";

import { Container, Row, Col, Button } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";

function Class({ submitValue, setSubmitValue, setKey }) {
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [trainerOptions, setTrainerOptions] = useState([]);
  const [maxDate] = useState(moment().format("YYYY-MM-DD"));
  const oneWeekFromNow = moment().subtract(5, 'days').format("YYYY-MM-DD");
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Teacher/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Trainer/read.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setTeacherOptions(
          result[0].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
        setTrainerOptions(
          result[1].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      })
    );
  }, [submitValue.classID]);

  const textInput = "form-control";

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Teacher</label>
            <Select
              options={teacherOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  teacherID: e.value,
                }))
              }
              value={teacherOptions.find(
                (obj) => obj.value === submitValue.teacherID
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Trainer</label>
            <Select
              options={trainerOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  trainerID: e.value,
                }))
              }
              value={trainerOptions.find(
                (obj) => obj.value === submitValue.trainerID
              )}
            />
          </Col>
          {/* <hr className="dropdown-divider" /> */}
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Start Time</label>
            <input
              type="time"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  startTime: e.target.value,
                }))
              }
              value={submitValue.startTime}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">End Time</label>
            <input
              type="time"
              className={textInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  endTime: e.target.value,
                }))
              }
              value={submitValue.endTime}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Date</label>
            <input
              type="date"
              className={textInput}
              min={authState.id == "6577"? null: oneWeekFromNow}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  classDate: e.target.value,
                }))
              }
              value={
                submitValue.classDate === ""
                  ? moment().format("YYYY-MM-DD")
                  : moment(submitValue.classDate).format("YYYY-MM-DD")
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("student")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Class;
