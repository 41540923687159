import React, { useState, useEffect } from "react";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import Axios from "axios";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

import categoryOptions from "../../../Data/Category";
import learningTypeOptions from "../../../Data/ClassType";
import VenueOptions from "../../../Data/Venue";

import { BiChevronRight } from "react-icons/bi";

function Info({ setSubmitValue, submitValue, setKey }) {
  const optionInput = "form-select";

  const [teacherOptions, setTeacherOptions] = useState([]);
  const [trainerOptions, setTrainerOptions] = useState([]);
  const [lessonPlanOptions, setLessonPlanOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);

  let venueOptions = [];
  VenueOptions.map((item) => {
    venueOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });
  
  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Teacher/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Trainer/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Student/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/lessonPlan.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/language.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/school.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/center.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setTeacherOptions(
          result[0].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
        setTrainerOptions(
          result[1].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );

        setLessonPlanOptions(
          result[3].data.map(({ value, label }) => ({
            label: label,
            value: value,
          }))
        );

        setLanguageOptions(
          result[4].data.map(({ value, label }) => ({
            label: label,
            value: value,
          }))
        );

        setSchoolOptions(
          result[5].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );

        setCenterOptions(
          result[6].data.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      })
    );
  }, [submitValue.classID]);

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Teacher</label>
            <Select
              options={teacherOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  teacherID: e.value,
                }))
              }
              value={teacherOptions.find(
                (obj) => obj.value === submitValue.teacherID
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Trainer</label>
            <Select
              options={trainerOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  trainerID: e.value,
                }))
              }
              value={trainerOptions.find(
                (obj) => obj.value === submitValue.trainerID
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Student List</label>
            <Multiselect
              displayValue="label"
              showCheckbox
              avoidHighlightFirstOption
              onRemove={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  studentList: e,
                }))
              }
              onSearch={function noRefCheck() {}}
              onSelect={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  studentList: e,
                }))
              }
              options={submitValue.studentOption}
              selectedValues={submitValue.studentList}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Type</label>
            <Select
              options={learningTypeOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  learningType: e.value,
                }))
              }
              value={learningTypeOptions.find(
                (obj) => obj.value === submitValue.learningType
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Category</label>
            <Select
              options={categoryOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  category: e.value,
                }))
              }
              value={categoryOptions.find(
                (obj) => obj.value === submitValue.category
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Lesson Plan</label>
            <Select
              options={lessonPlanOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  lessonPlan: e.value,
                }))
              }
              value={lessonPlanOptions.find(
                (obj) => obj.value === submitValue.lessonPlan
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Language</label>
            <Select
              options={languageOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  language: e.value,
                }))
              }
              value={languageOptions.find(
                (obj) => obj.value === submitValue.language
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Remarks</label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  remark: e.target.value,
                }))
              }
              value={submitValue.remark}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Venue</label>
            <select
              className={optionInput}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  venue: e.target.value,
                }))
              }
              value={submitValue.venue}
            >
              {venueOptions}
            </select>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">{submitValue.venue} Name</label>
            {submitValue.venue === "Center" ? (
              <Select
                options={centerOptions}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    venueID: e.value,
                  }))
                }
                value={centerOptions.find(
                  (obj) => obj.value === submitValue.venueID
                )}
              />
            ) : (
              <Select
                options={schoolOptions}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    venueID: e.value,
                  }))
                }
                value={schoolOptions.find(
                  (obj) => obj.value === submitValue.venueID
                )}
              />
            )}
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Class Status</label>
            <select
              className={optionInput}
              value={submitValue.status}
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  status: e.target.value,
                }))
              }
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("schedule")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Info;
