import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import SupplyChain from "../Components/SupplyChain/Read";

import { Container, Row, Col } from "react-bootstrap";

function ReadSupplyChain() {
  const { authState, setAuthState } = useContext(AuthContext);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
    <div>
    <Container className="my-3">
          <Row className="">
            <Col>
            {authState.role === "1" && <Heading content="Supply chain" design="h4" />}
            </Col>
          </Row>
          <Row className="my-5">
            {authState.role === "1" && <SupplyChain />}
          </Row>
          
        </Container>
    </div>
    </AuthContext.Provider>

  )
}

export default ReadSupplyChain