const Gender= [

    {
      value: "Y",
      label: "Yes",
    },
    {
      value: "N",
      label: "No",
    },
  ];
  
  export default Gender;
  