const ClassType= [
  {
    value: "O",
    label: "Online Class",
  },
  {
    value: "P",
    label: "Physical Class",
  },
];

export default ClassType;
