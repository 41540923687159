import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button } from "react-bootstrap";
import Axios from "axios";
import Select from "react-select";
import Calculate from "./Calculate";

function MaterialInstallmentDetails({
  setKey,
  submitValue,
  setSubmitValue,

}) {
  const textInput = "form-control";
  const optionInput = "form-select";
  const { studentID } = useParams();
  const [materialOptions, setMaterialOptions] = useState([]);


  useEffect(() => {
    let materialInformation = new FormData();
    materialInformation.append("studentID", studentID);
    materialInformation.append("requestOrigin", "addInstallmentPlan");
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/getMaterialList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: materialInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setMaterialOptions(
          result.data.map(({ id, displayText, amount }) => ({
            value: id,
            label: displayText,
            amount: amount
          }))
        );
      }
    });
  }, [studentID]);

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Material Fee</label>
            <Select
              className="basic-single form-control"
              classNamePrefix="select"
              showCheckbox
              options={materialOptions}
              onChange={(e) => {
                let newY;
                let newX;
                let price = 0;
                let installment = 0;

                materialOptions.find(
                  (obj) => {
                    if (obj.value === e.value) {
                      price = obj.amount;
                      installment = submitValue.installmentPlan;

                      if (price !== 0 && installment !== 0) {
                        newY = Math.trunc(price / installment);
                        newX = (price - newY) / (installment - 1);
                        if (newX % 1 !== 0) {
                          newX = Math.trunc(newX);
                          newY = price - newX * (installment - 1);
                        }
                      }
                    }
                  })


                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  materialID: e.value,
                  amount: price,
                  newX: newX,
                  newY: newY
                }))
              }}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Installment Plan</label>
            <select
              className={optionInput}
              onChange={(e) => {
                let newY;
                let newX;
                let price = 0;
                let installment = 0;

                materialOptions.find(
                  (obj) => {
                    if (obj.value === submitValue.materialID) {
                      price = obj.amount;
                      installment = e.target.value;


                      if (price !== 0 && installment !== 0) {
                        newY = Math.trunc(price / installment);
                        newX = (price - newY) / (installment - 1);
                        if (newX % 1 !== 0) {
                          newX = Math.trunc(newX);
                          newY = price - newX * (installment - 1);
                        }
                      }

                    }
                  })

                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  installmentPlan: e.target.value,
                  amount: price,
                  newX: newX,
                  newY: newY
                }))

              }}
            >
              <option value="">Please select</option>
              <option value="3">3</option>
              <option value="6">6</option>
            </select>
          </Col>
          {submitValue.materialID > 0 && (
            <Row>
              <Calculate plan="3" submitValue={submitValue} />
              <Calculate plan="6" submitValue={submitValue} />
            </Row>
          )}
        </Row>
        {/* <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row> */}
      </Container>
    </div >
  );

}

export default MaterialInstallmentDetails;
