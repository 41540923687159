import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import { Navbar } from "react-bootstrap";

import Logo from "../Logo";
import Name from "../Name";

import {
  BsPieChart,
  BsPeople,
  BsPerson,
  BsGrid3X3Gap,
  BsBook,
  BsBuilding,
  BsCheckSquare,
  BsShieldLock,
  BsBoxArrowRight,
  BsShield,
  BsBriefcase,
  BsWrench,
  BsWallet2,
  BsGift,
  BsLightbulb,
  BsPlusLg,
  BsBox,
  BsFileBarGraph,
  BsPatchCheck,
  BsCurrencyDollar,
  BsFillFileExcelFill,
} from "react-icons/bs";
import { FaExchangeAlt} from "react-icons/fa";

function Admin() {
  const { authState, setAuthState } = useContext(AuthContext);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({
      id: "",
      role: "",
      phoneNumber: "",
      status: false,
    });
    window.location = `${process.env.REACT_APP_CLIENT_DOMAIN}`;
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div className="sidebarfixed overflow-auto d-flex flex-column flex-shrink-0 pt-3 bg-theme text-white min-vh-100 h-100">
          <Navbar.Brand href="/" className="mx-auto">
            <Logo design="rounded-circle" width="40" height="40" />
            <span className="d-none d-sm-inline text-white">
              UkuBear Management
            </span>
          </Navbar.Brand>
          <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start mx-auto p-2">
            <li className="nav-item">
              <a
                href="/"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsPieChart className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline fs-6">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/guardian"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsPeople className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline fs-6">Guardian</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/student"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsBook className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Student</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/fmtstudent"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsPatchCheck className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">FMT/HP</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                href="/trainer"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsShield className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Trainer</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/teacher"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsLightbulb className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Teacher</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/class"
                className="nav-link link-light text-decoration-non align-middle px-0"
              >
                <BsPerson className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Class</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/enrollmentTeacher"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsBriefcase className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">
                  Enrollment Teacher
                </span>
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                href="/attendance"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsCheckSquare className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Attendance</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/attendanceTeacher"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsCheckSquare className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">
                  Attendance By Teacher
                </span>
              </a>
            </li> */}
            <li className="nav-item">
              <a
                href="/attendanceSearch"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsCheckSquare className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">
                  Attendance By Search
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mp.ukubear.com/mp/Temp/attendanceRecordbyAcc.php"
                className="nav-link link-light text-decoration-none align-middle px-0"
                target="_blank"
              >
                <BsCheckSquare className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">
                  Attendance By Account
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/payment"
                className="nav-link link-light text-decoration-none align-middle px-0"
              >
                <BsWallet2 className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Payment</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mp.ukubear.com/mp/Invoice/exportInvoiceReport.php"
                className="nav-link link-light text-decoration-none align-middle px-0"
                target="_blank"
              >
                <BsWallet2 className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Export Invoice Report</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mp.ukubear.com/mp/PaymentCollection/exportPaymentSlip.php"
                className="nav-link link-light text-decoration-none align-middle px-0"
                target="_blank"
              >
                <BsWallet2 className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Payment Record</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mp.ukubear.com/mp/Temp/exportStudentData.php"
                className="nav-link link-light text-decoration-none align-middle px-0"
                target="_blank"
              >
                <BsFillFileExcelFill className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Export Student Data</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mp.ukubear.com/mp/ServiceFee/serviceFee.php"
                className="nav-link link-light text-decoration-none align-middle px-0"
                target="_blank"
              >
                <BsCurrencyDollar className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Service Fee</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://mp.ukubear.com/mp/UpdateEnrolmentTeacher/updateEnrolmentTeacher.php"
                className="nav-link link-light text-decoration-none align-middle px-0"
                target="_blank"
              >
                <FaExchangeAlt className="icon" />{" "}
                <span className="ms-1 d-none d-sm-inline">Change Take Care ET </span>
              </a>
            </li>
            
            <li className="nav-item">
              <div className="dropend">
                <a
                  href="#"
                  className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsGrid3X3Gap className="icon" />
                  <span className="ms-1 d-none d-sm-inline">Class Report</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                  <li>
                    <a className="dropdown-item" target="_blank" href="https://mp.ukubear.com/mp/ClassReport/classReport.php">
                      <BsFileBarGraph className="icon" /> SC14: Scheduling Class 14 days
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" target="_blank" href="https://mp.ukubear.com/mp/ClassReport/classStudentReport.php">
                      <BsFileBarGraph className="icon" /> OCOT: Optimum Class Optimum Teacher
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <div className="dropend">
                <a
                  href="#"
                  className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsGrid3X3Gap className="icon" />
                  <span className="ms-1 d-none d-sm-inline">More</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                  {(authState.id == 17 || authState.id == 2) && (
                    <li>
                      <a className="dropdown-item" href="/account">
                        <BsPlusLg className="icon" /> Account
                      </a>
                    </li>
                  )}
                  <li>
                    <a className="dropdown-item" href="/installment">
                      <BsPerson className="icon" /> Installment
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/center">
                      <BsBuilding className="icon" /> Center
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/school">
                      <BsBuilding className="icon" /> School
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/paymentCollection">
                      <BsCurrencyDollar className="icon" /> Payment Collection
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/supplychain">
                      <BsBox className="icon" /> Supply Chain
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="/requestReceipt">
                      <BsBox className="icon" />
                      Request Receipt
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/report">
                      <BsFileBarGraph className="icon" /> Report
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <hr />
          <div className="dropdown mx-auto pb-0 pb-sm-2">
            <a
              href="#"
              className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p className="ms-1 fw-bold d-none d-sm-inline">
                <Name id={authState.id} />{" "}
              </p>
            </a>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              <li>
                <a className="dropdown-item" href="/settings">
                  <BsWrench className="icon" /> Settings
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" onClick={logout}>
                  <BsBoxArrowRight className="icon" />
                  Sign Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Admin;
