import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Row, Col, Button } from "react-bootstrap";
import { BiGridHorizontal, BiGridVertical } from "react-icons/bi";
import LongCard from "./Teacher/LongCard";
import ShortCard from "./Teacher/ShortCard";
import Heading from "../Heading";

function ClassList() {
  const { authState, setAuthState } = useContext(AuthContext);

  const [isCard, setCard] = useState(true);
  const [classList, setClassList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const cardSelection = (status) => {
    setCard(status);
  };

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Index/Teacher/classList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setClassList(
          result.data
            .filter(({ teacherID }) => {
              if (teacherID === authState.id) {
                return classList;
              }
              return false;
            })
        );
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div>
          <Row>
            <Col sm={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Heading content="Class List" design="h3 p-0 m-0" />
                </div>

                <div className="text-end align-items-center">
                  <p className="d-inline">View By: </p>
                  <Button
                    onClick={() => cardSelection(true)}
                    variant={!isCard ? "outline-secondary" : "warning"}
                    size="sm"
                    className="border me-1"
                  >
                    <BiGridHorizontal size={21} />
                  </Button>
                  <Button
                    onClick={() => cardSelection(false)}
                    variant={isCard ? "outline-secondary" : "warning"}
                    size="sm"
                    className="border"
                  >
                    <BiGridVertical size={21} />
                  </Button>
                </div>
              </div>
            </Col>
            <Col className="col-md-4 mt-2 ms-auto">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Class Code"
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                />
              </Col>
            <Col sm={12}>
              {isCard ? (
                <ShortCard classList={classList} searchTerm={searchTerm} />
              ) : (
                <LongCard classList={classList} searchTerm={searchTerm} />
              )}
            </Col>
          </Row>
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default ClassList;
