import React, { useState, useEffect } from "react";
import Axios from "axios";
import Category from "../Data/StudentCategory";

function StudentCourseFee(props) {
  const userID = props.id;
  const [viewCourseFee, setViewCourseFee] = useState([]);

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentCourseFee.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {
      if (result.data === undefined || result.data.length == 0) {
        setViewCourseFee("-");
      } else {

        let patchResult = result.data;
        for (let i = 0; i < Category.length; i++) {
          if (Category[i].value === patchResult.category) {
            patchResult.category = Category[i].label;
            break;
          }
        }
       
        setViewCourseFee(patchResult);
      }
    });
  }, [userID]);

  return (
      <a className="fs-7 text-decoration-none text-dark"
      >
        {viewCourseFee.currency} {viewCourseFee.courseFee} ({viewCourseFee.category})
      </a>
    
  );
}

export default StudentCourseFee;