import React, { useContext, Fragment, useState, useEffect } from "react";
import Axios from "axios";
import { AuthContext } from "../../Helpers/AuthContext";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import { FcAlarmClock, FcCalendar, FcPlus, FcInfo } from "react-icons/fc";
import {
  FaLanguage,
  FaSchool,
  FaChevronLeft,
  FaChevronRight,
  FaEye
} from "react-icons/fa";
import { GiTeacher, GiWhistle } from "react-icons/gi";
import { BiXCircle, BiCheckCircle } from "react-icons/bi";
import moment from "moment";
import ReactPaginate from "react-paginate";

import ReactTooltip from "react-tooltip";

import ClassStatus from "../../Data/ClassStatus";
import Day from "../../Data/Day";
import Heading from "../Heading";
import Name from "../Name";
import Venue from "../Venue";
import Record from "./Read/Record";
import TeacherType from "../../Data/TeacherType";
import Multiselect from "multiselect-react-dropdown";

function Read() {
  const textInput = "form-control";
  const optionInput = "form-select";
  const { authState, setAuthState } = useContext(AuthContext);
  const [classList, setClassList] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [filterTerm, setFilterTerm] = useState({
    classCode: "",
    teacherType: "",
    teacherID: "",
    startDate: "",
    endDate: "",
    day: "",
    classStatus: "",
  });

  Day.splice(7, 1, {
    value: "All",
    label: "All",
  });
  
  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/readBasedByTeacher.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Teacher/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/schoolState.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setClassList(result[0].data);

        setTeacherOptions(
          result[1].data.map(({ id, name, teacherType }) => ({
            label: name,
            id: id,
            teacherType: teacherType,
          }))
        );
      })
    );
  }, []);

  let teacherTypeOptions = [];
  TeacherType.map((item) => {
    teacherTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  const [pageNumber, setPageNumber] = useState(0);
  var cardPerPage = 15;
  const pagesVisited = pageNumber * cardPerPage;
  const pageCount = Math.ceil(classList.length / cardPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readAttendance">
          <Container fluid className="rounded bg-searchbox mb-3">
            <Row className="p-2">
              <Col sm={12} className="mb-1">
                <Heading content="Filter by" design="h5" />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="form-label">Teacher Type</label>
                <select
                  className={optionInput}
                  onChange={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      teacherType: e.target.value,
                    }))
                  }
                  defaultValue=""
                >
                  <option value="">Please Select</option>
                  {teacherTypeOptions}
                </select>
              </Col>
              <Col sm={4} className="mb-3">
                <label className="form-label">Teacher</label>
                <Multiselect
                  className="bg-white"
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() { }}
                  onSearch={function noRefCheck() { }}
                  onRemove={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      teacherID: "",
                    }))
                  }
                  onSelect={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      teacherID: e[0].id,
                    }))
                  }
                  options={teacherOptions.filter((teacherOption) =>
                    teacherOption.teacherType

                      .toLowerCase()
                      .includes(filterTerm.teacherType.toLowerCase())
                  )}
                  selectionLimit={1}
                  placeholder="Select Teacher"
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="form-label">Class Code</label>
                <input
                  type="text"
                  className={textInput}
                  placeholder="Search by Class Code"
                  onChange={(e) => {
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      classCode: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="form-label">Day</label>

                <Multiselect
                  className="bg-white"
                  singleSelect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onSearch={function noRefCheck() { }}
                  options={Day}
                  onRemove={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      day: "",
                    }))
                  }
                  onSelect={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      day: e[0].value,
                    }))
                  }
                />
              </Col>

              <Col sm={4} className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className={textInput}
                  onChange={(e) => {
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      startDate: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className={textInput}
                  onChange={(e) => {
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      endDate: e.target.value,
                    }));
                  }}
                />
              </Col>
              <Col sm={4} className="mb-3">
                <label className="form-label">Class Status</label>

                <Multiselect
                  className="bg-white"
                  singleSelect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onSearch={function noRefCheck() { }}
                  options={ClassStatus}
                  onRemove={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      classStatus: "",
                    }))
                  }
                  onSelect={(e) =>
                    setFilterTerm((filterTerm) => ({
                      ...filterTerm,
                      classStatus: e[0].value,
                    }))
                  }
                />
              </Col>
            </Row>
          </Container>

          {classList
            .filter((classList) => {
              if (filterTerm.classCode === "") {
                return classList;
              } else if (
                classList.classCode
                  .toLowerCase()
                  .includes(filterTerm.classCode.toLowerCase())
              ) {
                return classList;
              }
              return false;
            })

            .filter((classList) => {

              if (filterTerm.day === "" || filterTerm.day === "All") {
                return classList;
              } else if (
                classList.day
                  .toLowerCase()
                  .includes(filterTerm.day.toLowerCase())
              ) {
                return classList;
              }
              return false;
            })

            .filter((classList) => {
              if (filterTerm.classStatus === "" || filterTerm.classStatus === "All") {
                return classList;
              } else if (
                classList.status
                  .toLowerCase()
                  .includes(filterTerm.classStatus.toLowerCase())
              ) {
                return classList;
              }
              return false;
            })

            .filter((classList) => {
              if (filterTerm.teacherID === "") {
                return classList;
              } else if (
                classList.teacherID.toLowerCase()==filterTerm.teacherID.toLowerCase()
              ) {
                return classList;
              }
              return false;
            })

            .map((classList) => {
              const classID = classList.id;
              const classCode = classList.classCode;
              const teacherID = classList.teacherID;
              const trainerID = classList.trainerID;
              const classDay = classList.day;
              const remark = classList.remark;
              const status = classList.status;
              const learningType = classList.learningType;
              const venue = classList.venue;
              const venueID = classList.venueID;
              const startTime = moment(classList.startTime, "hh:mm:ss").format(
                "hh:mm a"
              );
              const endTime = moment(classList.endTime, "hh:mm:ss").format(
                "hh:mm a"
              );
              const language = classList.language;

              return (
                <div key={classID} className="mb-5">
                  <div className="mb-2">
                    <Heading
                      content={classCode}
                      design="h3 d-inline align-middle"
                    />
                    {status == 1 ? (
                    <p className="d-inline align-middle">
                      <a
                        className="text-decoration-none align-middle"
                        href={`${process.env.REACT_APP_SERVER_DOMAIN}Attendance/readPaymentDetailsByClass.php?classID=${classID}`}
                        data-tip
                        data-for="viewAttendancePayment"
                        target="_blank"
                      >
                        <FaEye size={28} />
                      </a>
                      <ReactTooltip
                        id="viewAttendancePayment"
                        place="top"
                        type="dark"
                        effect="float"
                      >
                        <span>View Attendance & Payment</span>
                      </ReactTooltip>
                    </p>
                    ):""}
                  </div>
                  <div className="mb-2 ms-2">
                    {learningType == "P" && (
                      <>
                        <FaSchool
                          className="icon d-inline"
                          data-tip
                          data-for={`${classID}-school`}
                        />
                        <ReactTooltip
                          id={`${classID}-school`}
                          place="bottom"
                          type="dark"
                          effect="float"
                        >
                          <span>{venue}</span>
                        </ReactTooltip>
                        <Heading
                          content={<Venue id={venueID} venueType={venue} />}
                          design="fs-6 d-inline"
                        />{" "}
                        |
                      </>
                    )}
                    {status == 1 ? (
                      <BiCheckCircle
                        className="icon d-inline"
                        data-tip
                        data-for="status"
                      />
                    ) : (
                      <BiXCircle
                        className="icon d-inline"
                        data-tip
                        data-for="status"
                      />
                    )}
                    <ReactTooltip
                      id="status"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Status</span>
                    </ReactTooltip>
                    <Heading
                      content={status == 1 ? "Active" : "Inactive"}
                      design="fs-6 d-inline"
                    />{" "}
                    |
                    <GiTeacher
                      className="icon d-inline"
                      data-tip
                      data-for="teacher"
                    />
                    <ReactTooltip
                      id="teacher"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Teacher</span>
                    </ReactTooltip>
                    <Heading
                      content={<Name id={teacherID} />}
                      design="fs-6 d-inline"
                    />{" "}
                    |
                    <GiWhistle
                      className="icon d-inline"
                      data-tip
                      data-for="trainer"
                    />
                    <ReactTooltip
                      id="trainer"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Trainer</span>
                    </ReactTooltip>
                    <Heading
                      content={<Name id={trainerID} />}
                      design="fs-6 d-inline"
                    />{" "}
                    |{" "}
                    <FcCalendar
                      className="icon d-inline"
                      data-tip
                      data-for="date"
                    />
                    <ReactTooltip
                      id="date"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Day</span>
                    </ReactTooltip>
                    <Heading content={classDay} design="fs-6 d-inline" /> |{" "}
                    <FcAlarmClock
                      className="icon d-inline"
                      data-tip
                      data-for="time"
                    />
                    <ReactTooltip
                      id="time"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Time</span>
                    </ReactTooltip>
                    <Heading
                      content={`${startTime} - ${endTime}`}
                      design="fs-6 d-inline"
                    />{" "}
                    |{" "}
                    <FaLanguage
                      className="icon d-inline"
                      data-tip
                      data-for="language"
                    />
                    <ReactTooltip
                      id="language"
                      place="bottom"
                      type="dark"
                      effect="float"
                    >
                      <span>Language</span>
                    </ReactTooltip>
                    <Heading content={language} design="fs-6 d-inline" />
                    {remark !== null ||
                      ("" && (
                        <>
                          {" "}
                          |{" "}
                          <FcInfo
                            className="icon d-inline"
                            data-tip
                            data-for={`${classID}-${remark}`}
                          />
                          <ReactTooltip
                            id={`${classID}-${remark}`}
                            place="bottom"
                            type="dark"
                            effect="float"
                          >
                            <span>{remark}</span>
                          </ReactTooltip>
                        </>
                      ))}
                  </div>
                  <Record
                    classID={classID}
                    realteacherID={teacherID}
                    filterTerm={filterTerm}
                  />
                </div>
              );
            })
            .slice(pagesVisited, pagesVisited + cardPerPage)}
          <div className="w-100">
            <ReactPaginate
              previousLabel={<FaChevronLeft />}
              nextLabel={<FaChevronRight />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginateBtn"}
              // previousLinkClassName={"page-item btn"}
              // nextLinkClassName={"page-item btn"}
              disabledClassName={"disabled"}
              activeClassName={"paginateBtnActive"}
            />
          </div>
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;
