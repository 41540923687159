import React, {
  forwardRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { AuthContext } from "../../../Helpers/AuthContext";
import { toast } from "react-toastify";

import { IoAdd } from "react-icons/io5";
import { FaEye } from "react-icons/fa";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

function Read() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [studentList, setStudentlist] = useState([]);
  
  
  useEffect(() => {
    let readPayment = new FormData();
    readPayment.append("currentUser", authState.id);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/read.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: readPayment,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setStudentlist(result.data);
      }
    });
  }, [authState.id]);

  const columns = [
    { title: "Student Code", field: "studentCode", editable: "never" },
    { title: "English Name", field: "englishName", editable: "never" },
    { title: "Chinese Name", field: "chineseName", editable: "never" },
    { title: "Center Name", field: "centerName", editable: "never" },
    { title: "School Name", field: "schoolName", editable: "never" },
    { title: "Course Fee", field: "courseFee", editable: "never", type: 'numeric' },
    { title: "Days Since Last Payment", field: "daysSinceLastPayment", editable: "never", type: 'numeric' },
    { title: "Last Payment Information", field: "lastPaymentInformation", editable: "never", type: 'date' },
    { title: "Processing Payment Slip Count", field: "processingPaymentCount", editable: "never", type: 'numeric' },
    { title: "Overpay or Underpay", field: "overpayOrUnderpayText", editable: "never" },
    { title: "Teacher Name", field: "teacherName", editable: "never" },
    { title: "Enrolment Teacher Name", field: "enrolmentTeacherName", editable: "never" },
    { title: "Last Payment Date", field: "lastPaymentDate", editable: "never", type: 'date'},
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readStudent">
          <MaterialTable
            title=""
            columns={columns}
            data={studentList}
            icons={tableIcons}
            key={studentList.length}
            options={{
              sorting: true,
              filtering: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              paging: true,
              pageSize: 25,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [25, 50, 75, 100],
              exportButton: true,
              exportAllData: true,
              exportFileName: "Student List for Payment",
              //actionsColumnIndex: -1,
              selection: false,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: true,
              tableLayout: "auto",
              rowStyle: {
                overflowWrap: "break-word",
              },
              headerStyle: { position: "sticky", top: 0, zIndex: 1 },
              maxBodyHeight: "75vh",
            }}
            actions={[
              // {
              //   icon: () => <IoAdd style={{ color: "blue" }} />,
              //   tooltip: "Add payment information",
              //   isFreeAction: false,
              //   onClick: (event, rowData) =>
              //     navigate(`/addPayment/${rowData.id}`),
              // },w
              {
                icon: () => <FaEye style={{ color: "blue" }} />,
                tooltip: "View payment history",
                isFreeAction: false,
                onClick: (event, rowData) =>
                  navigate(`/viewPayment/${rowData.id}`),
              },
              {
                icon: () => <FaEye style={{ color: "blue" }} />,
                tooltip: "VIew installment plan",
                isFreeAction: false,
                onClick: (event, rowData) =>
                  navigate(`/viewInstallmentPlan/${rowData.id}`),
              },
            ]}
          />
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;