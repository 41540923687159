import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { Col, Container, Row, Button, Spinner } from "react-bootstrap";
import Heading from "../Heading";
import Calculate from "./Create/Calculate";
import { BiUndo } from "react-icons/bi";
import { toast } from "react-toastify";

import Plan from "../../Data/Plan";

function Create() {
  const { authState, setAuthState } = useContext(AuthContext);
  const textInput = "form-control";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    installment: "",
    amount: 0,
  });

  let planOptions = [];
  Plan.map((item) => {
    planOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let submitBtn = false;
  if (
    submitValue.installment !== "" &&
    submitValue.amount > 0 &&
    submitValue.amount !== ""
  ) {
    submitBtn = true;
  }

  const submitForm = () => {
    setLoading(true);
    let createInstallment = new FormData();
    createInstallment.append("id", submitValue.id);
    createInstallment.append("installment", submitValue.installment);
    createInstallment.append("amount", submitValue.amount);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Installment/create.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: createInstallment,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/installment"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createInstallment" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content="New Installment" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-danger" size="sm" href="/installment">
                <BiUndo /> Cancel
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Installment Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    installment: e.target.value.toUpperCase(),
                  }))
                }
                value={submitValue.installment}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Total Amount</label>
              <input
                type="number"
                min="0"
                step=".01"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    amount: e.target.value,
                  }))
                }
                value={submitValue.amount}
              />
            </Col>
          </Row>
          {submitValue.amount > 0 && (
            <Row>
              <Calculate plan="3" submitValue={submitValue} />
              <Calculate plan="6" submitValue={submitValue} />
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
