import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import dayOptions from "../../../Data/Day";
import { BiChevronLeft } from "react-icons/bi";

function Schedule({ submitValue, setSubmitValue, setKey }) {
  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Start Time</label>
            <input
              type="time"
              id="inputStartTime"
              placeholder="Start Time"
              className="form-control"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  startTime: e.target.value,
                }))
              }
              value={submitValue.startTime}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">End Time</label>
            <input
              type="time"
              id="inputEndTime"
              placeholder="End Time"
              className="form-control"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  endTime: e.target.value,
                }))
              }
              value={submitValue.endTime}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Day</label>
            <Select
              options={dayOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  day: e.value,
                }))
              }
              value={dayOptions.find((obj) => obj.value === submitValue.day)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("info")}
              >
                <BiChevronLeft /> Previous
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Schedule;
