const BusinessType= [
    {
      value: "B2C",
      label: "Business to Consumer (B2C)",
    },
    {
      value: "B2B",
      label: "Business to Business (B2B)",
    }
  ];
  
  export default BusinessType;
  