import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import Heading from "../../Heading";
import PaymentDetails from "./UpdatePayment/PaymentDetails";
import { BiUndo } from "react-icons/bi";
import moment from "moment";

function UpdatePayment() {
  const { paymentID , studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("paymentDetails");
  const [materialFeeType, setMaterialFeeType] = useState();
  const [buttonText, setButtonText] = useState("Update");
  const [submitBtn, setSubmitBtn] = useState(false);
  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    paymentID: paymentID,
    studentID: studentID,
    englishName: "",
    chineseName: "",
    category: "",
    materialFeeType: "-",
    studentID: studentID,
    originalPaymentSlip: [],
    newPaymentSlip: []
  });

  const [materialFeeSelectedArr, setMaterialFeeSelectedArr] = useState([]);
  const [materialFeeAmountArr, setMaterialFeeAmountArr] = useState([]);
  const [materialFeeSelectedGroupArr, setMaterialFeeSelectedGroupArr] = useState([]);
  const studentMaterialFeeArr = (e, f, g, index) => {
    let tempMaterialFeeSelected = materialFeeSelectedArr;
    tempMaterialFeeSelected[index] = e;
    setMaterialFeeSelectedArr((tempMaterialFeeSelected) => [...tempMaterialFeeSelected]);
    
    let tempMaterialFeeAmount = materialFeeAmountArr;
    tempMaterialFeeAmount[index] = f;
    setMaterialFeeAmountArr((tempMaterialFeeAmount) => [...tempMaterialFeeAmount]);

    let tempMaterialFeeSelectedGroup = materialFeeSelectedGroupArr;
    tempMaterialFeeSelectedGroup[index] = g;
    setMaterialFeeSelectedGroupArr((tempMaterialFeeSelectedGroup) => [...tempMaterialFeeSelectedGroup]);
  };

  useEffect(() => {
    let updatePayment = new FormData();
    updatePayment.append("paymentID", paymentID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/readPaymentDetails.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updatePayment,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        
        setSubmitValue((submitValue) => ({
          ...submitValue,
          studentID: result.data[1][0].id,
          englishName: result.data[1][0].englishName,
          chineseName: result.data[1][0].chineseName,
          currency: result.data[1][0].currency,
          category: result.data[1][0].category,

          paymentID: result.data[0][0].id,
          paymentYear: result.data[0][0].paymentYear,
          paymentType: result.data[0][0].paymentType,
          paymentCourseFee: result.data[0][0].courseFee,
          paymentPenaltyFee: result.data[0][0].penaltyFee,
          teacherRemark: result.data[0][0].reference,
          requestReceipt: result.data[0][0].requestReceipt,
          transactionDate: result.data[0][0].paymentSlipDate,
          status: result.data[0][0].status,
          originalPaymentSlip: result.data[2],
        }));

      }
    });
  }, [paymentID]);

  const submitForm = () => {
    setButtonText("Processing");
    setSubmitBtn(true);
    let todaySubstractTwoYear = new moment().subtract(730, 'days');
    if (
      (submitValue.newPaymentSlip == "" && submitValue.originalPaymentSlip == "") ||
      submitValue.transactionDate == "" ||
      moment(submitValue.transactionDate).isAfter(moment().format('YYYY-MM-DD'), 'day')||
      !moment(submitValue.transactionDate).isAfter(todaySubstractTwoYear, 'day')
    ) {
      setButtonText("Update");
      setSubmitBtn(false);
      return toast.error(
        <Fragment>
          <span>{"Please fill in all the required field marked by * properly"}</span>
        </Fragment>
      );
      
    }
    let updateID = new FormData();
    let submitPayment = [];
    
    updateID.append("paymentID", submitValue.paymentID);
    updateID.append("action", "updatePayment");
    updateID.append("paymentYear", submitValue.paymentYear);
    updateID.append("paymentType", submitValue.paymentType);
    updateID.append("courseFee", submitValue.paymentCourseFee);
    updateID.append("materialFeeSelectedArr", materialFeeSelectedArr);
    updateID.append("materialFeeAmountArr", materialFeeAmountArr);
    updateID.append("penaltyFee", submitValue.paymentPenaltyFee);
    updateID.append("teacherRemark", submitValue.teacherRemark);
    updateID.append("requestReceipt", submitValue.requestReceipt);
    updateID.append("transactionDate", submitValue.transactionDate);
    updateID.append("materialFeeType", materialFeeType);
    updateID.append("currentUser", authState.id);
    for (let i = 0; i < submitValue.newPaymentSlip.length; i++) {
      updateID.append("paymentSlip[]", submitValue.newPaymentSlip[i]);
    }

    submitPayment.push({
      materialFeeSelected: materialFeeSelectedArr[0],
      materialFeeSelectedGroupArr: materialFeeSelectedGroupArr[0]
    });
    
    const submitPaymentJson = JSON.stringify(submitPayment);
    
    updateID.append("submitPaymentJson", submitPaymentJson);
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/updatePayment.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: updateID,
      
    }).then((result) => {
      if (result.data.error) {
        setButtonText("Update");
        setSubmitBtn(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setButtonText("Update");
        setSubmitBtn(false);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate(`/viewpayment/${studentID}`),
        ];
     
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="updatePayment" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content="Update Payment" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-secondary" size="sm" className="float-end" href={`/viewpayment/${studentID}`}>
                <BiUndo /> Back
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
            <Tab eventKey="paymentDetails" title="Payment Details">
              <PaymentDetails
                setKey={setKey}
                submitValue={submitValue}
                setSubmitValue={setSubmitValue}

                studentMaterialFeeArr={studentMaterialFeeArr}
                materialFeeSelectedArr={materialFeeSelectedArr}
                materialFeeAmountArr={materialFeeAmountArr}

                setMaterialFeeType={setMaterialFeeType}
                materialFeeType={materialFeeType}
              />
            </Tab>
            </Tabs>
          </Row>
         
          <Row>
            <Col sm={12}>
              <div className="float-end">
                <Button variant="primary" disabled={submitBtn} onClick={submitForm}>
                  {buttonText}{" "}
                </Button>
                
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default UpdatePayment;
