import React, { useState, useEffect } from "react";
import Axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

import Gender from "../../../Data/Gender";
import ClassType from "../../../Data/ClassType";
import Category from "../../../Data/StudentCategory";
import Currency from "currency-codes/data";
import Plan from "../../../Data/Plan";
import Status from "../../../Data/Status";

import { Container, Row, Col, Button } from "react-bootstrap";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";

function StudentB2C({
  setKey,
  submitValue,
  setSubmitValue,
  handleStudentList,
  setStudentList,
  studentList,
}) {
  const textInput = "form-control";
  const optionInput = "form-select";

  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolStateOptions, setSchoolStateOptions] = useState([]);

  useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_SERVER_DOMAIN}Installment/read.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/school.php/`,
      `${process.env.REACT_APP_SERVER_DOMAIN}Options/schoolState.php/`,
    ];

    Promise.all(endpoints.map((endpoint) => Axios.get(endpoint))).then(
      Axios.spread((...result) => {
        setInstallmentOptions(
          result[0].data.map((installmentOption) => {
            return (
              <option key={installmentOption.id} value={installmentOption.id}>
                {installmentOption.title} - MYR {installmentOption.amount}
              </option>
            );
          })
        );

        setSchoolOptions(
          result[1].data.map(({ name, state }) => ({
            label: name,
            value: name,
            state: state
          }))
        );

        setSchoolStateOptions(
          result[2].data.map(({ state }) => ({
            label: state,
            value: state,
          }))
        );
      })
    );
  }, [submitValue.classID]);

  let classTypeOptions = [];
  ClassType.map((item) => {
    classTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let categoryOptions = [];
  Category.map((item) => {
    categoryOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let currencyOptions = [];
  Currency.map((item) => {
    currencyOptions.push(
      <option value={item.code} key={item.code}>
        {item.currency} ({item.code})
      </option>
    );
  });

  let planOptions = [];
  Plan.map((item) => {
    planOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  let statusOptions = [];
  Status.map((item) => {
    statusOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });

  const index = 0;

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">English Name</label>
            <input
              type="text"
              className={textInput}
              name="englishName"
              onChange={(event) =>
                handleStudentList(
                  index,
                  event.target.name,
                  event.target.value.toUpperCase()
                )
              }
              value={studentList[index].englishName}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Chinese Name</label>
            <input
              type="text"
              className={textInput}
              name="chineseName"
              onChange={(event) =>
                handleStudentList(
                  index,
                  event.target.name,
                  event.target.value.toUpperCase()
                )
              }
              value={studentList[index].chineseName}
            />
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Alternative Phone Number</label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="MY"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  kidPhoneNumber: e,
                }))
              }
              value={submitValue.kidPhoneNumber}
            />
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Gender</label>
            <Multiselect
              singleSelect
              displayValue="label"
              avoidHighlightFirstOption
              onSearch={function noRefCheck() {}}
              onSelect={(e) => handleStudentList(index, "gender", e[0].value)}
              options={Gender}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Age</label>
            <input
              type="number"
              min="1"
              className={textInput}
              name="age"
              onChange={(event) =>
                handleStudentList(index, event.target.name, event.target.value)
              }
              value={studentList[index].age}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <label className="form-label required">Type</label>
              <label className="form-label required">Category</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    classType: e.target.value,
                  }))
                }
                value={submitValue.classType}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {classTypeOptions}
              </select>

              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    category: e.target.value,
                  }))
                }
                value={submitValue.category}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {categoryOptions}
              </select>
            </div>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <label className="form-label required">Currency</label>
              <label className="form-label required">Course Fee</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    currency: e.target.value,
                  }))
                }
                value={submitValue.currency}
              >
                {currencyOptions}
              </select>
              <input
                type="number"
                min="0"
                step=".01"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    courseFee: e.target.value,
                  }))
                }
                value={submitValue.courseFee}
              />
            </div>
          </Col>

          <Col sm={12} md={6} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <label className="form-label">Installment</label>
              <label className="form-label">Plan</label>
            </div>
            <div className="input-group flex-nowrap">
              <select
                className={optionInput}
                name="installment"
                onChange={(event) =>
                  handleStudentList(
                    index,
                    event.target.name,
                    event.target.value
                  )
                }
                value={studentList[index].installment}
              >
                <option value="0" key="Select...">
                  Select
                </option>
                {installmentOptions}
              </select>
              <select
                className={optionInput}
                name="plan"
                onChange={(event) =>
                  handleStudentList(
                    index,
                    event.target.name,
                    event.target.value
                  )
                }
                value={studentList[index].plan}
              >
                <option value="0" key="Select...">
                  Select
                </option>
                {planOptions}
              </select>
            </div>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">School State</label>
            <Select
              options={schoolStateOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  schoolState: e.value,
                }))
              }
              value={schoolStateOptions.find(
                (obj) => obj.value === submitValue.schoolState
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">School Name</label>
            <Multiselect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      schoolName: "",
                    }))
                  }
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      schoolName: e[0].value,
                    }))
                  }
                  options={schoolOptions.filter((schoolOption) =>
                    schoolOption.state
                      .toLowerCase()
                      .includes(submitValue.schoolState.toLowerCase())
                  )}
                  selectionLimit={1}
                  placeholder="Select School"
                />

          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Status</label>
                <select
                  className={optionInput}
                  name="status"
                  onChange={(event) =>
                    handleStudentList(
                      index,
                      event.target.name,
                      event.target.value
                    )
                  }
                  value={studentList[index].status}
                >
                  {statusOptions}
                </select>
              </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                <BiChevronLeft /> Previous
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StudentB2C;
