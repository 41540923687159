import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import Read from "../Components/Installment/Read";

import { Container, Row, Col, Button } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";

function Installment() {
  const { authState, setAuthState } = useContext(AuthContext);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <div>
        <Container className="my-3 p-3">
          <Row className="my-2">
            <Col sm={10}>
              <Heading content="Manage Installment" design="h4" />
            </Col>
            {authState.id == 17 && (
              <Col sm={2}>
                <Button variant="primary" href="/createInstallment">
                  <BiPlus /> Installment
                </Button>
              </Col>
            )}
          </Row>
          <Row className="my-5">
            <Read />
          </Row>
        </Container>
      </div>
    </AuthContext.Provider>
  );
}

export default Installment;
