import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Axios from "axios";
import { Col, Container, Row, Button, Spinner, Alert } from "react-bootstrap";
import Heading from "../Heading";
import { BiUndo } from "react-icons/bi";
import { toast } from "react-toastify";
import State from "../../Data/State";

function Create() {
  const { authState, setAuthState } = useContext(AuthContext);
  const textInput = "form-control";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    centerName: "",
    state: "",
  });

  let submitBtn = false;
  if (submitValue.centerName !== "" && submitValue.state !== "") {
    submitBtn = true;
  }

  const submitForm = () => {
    setLoading(true);
    let createCenter = new FormData();
    createCenter.append("id", submitValue.id);
    createCenter.append("centerName", submitValue.centerName);
    createCenter.append("state", submitValue.state);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Center/create.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: createCenter,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/center"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createCenter" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content="New Center" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-danger" size="sm" href="/center">
                <BiUndo /> Cancel
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Center Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    centerName: e.target.value.toUpperCase(),
                  }))
                }
                value={submitValue.centerName}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label">State</label>
              <Multiselect
                singleSelect
                displayValue="label"
                avoidHighlightFirstOption
                onRemove={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    state: e[0].value,
                  }))
                }
                onSearch={function noRefCheck() {}}
                onSelect={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    state: e[0].value,
                  }))
                }
                options={State}
              />
            </Col>
          </Row>
          <Row className="mx-3">
            <Alert variant="danger">
            <Alert.Heading>
                Rules to create Center. Please follow the rules below!
              </Alert.Heading>
              <hr />
              <div className="mx-2">
              <p className="mb-1">Center Name (registered company name)</p>
                <li className="mb-0 mx-2">Example: AZ PRESCHOOL IMPIAN EMAS (TADIKA A-Z SDN BHD)</li>
              </div>
            </Alert>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
