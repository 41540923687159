import React, {
    forwardRef,
    useContext,
    Fragment,
    useState,
    useEffect,
  } from "react";
  import Axios from "axios";
  import { AuthContext } from "../../Helpers/AuthContext";
  import { toast } from "react-toastify";
import { BiTrashAlt } from "react-icons/bi";
  
  import MaterialTable from "@material-table/core";
  
  import AddBox from "@material-ui/icons/AddBox";
  import ArrowDownward from "@material-ui/icons/ArrowDownward";
  import Check from "@material-ui/icons/Check";
  import ChevronLeft from "@material-ui/icons/ChevronLeft";
  import ChevronRight from "@material-ui/icons/ChevronRight";
  import Clear from "@material-ui/icons/Clear";
  import DeleteOutline from "@material-ui/icons/DeleteOutline";
  import Edit from "@material-ui/icons/Edit";
  import FilterList from "@material-ui/icons/FilterList";
  import FirstPage from "@material-ui/icons/FirstPage";
  import LastPage from "@material-ui/icons/LastPage";
  import Remove from "@material-ui/icons/Remove";
  import SaveAlt from "@material-ui/icons/SaveAlt";
  import Search from "@material-ui/icons/Search";
  import ViewColumn from "@material-ui/icons/ViewColumn";
  
  function Read() {
    const { authState, setAuthState } = useContext(AuthContext);
    const [centerList, setCenterList] = useState([]);
  
    useEffect(() => {
      Axios({
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}Center/read.php/`,
        config: {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        },
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
            setCenterList(result.data);
        }
      });
    }, []);
  
    const columns = [
      { title: "Name", field: "name", editable: "never" },
      {
        title: "State",
        field: "state",
        editable: "never",
      },
      { title: "Last Updated", field: "lastUpdated", editable: "never" },
    ];
  
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };
  
    return (
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Fragment>
          <div id="readCenter">
            {authState.id == 17 ? 
            <MaterialTable
            title=""
            columns={columns}
            data={centerList}
            icons={tableIcons}
            options={{
              sorting: true,
              filtering: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              paging: true,
              pageSize: 25,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [25, 50, 75, 100],
              exportButton: true,
              exportAllData: true,
              exportFileName: "Total User",
              actionsColumnIndex: -1,
              selection: true,
              showSelectAllCheckbox: false,
            showTextRowsSelected: false,
                grouping: false,
              columnsButton: true,
            }}
            actions={[
              {
                icon: () => <BiTrashAlt />,
                tooltip: "Delete Center",
                isFreeAction: false,
                onClick: (e, data) => {
                  const centerArr = JSON.stringify(data);
                  let deleteCenter = new FormData();
                  deleteCenter.append("id", authState.id);
                  deleteCenter.append("centerArr", centerArr);

                  Axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_SERVER_DOMAIN}Center/delete.php/`,
                    config: {
                      headers: {
                        "Content-Type": "application/json",
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    },
                    data: deleteCenter,
                  }).then((result) => {
                    if (result.data.error) {
                      return toast.error(
                        <Fragment>
                          <span>{result.data.error}</span>
                        </Fragment>
                      );
                    } else if (result.data.warn) {
                      return toast.warn(
                        <Fragment>
                          <span>{result.data.warn}</span>
                        </Fragment>
                      );
                    } else {
                      return [
                        toast.success(
                          <Fragment>
                            <span>Successfully Deleted</span>
                          </Fragment>
                        ),
                        setCenterList(result.data),
                      ];
                    }
                  });
                },
              },
            ]}
          />
        :
        <MaterialTable
              title=""
              columns={columns}
              data={centerList}
              icons={tableIcons}
              options={{
                sorting: true,
                filtering: true,
                search: true,
                searchFieldAlignment: "right",
                searchAutoFocus: true,
                searchFieldVariant: "standard",
                paging: true,
                pageSize: 25,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [25, 50, 75, 100],
                exportButton: true,
                exportAllData: true,
                exportFileName: "Total User",
                actionsColumnIndex: -1,
                selection: false,
                columnsButton: true,
              }}
            />
            }
          </div>
        </Fragment>
      </AuthContext.Provider>
    );
  }
  
  export default Read;
  