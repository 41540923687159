import React, { useState, useEffect } from "react";
import Axios from "axios";

function ClassCode(props) {
  const classID = props.id;
  const [viewClassCode, setViewClassCode] = useState("Ukubear User");

  useEffect(() => {
    let classCodeInfo = new FormData();
    classCodeInfo.append("classID", classID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}classCode.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: classCodeInfo,
    }).then((result) => {
      setViewClassCode(result.data.classCode);
    });
  }, [classID]);

  return <>{viewClassCode}</>;
}

export default ClassCode;
