const TeacherType= [

    {
      value: "F",
      label: "Full Time",
    },
    {
      value: "P",
      label: "Part Time",
    },
  ];
  
  export default TeacherType;