import React, {
  forwardRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import Axios from "axios";
import { AuthContext } from "../../Helpers/AuthContext";
import { toast } from "react-toastify";
import { BiBlock, BiCheckCircle, BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

function Read() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Account/read.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setAccountList(result.data);
      }
    });
  }, []);

  const columns = [
    { title: "Name", field: "name", editable: "never" },
    { title: "Nick Name", field: "nickName", editable: "never" },
    { title: "Phone Number", field: "phoneNumber", editable: "never" },
    {
      title: "Role",
      field: "role",
      editable: "never",
      lookup: {
        1: "Admin",
        2: "Enrollment Teacher",
        3: "Scheduler",
        4: "Guardian",
        5: "Trainer",
        6: "Teacher",
      },
    },
    {
      title: "Teacher Type",
      field: "teacherType",
      editable: "never",
      lookup: { P: "Part Time", F: "Full Time" },
    },
    {
      title: "Status",
      field: "status",
      editable: "never",
      lookup: { 0: "Inactive", 1: "Active" },
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readCenter">
          <MaterialTable
            title=""
            columns={columns}
            data={accountList}
            icons={tableIcons}
            options={{
              sorting: true,
              filtering: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              paging: true,
              pageSize: 25,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [25, 50, 75, 100],
              exportButton: true,
              exportAllData: true,
              exportFileName: "Total User",
              actionsColumnIndex: -1,
              selection: true,
              showSelectAllCheckbox: false,
            showTextRowsSelected: false,
                grouping: false,
              columnsButton: true,
            }}
            actions={[
              {
                icon: () => <BiCheckCircle />,
                tooltip: "Activate the account",
                isFreeAction: false,
                onClick: (e, data) => {
                  const accountArr = JSON.stringify(data);
                  let statusAccount = new FormData();
                  statusAccount.append("id", authState.id);
                  statusAccount.append("status", "1");
                  statusAccount.append("accountArr", accountArr);

                  Axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_SERVER_DOMAIN}Account/status.php/`,
                    config: {
                      headers: {
                        "Content-Type": "application/json",
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    },
                    data: statusAccount,
                  }).then((result) => {
                    if (result.data.error) {
                      return toast.error(
                        <Fragment>
                          <span>{result.data.error}</span>
                        </Fragment>
                      );
                    } else if (result.data.warn) {
                      return toast.warn(
                        <Fragment>
                          <span>{result.data.warn}</span>
                        </Fragment>
                      );
                    } else {
                      return [
                        toast.success(
                          <Fragment>
                            <span>Successfully Activated</span>
                          </Fragment>
                        ),
                        setAccountList(result.data),
                      ];
                    }
                  });
                },
              },
              {
                icon: () => <BiBlock />,
                tooltip: "Deactivate the account",
                isFreeAction: false,
                onClick: (e, data) => {
                  const accountArr = JSON.stringify(data);
                  let statusAccount = new FormData();
                  statusAccount.append("id", authState.id);
                  statusAccount.append("status", "0");
                  statusAccount.append("accountArr", accountArr);

                  Axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_SERVER_DOMAIN}Account/status.php/`,
                    config: {
                      headers: {
                        "Content-Type": "application/json",
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    },
                    data: statusAccount,
                  }).then((result) => {
                    if (result.data.error) {
                      return toast.error(
                        <Fragment>
                          <span>{result.data.error}</span>
                        </Fragment>
                      );
                    } else if (result.data.warn) {
                      return toast.warn(
                        <Fragment>
                          <span>{result.data.warn}</span>
                        </Fragment>
                      );
                    } else {
                      return [
                        toast.success(
                          <Fragment>
                            <span>Successfully Deactivate</span>
                          </Fragment>
                        ),
                        setAccountList(result.data),
                      ];
                    }
                  });
                },
              },
              {
                icon: () => (
                  <BiEdit />
                ),
                tooltip: "Edit",
                isFreeAction: false,
                onClick: (event, rowData) =>
                  navigate(`/updateAccount/${rowData[0].id}`)
              },
            ]}
          />
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;
