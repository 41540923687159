const Category= [
  {
    value: "P",
    label: "Primary",
  },
  {
    value: "E",
    label: "PEK",
  },
  {
    value: "U",
    label: "UOC",
  },
  {
    value: "K",
    label: "Kindergarten",
  },
  {
    value: "I",
    label: "International School",
  },
];

export default Category;
