import React, { useState, useEffect } from "react";
import Axios from "axios";

function StudentName(props) {
  const userID = props.id;
  const [viewStudentName, setViewStudentName] = useState("Ukubear User");

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentName.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {
      setViewStudentName(result.data.studentName);
    });
  }, [userID]);

  return <>{viewStudentName}</>;
}

export default StudentName;
