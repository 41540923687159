const Plan = [
  {
    value: 3,
    label: "3 months",
  },
  {
    value: 6,
    label: "6 months",
  },
];

export default Plan;
