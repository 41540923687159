import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { AuthContext } from "./Helpers/AuthContext";
import { Container, Row, Col } from "react-bootstrap";


// import Header based on Roles
import Admin from "./Components/Header/Admin";
import SalesPerson from "./Components/Header/SalesPerson";
import Teacher from "./Components/Header/Teacher";

//import Routes basec on Roles
import routes from "./Config/routes";
import salesperson from "./Config/salesperson";
import teacher from "./Config/teacher";
import signInroutes from "./Config/signInRoute";

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  // const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    id: "",
    role: "",
    phoneNumber: "",
    status: false,
  });

  useEffect(() => {
    let accessInfo = localStorage.getItem("accessToken")
    Axios({
      method: "get",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}auth.php?token=${accessInfo}`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      }
    }).then((result) => {
      if (result.data.error) {
        setAuthState({ ...authState, status: false });
      } else {
        setAuthState({
          id: result.data.id,
          role: result.data.role,
          phoneNumber: result.data.phoneNumber,
          status: true,
        });
      }
    });
  }, [authState.status]);

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        {authState.status ? (
          <Container fluid id="maincontent">
            <Row>
              <Col xs={2} md={3} lg={2} className="m-0 p-0 ">
                {authState.role == 1 && <Admin />}
                {authState.role == 2 && <SalesPerson />}
                {authState.role == 6 && <Teacher />}
              </Col>
              <Col xs={10} md={9} lg={10}  id="subcontent" className="m-0 p-0">
                <Router>
                  <Routes>
                    {authState.role == 1 &&
                      routes.map(({ path, name, element }, key) => {
                        return (
                          <Route
                            key={element}
                            exact
                            path={path}
                            name={name}
                            element={element}
                          />
                        );
                      })}

                    {authState.role == 2 &&
                      salesperson.map(({ path, name, element }, key) => {
                        return (
                          <Route
                            key={element}
                            exact
                            path={path}
                            name={name}
                            element={element}
                          />
                        );
                      })}

                    {authState.role == 6 &&
                      teacher.map(({ path, name, element }, key) => {
                        return (
                          <Route
                            key={element}
                            exact
                            path={path}
                            name={name}
                            element={element}
                          />
                        );
                      })}

                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Router>
              </Col>
            </Row>
          </Container>
        ) : (
          <Router>
            <Routes>
              {signInroutes.map(({ path, name, element }, key) => {
                return (
                  <Route
                    key={element}
                    exact
                    path={path}
                    name={name}
                    element={element}
                  />
                );
              })}
            </Routes>
          </Router>
        )}
        <ScrollToTop />

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
