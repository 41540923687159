import React, { useEffect, useState } from "react";
import Axios from "axios";
import { BiBadgeCheck } from "react-icons/bi";
function Indicator(props) {
  let indicatorDates = props.indicatorDates;
  let studentID = props.studentID;
  const [indicator, setIndicator] = useState([]);

  useEffect(() => {
    let getClassIndicator = new FormData();
    getClassIndicator.append("studentID", studentID);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Attendance/indicator.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: getClassIndicator,
    }).then((response) => {
      setIndicator(response.data);
    });
  }, [studentID]);

  const forthClass = indicator.includes(indicatorDates);

  return (
    <span>
      {" "}
      {forthClass ? <BiBadgeCheck className="text-success" /> : null}
    </span>
  );
}

export default Indicator;
