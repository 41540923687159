import React, { useContext, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { Container, Row, Col, Button, Tab, Tabs, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import Heading from "../Heading";
import Guardian from "./Create/Guardian";
import StudentB2B from "./Create/StudentB2B";
import StudentB2C from "./Create/StudentB2C";
import { BiUndo } from "react-icons/bi";

function Create() {
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("guardian");

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    businessType: "B2C",
    salespersonID: authState.id,
    centerState: "",
    centerID: "",
    classType: "",
    category: "",
    currency: "MYR",
    courseFee: 0.0,
    guardianName: "PARENT",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateRegion: "",
    zip: "",
    country: "MY",
    kidPhoneNumber: "",
    schoolName: "",
    schoolState: "",
    classLocation: "",
    collectByTeacher: 0,
  });

  const [studentList, setStudentList] = useState([
    {
      englishName: "",
      chineseName: "",
      guardianPhoneNumber: "",
      gender: "",
      age: 0,
      status: 0,
      installment: 0,
      plan: 0,
    },
  ]);

  const handleStudentList = (index, name, value) => {
    let studentData = [...studentList];
    studentData[index][name] = value;
    setStudentList(studentData);
  };

  const addRow = () => {
    let newfield = {
      englishName: "",
      chineseName: "",
      guardianPhoneNumber: "",
      gender: "",
      age: 0,
      status: 0,
      installment: 0,
      plan: 0,
    };

    if (studentList.length < 16) {
      setStudentList([...studentList, newfield]);
    } else {
      return toast.warn(
        <Fragment>
          <span>Reached Limit</span>
        </Fragment>
      );
    }
  };

  const deleteRow = (index) => {
    let studentData = [...studentList];
    studentData.splice(index, 1);
    setStudentList(studentData);
  };

  let submitBtn = false;

  if (submitValue.businessType === "B2B") {
    if (
      submitValue.businessType !== "" &&
      submitValue.salespersonID !== "" &&
      submitValue.centerID !== "" &&
      submitValue.classType !== "" &&
      submitValue.category !== "" &&
      submitValue.courseFee > 0 &&
      submitValue.courseFee !== "" &&
      submitValue.classLocation !== ""
    ) {
      submitBtn = true;
    }
  } else {
    if (
      submitValue.businessType !== "" &&
      submitValue.salespersonID !== "" &&
      submitValue.country !== "" &&
      submitValue.classType !== "" &&
      submitValue.category !== "" &&
      submitValue.courseFee > 0 &&
      submitValue.courseFee !== "" &&
      submitValue.classLocation !== "" &&
      studentList[0].guardianPhoneNumber !== "" &&
      studentList[0].gender !== "" &&
      studentList[0].age !== "" &&
      studentList[0].age > 0 &&
      (studentList[0].englishName !== "" || studentList[0].chineseName !== "")
    ) {
      submitBtn = true;
    }
  }

  const submitForm = () => {
    setLoading(true);
    const studentListJson = JSON.stringify(studentList);

    let createStudent = new FormData();
    createStudent.append("id", submitValue.id);
    createStudent.append("businessType", submitValue.businessType);
    createStudent.append("salespersonID", submitValue.salespersonID);
    createStudent.append("centerState", submitValue.centerState);
    createStudent.append("centerID", submitValue.centerID);
    createStudent.append("guardianName", submitValue.guardianName);
    createStudent.append("addressLine1", submitValue.addressLine1);
    createStudent.append("addressLine2", submitValue.addressLine2);
    createStudent.append("city", submitValue.city);
    createStudent.append("stateRegion", submitValue.stateRegion);
    createStudent.append("zip", submitValue.zip);
    createStudent.append("country", submitValue.country);
    createStudent.append("schoolName", submitValue.schoolName);
    createStudent.append("schoolState", submitValue.schoolState);
    createStudent.append("classType", submitValue.classType);
    createStudent.append("category", submitValue.category);
    createStudent.append("currency", submitValue.currency);
    createStudent.append("courseFee", submitValue.courseFee);
    createStudent.append("kidPhoneNumber", submitValue.kidPhoneNumber);
    createStudent.append("classLocation", submitValue.classLocation);
    createStudent.append("collectByTeacher", submitValue.collectByTeacher);

    createStudent.append("studentList", studentListJson);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Student/create.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: createStudent,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(false);
        if (submitValue.businessType === "B2B") {
          return [
            toast.success(
              <Fragment>
                <span>{result.data.success}</span>
              </Fragment>
            ),
            navigate("/payment"),
          ];
        } else {
          return [
            toast.success(
              <Fragment>
                <span>{result.data.success}</span>
              </Fragment>
            ),
            navigate(`/addPaymentRecruitmentTeacher/${result.data.studentID}`),
          ];
        }
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createStudent" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content="Add New Student" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/student">
                  <BiUndo /> Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab
                eventKey="guardian"
                title={
                  submitValue.businessType === "B2B" ? "Center" : "Guardian"
                }
              >
                <Guardian
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}
                  setKey={setKey}
                  handleStudentList={handleStudentList}
                  studentList={studentList}
                  authState={authState}
                />
              </Tab>
              <Tab eventKey="student" title="Student">
                {submitValue.businessType === "B2B" ? (
                  <StudentB2B
                    submitValue={submitValue}
                    setSubmitValue={setSubmitValue}
                    setKey={setKey}
                    studentList={studentList}
                    setStudentList={setStudentList}
                    handleStudentList={handleStudentList}
                    addRow={addRow}
                    deleteRow={deleteRow}
                  />
                ) : (
                  <StudentB2C
                    submitValue={submitValue}
                    setSubmitValue={setSubmitValue}
                    setKey={setKey}
                    studentList={studentList}
                    setStudentList={setStudentList}
                    handleStudentList={handleStudentList}
                  />
                )}
              </Tab>
            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
              {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
