import React, { useState, useEffect } from "react";
import Axios from "axios";

function StudentLatestPayment(props) {
  const userID = props.id;
  const [viewLearningProgress, setViewLearningProgress] = useState([]);

  useEffect(() => {
    let userIDInfo = new FormData();
    userIDInfo.append("userID", userID);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}studentLatestPayment.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: userIDInfo,
    }).then((result) => {
      if (result.data === undefined || result.data.length == 0) {
        setViewLearningProgress("-");
      } else {
        setViewLearningProgress(result.data);
      }
    });
  }, [userID]);

  return (
    <>
      <a
        className={
          viewLearningProgress.status == 1
            ? "text-white bg-success text-decoration-none"
            : "text-dark bg-warning text-decoration-none"
        }
      >
        {viewLearningProgress.paymentType} {viewLearningProgress.paymentYear}
      </a>{" "}
      <a
        className={
          viewLearningProgress.totalPaymentCount >= 1
            ? "text-white bg-success text-decoration-none"
            : "text-dark bg-warning text-decoration-none"
        }
      >
        ({viewLearningProgress.totalPaymentCount})
      </a>
    </>
  );
}

export default StudentLatestPayment;
