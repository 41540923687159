const Venue= [
    {
      value: "Center",
      label: "Center",
    },
    {
      value: "School",
      label: "School",
    }
  ];
  
  export default Venue;
  