import React, { useState, useEffect } from "react";
import Axios from "axios";

function Name(props) {
  const venueID = props.id;
  const venueType = props.venueType;
  
  const [venueName, setVenueName] = useState("Venue");

  useEffect(() => {
    let venueInfo = new FormData();
    venueInfo.append("venueID", venueID);
    venueInfo.append("venueType", venueType);
    
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}venue.php/`,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: venueInfo,
    }).then ((result) => {
        setVenueName(result.data.venueName);
    })
  }, [venueID]);


  return <>{venueName}</>;
}

export default Name