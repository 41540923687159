const Gender= [

    {
      value: "B",
      label: "Boy",
    },
    {
      value: "G",
      label: "Girl",
    },
  ];
  
  export default Gender;
  