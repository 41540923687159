import React, { useContext, Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import DeleteIcon from '@mui/icons-material/Delete';
import { AiFillFilePdf, AiFillFileImage } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import Axios from "axios";
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import PaymentTypeTermOptions from "../../../../Data/PaymentTypeTermOptions";
import PaymentTypeMonthOptions from "../../../../Data/PaymentTypeMonthOptions";
import PaymentYearOptions from "../../../../Data/PaymentYearOptions"
import TextareaAutosize from 'react-textarea-autosize';
import { AuthContext } from "../../../../Helpers/AuthContext";
import moment from "moment";

function ConfirmPaymentDetails({
  setKey,
  submitValue,
  setSubmitValue,

  studentMaterialFeeArr,
  materialFeeSelectedArr,
  materialFeeAmountArr,
  materialFeeSelectedGroupArr,

  setMaterialFeeType,
  materialFeeType
}) {
  const textInput = "form-control";
  const { paymentID, studentID } = useParams();
  const { authState, setAuthState } = useContext(AuthContext);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [selectedMaterialOptions, setSelectedMaterialOptions] = useState([]);

  const [file, setFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([])
  const MAX_SIZE = 10; //file size max is 10mb
  const [inputReadOnly, setInputReadOnly] = useState(false);
  const [maxDate] = useState(moment().format("YYYY-MM-DD"));
  let previewObject="";
  let previewButton="";
  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    let tempfile = file;

    files.some((file) => {
      let currentFileSizeInMb = parseFloat(file.size / 1024 / 1024).toFixed(2);
      if (isValidFileUploaded(file) && currentFileSizeInMb < MAX_SIZE) {
        uploaded.push(file);
        tempfile.push(URL.createObjectURL(file));
        setFile((tempfile) => [...tempfile]);
        setUploadedFiles(uploaded);
        submitValue.newPaymentSlip = uploaded;
      } else if (!isValidFileUploaded(file)) {
        return toast.error(
          <Fragment>
            <span>Please only upload image or pdf file</span>
          </Fragment>
        );
      } else if (currentFileSizeInMb > MAX_SIZE) {
        return toast.error(
          <Fragment>
            <span>Uploaded file size is {currentFileSizeInMb}MB. Do not upload file bigger than 10MB</span>
          </Fragment>
        );
      }
    })

  }

  const isValidFileUploaded = (file) => {
    const validExtensions = ['png', 'jpeg', 'jpg', 'pdf']
    const fileExtension = file.type.split('/')[1];
    return validExtensions.includes(fileExtension);
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  }

  function deleteFile(e) {
    file.splice(uploadedFiles.indexOf(e),1);
    const s = uploadedFiles.filter((item, index) =>
      item !== e
    );
    setUploadedFiles(s);
    submitValue.newPaymentSlip = s;
  }

  const DeleteOriginalFile = (e) => {
    let imageInformationID = new FormData();
    imageInformationID.append("imageID", e.id);
    imageInformationID.append("currentUser", authState.id);
    imageInformationID.append("action", "deleteImage");
    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/verifyPayment.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: imageInformationID,

    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        const s = submitValue.originalPaymentSlip.filter((item, index) =>
          item !== e
        );
        submitValue.originalPaymentSlip = s;
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
        ];

      }
    });
  };

  useEffect(() => {
    let studentInformation = new FormData();
    studentInformation.append("studentID", studentID);
    studentInformation.append("paymentID", paymentID);
    studentInformation.append("requestOrigin", "verifyPayment");
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/getMaterialList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: studentInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setMaterialOptions(
          result.data[0].map(({ displayText, amount, id, group, titleText }) => ({
            label: displayText,
            amount: amount,
            value: id,
            group: group,
            titleText: titleText
          }))
        );

        setSelectedMaterialOptions(
          result.data[1].map(({ displayText, amount, id, group, titleText }) => ({
            label: displayText,
            amount: amount,
            value: id,
            group: group,
            titleText: titleText
          }))
        );

        setMaterialFeeType(result.data[2][0]);

        var temp = [];
        var temp2 = [];
        var amount = 0;

        result.data[1].forEach(e => {
          temp.push(e.id);
          temp2.push(e.group);
          amount = amount + Number(e.amount);
        });
        studentMaterialFeeArr(temp, amount, temp2, 0);

      }

    });
  }, [studentID]);

  let paymentType;
  if (submitValue.category == "P" || submitValue.category == "E") {
    paymentType = <Col sm={12} md={6} className="mb-3">
      <label className="form-label required">Confirm Payment Type</label>
      <div className="input-group">
        <Select
          options={PaymentYearOptions}
          className="basic-single form-control"
          classNamePrefix="select"
          onChange={(e) => {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              confirmPaymentYear: e.value,
            }))
          }}
          value={PaymentYearOptions.find(
            (obj) => obj.value == submitValue.confirmPaymentYear
          )}

        />
      <Select
        options={PaymentTypeTermOptions}
        className="basic-single form-control"
        classNamePrefix="select"
        onChange={(e) =>
          setSubmitValue((submitValue) => ({
            ...submitValue,
            confirmPaymentType: e.value,
          }))
        }
        value={PaymentTypeTermOptions.find(
          (obj) => obj.value === submitValue.confirmPaymentType
        )}
      />
      </div>
    </Col>
  }
  if (submitValue.category == "U" || submitValue.category == "K") {
    paymentType = <Col sm={12} md={6} className="mb-3">
      <label className="form-label required">Confirm Payment Type</label>
      <div className="input-group">
        <Select
          options={PaymentYearOptions}
          className="basic-single form-control"
          classNamePrefix="select"
          onChange={(e) => {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              confirmPaymentYear: e.value,
            }))
          }}
          value={PaymentYearOptions.find(
            (obj) => obj.value == submitValue.confirmPaymentYear
          )}
        />
        <Select
          options={PaymentTypeMonthOptions}
          className="basic-single form-control"
          classNamePrefix="select"
          onChange={(e) =>
            setSubmitValue((submitValue) => ({
              ...submitValue,
              confirmPaymentType: e.value,
            }))
          }
          value={PaymentTypeMonthOptions.find(
            (obj) => obj.value === submitValue.confirmPaymentType
          )}
        />
      </div>
    </Col>
  }

  return (
    <div>
      <h4>Confirm Payment Details</h4>
      <Container>
        <Row>
          {paymentType}
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Confirm Course Fee Amount</label>
            <input
              type="number"
              step="any"
              className={textInput}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value <= 0) {
                  e.target.value = 0;
                }
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  confirmCourseFee: e.target.value,
                }));
              }}
              value={Number(submitValue.confirmCourseFee)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Confirm Material Fee</label>
            <Multiselect
              displayValue="label"
              groupBy="titleText"
              showCheckbox
              options={materialOptions}
              style={{
                chips: {
                  whiteSpace: 'unset'
                }
              }}
              selectedValues={materialOptions.filter(
                (option) => {
                  for (let counter = 0; counter < selectedMaterialOptions.length; counter++) {
                    if (selectedMaterialOptions[counter].value === option.value && selectedMaterialOptions[counter].group === option.group) {
                      return option;
                    }
                  }
                }
              )}
              onSelect={(e) => {
                var temp = [];
                var temp2 = [];
                var amount = 0;

                e.forEach(e => {
                  temp.push(e.value);
                  temp2.push(e.group);
                  amount = amount + Number(e.amount);
                });
                studentMaterialFeeArr(temp, amount, temp2, 0);
              }}
              onRemove={(e) => {
                var temp = [];
                var temp2 = [];
                var amount = 0;

                e.forEach(e => {
                  temp.push(e.value);
                  temp2.push(e.group);
                  amount = amount + Number(e.amount);
                });
                studentMaterialFeeArr(temp, amount, temp2, 0);
              }}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Confirm Penalty Fee Amount</label>
            <input
              type="number"
              step="any"
              className={textInput}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value <= 0) {
                  e.target.value = 0;
                }
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  confirmPenaltyFee: e.target.value,
                }));
              }}
              value={Number(submitValue.confirmPenaltyFee)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Confirm Registration Fee</label>
            <input
              type="number"
              step="any"
              className={textInput}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value <= 0) {
                  e.target.value = 0;
                }
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  confirmRegistrationFee: e.target.value,
                }));
              }}
              value={Number(submitValue.confirmRegistrationFee)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Confirm Discount Amount</label>
            <input
              type="number"
              step="any"
              className={textInput}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value <= 0) {
                  e.target.value = 0;
                }
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  confirmDiscountAmount: e.target.value,
                }));
              }}
              value={Number(submitValue.confirmDiscountAmount)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Confirm Overpay or Underpay</label>
            <div class="input-group">
              <select
                class="custom-select"
                onChange={(e) => {
                  if (e.target.value == 0 || e.target.value == "settle") {
                    setInputReadOnly(true);
                    submitValue.confirmOverpayOrUnderpayAmount = 0;
                  } else if (e.target.value == "overpay" || e.target.value == "underpay") {
                    setInputReadOnly(false);
                  }

                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    confirmOverpayOrUnderpayStatus: e.target.value,
                  }));
                }}
              >
                <option value="0">Please select</option>
                <option value="overpay">Overpay</option>
                <option value="underpay">Underpay</option>
                <option value="settle">Settle</option>
              </select>
              <input
                type="number"
                readOnly={inputReadOnly}
                step="any"
                className="form-control"
                id="confirmOverpayOrUnderpayAmount"
                onWheel={(e) => e.target.blur()}
                value={submitValue.confirmOverpayOrUnderpayAmount}
                onChange={(e) => {
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    confirmOverpayOrUnderpayAmount: e.target.value,
                  }));
                }}
              />
            </div>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Confirm Total Fee</label>
            <input
              type="number"
              step="any"
              disabled
              className={textInput}
              value={(Number(submitValue.confirmCourseFee) + Number(submitValue.confirmPenaltyFee) + Number(materialFeeAmountArr[0]) - Number(submitValue.confirmDiscountAmount) + Number(submitValue.confirmRegistrationFee)).toFixed(2)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Account Remark</label>
            <TextareaAutosize
              type="number"
              className={textInput}
              onChange={(e) => {
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  accountRemark: e.target.value,
                }));
              }}
              value={submitValue.accountRemark}
            />
          </Col>
          <br></br>
          <hr></hr>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Confirm Transaction Date</label>
            <Tooltip title="Refer to date on the payment slip" placement="bottom">
              <span>
                <IoInformationCircle />
              </span>
            </Tooltip>
            <input
              type="date"
              className={textInput}
              max={maxDate}
              onChange={(e) => {
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  confirmTransactionDate: e.target.value,
                }));
              }}
              value={submitValue.confirmTransactionDate}
            />
          </Col>
          
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Payment Slip</label>
            <Tooltip title="Maximum file size is 10MB." placement="bottom">
              <span>
                <IoInformationCircle />
              </span>
            </Tooltip>
            <input
              type="file"
              accept='image/*,application/pdf'
              multiple
              className={textInput}
              onChange={(e) => {
                handleFileEvent(e);
              }}
            />
          </Col>
          <Col sm={12} md={6} className="form-group preview">
            <label className="form-label">Original Payment Slip</label>
            {submitValue.originalPaymentSlip.length > 0 &&
              submitValue.originalPaymentSlip.map((item, index) => {
                let extension = item.imagepath.split('.').pop();
                let originalFilePreviewButton="";
                let originalFilePreviewObject="";
                if (extension==="pdf"){
                  originalFilePreviewObject = <object data={process.env.REACT_APP_SERVER_DOMAIN+item.imageDirectory+"/"+item.imagepath} className="w-50 align-middle" type="application/pdf"><div>Cannot preview pdf file. Please click on the preview button to preview the pdf file in new tab.</div></object>;
                  originalFilePreviewButton = <Button variant="primary"  onClick={() => {window.open(process.env.REACT_APP_SERVER_DOMAIN+item.imageDirectory+"/"+item.imagepath);}}>
                  <AiFillFilePdf />
                </Button>;
                }else {
                  originalFilePreviewObject = <img src={process.env.REACT_APP_SERVER_DOMAIN+item.imageDirectory+"/"+item.imagepath} className="w-50"  width="250" height="200"/>;
                  originalFilePreviewButton = <Button variant="primary" onClick={() => {window.open(process.env.REACT_APP_SERVER_DOMAIN+item.imageDirectory+"/"+item.imagepath);}}>
                  <AiFillFileImage />
                </Button>;
                }
                return (
                  <Col key={index}>
                    {originalFilePreviewObject}
                    {originalFilePreviewButton}
                    {(submitValue.status != 1 && submitValue.status != 3) && (
                      <Button variant="danger"
                        onClick={() => {
                          if (window.confirm("Delete this payment slip image?")) {
                            DeleteOriginalFile(item)
                          }
                        }
                        }>
                        <DeleteIcon />
                      </Button>
                    )}

                  </Col>
                );
              })}
          </Col>
          <Col sm={12} md={6} className="form-group preview">
            <label className="form-label">New Payment Slip</label>
            {uploadedFiles.length > 0 &&
              uploadedFiles.map((item, index) => {
                if (item.type==="application/pdf"){
                  previewObject = <object data={file[index]} className="w-50 align-middle" type="application/pdf"><div>Cannot preview pdf file. Please click on the preview button to preview the pdf file in new tab.</div></object>;
                  previewButton = <Button variant="primary"  onClick={() => {window.open(file[index]);}}>
                  <AiFillFilePdf />
                </Button>;
                }else {
                  previewObject = <img src={file[index]} className="w-50"  width="250" height="200"/>;
                  previewButton = <Button variant="primary" onClick={() => {window.open(file[index]);}}>
                  <AiFillFileImage />
                </Button>;
                }
                return (
                  <Col key={index}>
                    {previewObject}
                    {previewButton}
                    <Button variant="danger"
                      onClick={() => {
                        if (window.confirm("Delete this payment slip image?")) {
                          deleteFile(item)
                        }
                      }
                      }>
                      <DeleteIcon />
                    </Button>
                  </Col>
                );
              })}
          </Col>
        </Row>
        {/* <Row>
          <Col sm={12}>
            <div className="float-end">
              <Button
                variant="outline-secondary"
                onClick={() => setKey("guardian")}
              >
                Next <BiChevronRight />
              </Button>
            </div>
          </Col>
        </Row> */}
      </Container>
    </div >
  );

}

export default ConfirmPaymentDetails;
