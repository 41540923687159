import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Axios from "axios";
import { Col, Container, Row, Button, Spinner, Alert } from "react-bootstrap";
import Heading from "../Heading";
import { BiUndo } from "react-icons/bi";
import { toast } from "react-toastify";
import State from "../../Data/State";

function Create() {
  const { authState, setAuthState } = useContext(AuthContext);
  const textInput = "form-control";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    schoolName: "",
    state: "",
  });

  let submitBtn = false;
  if (submitValue.schoolName !== "" && submitValue.state !== "") {
    submitBtn = true;
  }

  const submitForm = () => {
    setLoading(true);
    let createSchool = new FormData();
    createSchool.append("id", submitValue.id);
    createSchool.append("schoolName", submitValue.schoolName);
    createSchool.append("state", submitValue.state);

    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}School/create.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: createSchool,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/school"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createSchool" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content="New School" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-danger" size="sm" href="/school">
                <BiUndo /> Cancel
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">School Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    schoolName: e.target.value.toUpperCase(),
                  }))
                }
                value={submitValue.schoolName}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label">State</label>
              <Multiselect
                singleSelect
                displayValue="label"
                avoidHighlightFirstOption
                onRemove={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    state: e[0].value,
                  }))
                }
                onSearch={function noRefCheck() {}}
                onSelect={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    state: e[0].value,
                  }))
                }
                options={State}
              />
            </Col>
          </Row>
          <Row className="mx-3">
            <Alert variant="danger">
              <Alert.Heading>
                Rules to create School. Please follow the rules below!
              </Alert.Heading>
              <hr />
              <div className="mx-2">
                <p className="mb-1">
                  1. <span className="fw-bold">Cannot</span> key in{" "}
                  <span className="fw-bold">repeated</span> school name
                </p>
                <p className="mb-1">
                  2. SJKC <span className="fw-bold">without symbols</span>
                </p>
              </div>
              <div className="mx-2">
                <p className="mb-1">3. SJKC "NAME" , "DISTRICT"</p>
                <li className="mb-0 mx-2">Example: SJKC CHUNG HWA, KLANG</li>
              </div>
              <div className="mx-2">
                <p className="mb-1">
                  4. District Name cannot short form, such as Johor Bahru ,{" "}
                  <span className="fw-bold">Cannot type JB</span>
                </p>
              </div>

              <div className="mx-2 my-3">
                <h4>School Type Standard Code</h4>
                <p className="mb-1">1. Chinese Primary School: <span className="fw-bold">SJKC XXX</span></p>
                <li className="mb-0 mx-2">Example: SJKC FOON YEW 1, JOHOR BAHRU</li>
                <p className="mb-1">2. Malay Primary School: <span className="fw-bold">SK XXX</span></p>
                <li className="mb-0 mx-2">Example: SK CHUNG, KLANG</li>

                <p className="mb-1">3. Indian Primary School: <span className="fw-bold">SJKT XXX</span></p>
                <li className="mb-0 mx-2">Example: SJKT IMPIAN, RAWANG</li>

                <p className="mb-1">4. Secondary School: <span className="fw-bold">SMJK XXX</span></p>
                <li className="mb-0 mx-2">Example: SMJK LUK HWA, KAJANG</li>

                <p className="mb-1">5. Normal Secondary School: <span className="fw-bold">SMK XXX</span></p>
                <li className="mb-0 mx-2">Example: SMK BANGSAR, SHAH ALAM</li>

              </div>
              <div className="mx-2 my-3">
                <p className="fw-bold">*for actual school name can double check through headmaster or google maps</p>
              </div>
            </Alert>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Submit{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Create;
