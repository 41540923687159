import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { AiFillFilePdf, AiFillFileImage } from "react-icons/ai";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Axios from "axios";
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import TextareaAutosize from 'react-textarea-autosize';
import PaymentAdvanceOptions from "../../../../Data/PaymentAdvanceOptions";
import PaymentYearOptions from "../../../../Data/PaymentYearOptions";
import RequestReceiptOptions from "../../../../Data/RequestReceiptOptions";
import moment from "moment";

function PaymentDetails({
  setKey,
  submitValue,
  setSubmitValue,

  courseFeeArr,
  studentCourseFeeArr,

  paymentTypeArr,
  studentPaymentTypeArr,

  paymentYearArr,
  studentPaymentYearArr,

  penaltyFeeArr,
  studentPenaltyFeeArr,

  studentMaterialFeeArr,
  materialFeeSelectedArr,
  materialFeeAmountArr,

  studentTeacherRemarkArr,
  teacherRemarkArr,

  setMaterialFeeType,
  materialFeeType
}) {
  const textInput = "form-control";
  const optionInput = "form-select";
  const { studentID } = useParams();
  const [materialOptions, setMaterialOptions] = useState([]);

  const [file, setFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([])
  const MAX_SIZE = 10; //file size max is 10mb
  var totalAmount = 0;
  const [maxDate, setMaxDate] = useState(moment().format("YYYY-MM-DD"));
  let previewObject="";
  let previewButton="";

  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    let tempfile = file;

    files.some((file) => {
      let currentFileSizeInMb = parseFloat(file.size / 1024 / 1024).toFixed(2);
      if (isValidFileUploaded(file) && currentFileSizeInMb < MAX_SIZE) {
        uploaded.push(file);
        tempfile.push(URL.createObjectURL(file));
        setFile((tempfile) => [...tempfile]);
        setUploadedFiles(uploaded);
        submitValue.paymentSlip = uploaded;
      } else if (!isValidFileUploaded(file)) {
        return toast.error(
          <Fragment>
            <span>Please only upload image or pdf file</span>
          </Fragment>
        );
      } else if (currentFileSizeInMb > MAX_SIZE) {
        return toast.error(
          <Fragment>
            <span>Uploaded file size is {currentFileSizeInMb}MB. Do not upload file bigger than 10MB</span>
          </Fragment>
        );
      }
    })

  }

  const isValidFileUploaded = (file) => {
    const validExtensions = ['png', 'jpeg', 'jpg','pdf']
    const fileExtension = file.type.split('/')[1];
    return validExtensions.includes(fileExtension);
  }

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  }

  function deleteFile(e) {
    file.splice(uploadedFiles.indexOf(e),1);
    const s = uploadedFiles.filter((item, index) =>
      item !== e
    );
    setUploadedFiles(s);
    submitValue.paymentSlip = s;
  }

  useEffect(() => {
    let studentInformation = new FormData();
    studentInformation.append("studentID", studentID);
    studentInformation.append("requestOrigin", "addPayment");
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/Teacher/getMaterialList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: studentInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setMaterialOptions(
          result.data[0].map(({ displayText, id, amount, type }) => ({
            label: displayText,
            value: id,
            amount: amount,
            type: type
          }))
        );
        
        if (result.data[1][0] == ["installment"]) {
          studentMaterialFeeArr([result.data[0][0].id], result.data[0][0].amount, 0);
          //submitValue.materialFeeType = ;
          setMaterialFeeType("installment");
        } else {
          studentMaterialFeeArr([], 0, 0);
          setMaterialFeeType("fullPayment");
          //submitValue.materialFeeType = "fullPayment";
        }
        penaltyFeeArr[0] = 0;
        teacherRemarkArr[0] = "";
        paymentYearArr[0] = (new Date().getFullYear());
      }
    });
  }, [studentID]);

  const resetArr = (index) => {
    courseFeeArr.splice(0, courseFeeArr.length);
    materialFeeSelectedArr.splice(0, materialFeeSelectedArr.length);
    materialFeeAmountArr.splice(0, materialFeeAmountArr.length);
    penaltyFeeArr.splice(0, penaltyFeeArr.length);
    paymentYearArr.splice(0, paymentYearArr.length);
    teacherRemarkArr.splice(0, teacherRemarkArr.length);
    for (let counter = 0; counter < index; counter++) {
      courseFeeArr.push(submitValue.courseFee);
      penaltyFeeArr.push(0);
      paymentYearArr.push(new Date().getFullYear());
      teacherRemarkArr.push("");
      if (counter == 0 && materialOptions[0].type == "installment")
        studentMaterialFeeArr([materialOptions[0].value], materialOptions[0].amount, 0);
      else
        studentMaterialFeeArr([], 0, counter);
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Payment Mode</label>
            <div className="input-group">
              <select
                className={optionInput}
                onChange={(e) => {
                  var receiptCount = 0;

                  if (e.target.value == "monthly") {
                    receiptCount = 1;
                  } else if (e.target.value == "advance") {
                    receiptCount = 2;
                  }
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    payment: e.target.value,
                    receiptCounter: receiptCount,
                  }))
                  resetArr(receiptCount);
                }}
              >
                <option value="monthly">Monthly</option>
                <option value="advance">Advance</option>
              </select>
              <Select
                options={PaymentAdvanceOptions}
                className="basic-single form-control"
                classNamePrefix="select"
                onChange={(e) => {
                  var receiptCount = 0;
                  if (submitValue.payment == "monthly") {
                    receiptCount = 1;
                  } else if (submitValue.payment == "advance" && e.value == 1) {
                    receiptCount = 2;
                  } else {
                    receiptCount = e.value;
                  }
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    receiptCounter: receiptCount,
                  }))
                  resetArr(receiptCount);
                }}
                value={PaymentAdvanceOptions.find(
                  (obj) => obj.value === submitValue.receiptCounter
                )}
              />
            </div>
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Request Receipt</label>
            <Select
              options={RequestReceiptOptions}
              className="basic-single"
              classNamePrefix="select"
              onChange={(e) =>
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  requestReceipt: e.value,
                }))
              }
              value={RequestReceiptOptions.find(
                (obj) => obj.value === submitValue.requestReceipt
              )}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Material Fee</label>
            <Multiselect
              displayValue="label"
              showCheckbox
              options={materialOptions}
              style={{
                chips: {
                  whiteSpace: 'unset'
                }
              }}
              selectedValues={materialOptions.filter(
                (option) => {
                  for (let counter = 0; counter < materialFeeSelectedArr[0].length; counter++) {
                    if (materialFeeSelectedArr[0][counter] === option.value) {
                      return option;
                    }
                  }
                }
              )}
              onSelect={(e) => {
                var temp = [];
                var amount = 0;
                e.forEach(e => {
                  temp.push(e.value);
                  amount = amount + Number(e.amount);
                });
                studentMaterialFeeArr(temp, amount, 0);
              }}
              onRemove={(e) => {
                var temp = [];
                var amount = 0;
                e.forEach(e => {
                  temp.push(e.value);
                  amount = amount + Number(e.amount);
                });
                studentMaterialFeeArr(temp, amount, 0);
              }}
            />
          </Col>
          <hr></hr>
          {Array.from({ length: submitValue.receiptCounter }).map((_, idx) => {
            submitValue.paymentMode = 'Term';
            let paymentType;
            totalAmount = totalAmount + Number(courseFeeArr[idx]) + Number(materialFeeAmountArr[idx]) + Number(penaltyFeeArr[idx]);
            if (submitValue.category == "P" || submitValue.category == "E") {
              paymentType = <Col sm={12} md={6} className="mb-3">
                <label className="form-label required">Payment Type</label>
                <div className="input-group">
                  <Select
                    options={PaymentYearOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(e) => {
                      studentPaymentYearArr(e, idx);
                    }}
                    value={PaymentYearOptions.find(
                      (obj) => obj.value == paymentYearArr[idx]
                    )}

                  />
                  <select
                    className={optionInput}
                    onChange={(e) => {
                      studentPaymentTypeArr(e, idx);
                    }}
                  >
                    <option value="0">Please select</option>
                    <option value="Material Fee Only">Material Fee Only</option>
                    <option value="Term 1">Term 1</option>
                    <option value="Term 2">Term 2</option>
                    <option value="Term 3">Term 3</option>
                    <option value="Term 4">Term 4</option>
                    <option value="Term 5">Term 5</option>
                    <option value="Term 6">Term 6</option>
                    <option value="Term 7">Term 7</option>
                    <option value="Term 8">Term 8</option>
                    <option value="Term 9">Term 9</option>
                    <option value="Term 10">Term 10</option>
                    <option value="Term 11">Term 11</option>
                    <option value="Term 12">Term 12</option>
                    <option value="Term 13">Term 13</option>
                  </select>
                </div>
              </Col>
            }
            else if (submitValue.category == "U" || submitValue.category == "K") {
              submitValue.paymentMode = 'Month';
              paymentType = <Col sm={12} md={6} className="mb-3">
                <label className="form-label required">Payment Type</label>
                <div className="input-group">
                  <Select
                    options={PaymentYearOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(e) => {
                      studentPaymentYearArr(e, idx);
                    }}
                    value={PaymentYearOptions.find(
                      (obj) => obj.value == paymentYearArr[idx]
                    )}

                  />
                  <select
                    className={optionInput}
                    onChange={(e) => {
                      studentPaymentTypeArr(e, idx);
                    }}
                  >
                    <option value="0">Please select</option>
                    <option value="Material Fee Only">Material Fee Only</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </Col>
            }
            return (
              [
                <h4>Payment Details {idx + 1}</h4>,
                paymentType,
                <Col sm={12} md={6} className="mb-3">
                  <label className="form-label required">Course Fee Amount</label>
                  <input
                    type="number"
                    step="any"
                    className={textInput}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      studentCourseFeeArr(e, idx);
                    }}
                    value={Number(courseFeeArr[idx])}
                  />
                </Col>,
                <Col sm={12} md={6} className="mb-3">
                  <label className="form-label required">Penalty Fee Amount</label>
                  <input
                    type="number"
                    step="any"
                    className={textInput}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      studentPenaltyFeeArr(e, idx);
                    }}
                    value={Number(penaltyFeeArr[idx])}
                  />
                </Col>,
                <Col sm={12} md={6} className="mb-3">
                  <label className="form-label">Total Fee</label>
                  <input
                    type="number"
                    step="any"
                    disabled
                    className={textInput}
                    value={Number( Number(courseFeeArr[idx]) + Number(materialFeeAmountArr[idx]) + Number(penaltyFeeArr[idx]) ).toFixed(2)}
                  />
                </Col>,
                <Col sm={12} md={6} className="mb-3">
                  <label className="form-label">Teacher Remark</label>
                  <TextareaAutosize
                    className={textInput}
                    onChange={(e) => {
                      studentTeacherRemarkArr(e, idx);
                    }}
                    value={teacherRemarkArr[idx]}
                  />
                </Col>,
                <br></br>
              ]
            )
          })}
          <hr></hr>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label">Grand Total</label>
            <input
              type="number"
              step="any"
              disabled
              className={textInput}
              value={Number(totalAmount).toFixed(2)}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Transaction Date</label>
            <Tooltip title="Refer to date on the payment slip" placement="bottom">
              <span>
                <IoInformationCircle />
              </span>
            </Tooltip>
            <input
              type="date"
              className={textInput}
              max={maxDate}
              onChange={(e) => {
                setSubmitValue((submitValue) => ({
                  ...submitValue,
                  transactionDate: e.target.value,
                }));
              }}
              value={submitValue.transactionDate}
            />
          </Col>
          <Col sm={12} md={6} className="mb-3">
            <label className="form-label required">Payment Slip</label>
            <Tooltip title="Maximum file size is 10MB." placement="bottom">
              <span>
                <IoInformationCircle />
              </span>
            </Tooltip>
            <input
              type="file"
              accept='image/*,application/pdf'
              multiple
              className={textInput}
              onChange={(e) => {
                handleFileEvent(e);
              }}
            />
          </Col>
          <Col sm={12} md={6} className="form-group preview">
            <label className="form-label">Preview Image</label>
            {uploadedFiles.length > 0 &&
              uploadedFiles.map((item, index) => {
                if (item.type==="application/pdf"){
                  previewObject = <object data={file[index]} className="w-50 align-middle" type="application/pdf"><div>Cannot preview pdf file. Please click on the preview button to preview the pdf file in new tab.</div></object>;
                  previewButton = <Button variant="primary"  onClick={() => {window.open(file[index]);}}>
                  <AiFillFilePdf />
                </Button>;
                }else {
                  previewObject = <img src={file[index]} className="w-50"  width="250" height="200"/>;
                  previewButton = <Button variant="primary" onClick={() => {window.open(file[index]);}}>
                  <AiFillFileImage />
                </Button>;
                }
                return (
                  <Col key={index}>
                    {previewObject}
                    {previewButton}
                    <Button variant="danger" onClick={() => deleteFile(item)}>
                      <DeleteIcon />
                    </Button>
                  </Col>
                );
              })}
          </Col>

        </Row>
      </Container>
    </div >
  );

}

export default PaymentDetails;