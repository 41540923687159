import React, { useEffect, useState, Fragment } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import Profile from "../../Profile";
import Heading from "../../Heading";
import StudentName from "../../StudentName";
import StudentCode from "../../StudentCode";
import StudentLatestLearningProgress from "../../StudentLatestLearningProgress";
import { Col, Container } from "react-bootstrap";

function StudentList({ classID }) {
  const [viewStudentList, setViewStudentList] = useState([]);
  useEffect(() => {
    let getInfo = new FormData();
    getInfo.append("classID", classID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Index/Teacher/studentList.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: getInfo,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setViewStudentList(result.data);
      }
    });
  }, [classID]);

  return (
    <div className="mx-3">
      <Heading
        content={`Total Student: ${viewStudentList.length}`}
        design="h5"
      />

      {viewStudentList.map((viewStudentList, index) => {
        const studentID = viewStudentList.studentID;

        return (
          <div key={index}>
            <Container className="mb-2">
              <div className="d-flex flex-row align-items-center">
                <div className="mx-2">
                  <Profile id={studentID} />
                </div>
                <div>
                  <Col className="p-0 m-0 ">
                    <div className="d-flex flex-row align-items-center text-dark fw-bold">
                      <div className="p-1 text-capitalize">
                        <StudentName id={studentID} />
                      </div>
                      <div className="p-1">
                        <StudentCode id={studentID} />
                      </div>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <p className="mx-2">
                      {" "}
                      <StudentLatestLearningProgress id={studentID} />
                    </p>
                  </Col>
                </div>
              </div>
            </Container>
          </div>
        );
      })}
    </div>
  );
}

export default StudentList;
