import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import Read from "../Components/Class/Read";
import ReadTeacher from "../Components/Class/ReadTeacher";

import { Container, Row, Col, Button } from "react-bootstrap";
import { FaChalkboardTeacher } from "react-icons/fa";

function Class() {
  const { authState, setAuthState } = useContext(AuthContext);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <div>
        <Container className="my-3 p-3">
          <Row className="my-2">
            <Col sm={10}>
              <Heading content="Manage Class" design="h4" />
            </Col>
            {authState.role == "1" && (
              <Col sm={2}>
                <Button variant="primary" href="/createClass">
                  <FaChalkboardTeacher /> Create Class
                </Button>
              </Col>
            )}
          </Row>
          <Row className="my-5">
            {authState.role == 6 ? <ReadTeacher /> : <Read />}
          </Row>
        </Container>
      </div>
    </AuthContext.Provider>
  );
}

export default Class;
