import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import Heading from "../../Heading";
import PaymentDetails from "./AddPayment/PaymentDetails";
import { BiUndo } from "react-icons/bi";
import moment from "moment";

function AddPayment() {
  const { studentID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [key, setKey] = useState("paymentDetails");
  const [materialFeeType, setMaterialFeeType] = useState();
  const [buttonText, setButtonText] = useState("Submit");
  const [submitBtn, setSubmitBtn] = useState(false);
  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    studentOriID: studentID,
    studentID: "",
    category: "",
    payment: "",
    receiptCounter: 1,
    requestReceipt: "",
    paymentSlip: [],
    transactionDate: "",
    materialFeeType: "",
    paymentMode: ""
  });

  const [courseFeeArr, setcourseFeeArr] = useState([]);
  const studentCourseFeeArr = (e, index) => {
    let tempCourseFee = courseFeeArr;
    tempCourseFee[index] = e.target.value;
    setcourseFeeArr((tempCourseFee) => [...tempCourseFee]);
  };
  const [paymentTypeArr, setPaymentTypeArr] = useState([]);
  const studentPaymentTypeArr = (e, index) => {
    let tempPaymentTypeArr = paymentTypeArr;
    tempPaymentTypeArr[index] = e.target.value;
    setPaymentTypeArr((tempPaymentTypeArr) => [...tempPaymentTypeArr]);
  };
  const [paymentYearArr, setPaymentYearArr] = useState([]);
  const studentPaymentYearArr = (e, index) => {
    let tempPaymentYearArr = paymentYearArr;
    tempPaymentYearArr[index] = e.value;
    setPaymentYearArr((tempPaymentYearArr) => [...tempPaymentYearArr]);
  };
  const [materialFeeSelectedArr, setMaterialFeeSelectedArr] = useState([[]]);
  const [materialFeeAmountArr, setMaterialFeeAmountArr] = useState([]);
  const studentMaterialFeeArr = (e, f, index) => {
    let tempMaterialFeeSelected = materialFeeSelectedArr;
    tempMaterialFeeSelected[index] = e;
    setMaterialFeeSelectedArr((tempMaterialFeeSelected) => [...tempMaterialFeeSelected]);

    let tempMaterialFeeAmount = materialFeeAmountArr;
    tempMaterialFeeAmount[index] = f;
    setMaterialFeeAmountArr((tempMaterialFeeAmount) => [...tempMaterialFeeAmount]);
  };
  const [penaltyFeeArr, setPenaltyFeeArr] = useState([]);
  const studentPenaltyFeeArr = (e, index) => {
    let tempPenaltyFee = penaltyFeeArr;
    tempPenaltyFee[index] = e.target.value;
    setPenaltyFeeArr((tempPenaltyFee) => [...tempPenaltyFee]);
  };
  const [discountAmountArr, setDiscountAmountArr] = useState([]);
  const studentDiscountAmountArr = (e, index) => {
    let tempDiscountAMount = discountAmountArr;
    tempDiscountAMount[index] = e.target.value;
    setDiscountAmountArr((tempDiscountAMount) => [...tempDiscountAMount]);
  };
  const [registrationFeeArr, setRegistrationFeeArr] = useState([]);
  const studentRegistrationFeeArr = (e, index) => {
    let tempRegistrationFee = registrationFeeArr;
    tempRegistrationFee[index] = e.target.value;
    setRegistrationFeeArr((tempRegistrationFee) => [...tempRegistrationFee]);
  };
  const [teacherRemarkArr, setTeacherRemarkArr] = useState([]);
  const studentTeacherRemarkArr = (e, index) => {
    let tempTeacherRemark = teacherRemarkArr;
    tempTeacherRemark[index] = e.target.value;
    setTeacherRemarkArr((tempTeacherRemark) => [...tempTeacherRemark]);
  };

  useEffect(() => {
    let studentInformation = new FormData();
    studentInformation.append("studentID", studentID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/getStudentInformation.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: studentInformation,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setSubmitValue((submitValue) => ({
          ...submitValue,
          studentID: result.data[0].id,
          englishName: result.data[0].englishName,
          chineseName: result.data[0].chineseName,
          category: result.data[0].category,
          courseFee: result.data[0].courseFee,
          payment: "monthly",
          receiptCounter: 1,
          requestReceipt: "No",
          paymentSlip: [],
          transactionDate: ""
        }));
        courseFeeArr[0] = result.data[0].courseFee;
        if (result.data[0].category == "P" || result.data[0].category == "E") {
          submitValue.paymentMode = 'Term';
        }
        if (result.data[0].category == "U" || result.data[0].category == "K") {
          submitValue.paymentMode = 'Month';
        }
        
      }
    });
  }, [studentID]);

  const submitForm = () => {
    setButtonText("Processing");
    setSubmitBtn(true);
    let todaySubstractTwoYear = new moment().subtract(730, 'days');
   
    if (
      submitValue.paymentSlip == "" ||
      submitValue.payment == "" ||
      submitValue.receiptCounter == "" ||
      submitValue.transactionDate == "" ||
      moment(submitValue.transactionDate).isAfter(moment().format('YYYY-MM-DD'), 'day')||
      !moment(submitValue.transactionDate).isAfter(todaySubstractTwoYear, 'day')
    ) {
      setButtonText("Submit");
      setSubmitBtn(false);
      return toast.error(
        <Fragment>
          <span>{"Please fill in all the required field marked by * properly"}</span>
        </Fragment>
      );

    }

    for (let counter = 0; counter < submitValue.receiptCounter; counter++) {
      if (paymentTypeArr[counter] == "0" || paymentTypeArr[counter] == undefined) {
        setButtonText("Submit");
        setSubmitBtn(false);
        return toast.error(
          <Fragment>
            <span>{"Please fill in all the required field marked by * properly"}</span>
          </Fragment>
        );

      }
    }

    let addPayment = new FormData();
    let submitPayment = [];


    addPayment.append("id", submitValue.id);
    addPayment.append("studentOriID", submitValue.studentOriID);
    addPayment.append("studentID", submitValue.studentID);

    addPayment.append("payment", submitValue.payment);
    addPayment.append("receiptCounter", submitValue.receiptCounter);
    addPayment.append("requestReceipt", submitValue.requestReceipt);
    addPayment.append("materialFeeType", materialFeeType);
    addPayment.append("paymentMode", submitValue.paymentMode);
    addPayment.append("transactionDate", submitValue.transactionDate);
    addPayment.append("currentUser", authState.id);
    for (var i = 0; i < submitValue.receiptCounter; i++) {
      submitPayment.push({
        studentID: submitValue.studentID,
        paymentType: paymentTypeArr[i],
        paymentYear: paymentYearArr[i],
        courseFee: courseFeeArr[i],
        penaltyFee: penaltyFeeArr[i],
        discountAmount: discountAmountArr[i],
        registrationFee: registrationFeeArr[i],
        materialFeeSelected: materialFeeSelectedArr[i],
        materialFeeAmount: materialFeeAmountArr[i],
        teacherRemark: teacherRemarkArr[i],
      });
    }

    const submitPaymentJson = JSON.stringify(submitPayment);
    addPayment.append("submitPaymentJson", submitPaymentJson);

    for (let i = 0; i < submitValue.paymentSlip.length; i++) {
      addPayment.append("paymentSlip[]", submitValue.paymentSlip[i]);
    }

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Payment/RecruitmentTeacher/addPayment.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: addPayment,

    }).then((result) => {
      if (result.data.error) {
        setButtonText("Submit");
        setSubmitBtn(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else {
        setButtonText("Submit");
        setSubmitBtn(false);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/viewPaymentRecruitmentTeacher/" + submitValue.studentID),
        ];

      }
    });
  };
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="addPayment" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={11}>
              <Heading content={`Add Payment for ${submitValue.englishName} ${submitValue.chineseName}`} design="h5" />
            </Col>
            <Col sm={2} md={2} lg={1}>
              <Button variant="outline-secondary" size="sm" className="float-end" href="/payment">
                <BiUndo /> Back
              </Button>
            </Col>
          </Row>
          <Row className="my-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="paymentDetails" title="Payment Details">
                <PaymentDetails
                  setKey={setKey}
                  submitValue={submitValue}
                  setSubmitValue={setSubmitValue}

                  courseFeeArr={courseFeeArr}
                  studentCourseFeeArr={studentCourseFeeArr}

                  paymentTypeArr={paymentTypeArr}
                  studentPaymentTypeArr={studentPaymentTypeArr}

                  paymentYearArr={paymentYearArr}
                  studentPaymentYearArr={studentPaymentYearArr}

                  penaltyFeeArr={penaltyFeeArr}
                  studentPenaltyFeeArr={studentPenaltyFeeArr}

                  discountAmountArr={discountAmountArr}
                  studentDiscountAmountArr={studentDiscountAmountArr}

                  registrationFeeArr={registrationFeeArr}
                  studentRegistrationFeeArr={studentRegistrationFeeArr}

                  studentMaterialFeeArr={studentMaterialFeeArr}
                  materialFeeSelectedArr={materialFeeSelectedArr}
                  materialFeeAmountArr={materialFeeAmountArr}

                  studentTeacherRemarkArr={studentTeacherRemarkArr}
                  teacherRemarkArr={teacherRemarkArr}

                  setMaterialFeeType={setMaterialFeeType}
                  materialFeeType={materialFeeType}
                />
              </Tab>

            </Tabs>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">

                <Button variant="primary" disabled={submitBtn} onClick={submitForm}>
                  {buttonText}{" "}
                </Button>

              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default AddPayment;
