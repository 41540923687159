import React, { Fragment, useContext, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

import { AuthContext } from "../Helpers/AuthContext";
import useInput from "../Helpers/use-input";

import Footer from "../Components/Footer";
import Heading from "../Components/Heading";
import Logo from "../Components/Logo";

const isNotPhone = (value) => value.match(/^\d{10,12}$/);
const isNotPassword = (value) =>
  value.match(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/
  );

function SignIn() {
  //SESSION
  const { setAuthState } = useContext(AuthContext);

  const [currentPassword, setCurrentPassword] = useState(false);

  const toggleCurrentPassword = () => {
    setCurrentPassword(!currentPassword);
  };


  // Phone Input Check
  const {
    value: enteredPhone,
    isValid: enteredPhoneIsValid,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhone,
  } = useInput(isNotPhone);

  // Password Input Check
  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(isNotPassword);

  let loginFormIsValid = false;

  if (enteredPhoneIsValid && enteredPasswordIsValid) {
    loginFormIsValid = true;
  }

  const logInFormSubmissionHandler = (event) => {
    event.preventDefault();

    if (!enteredPhoneIsValid && !enteredPasswordIsValid) {
      return;
    } else {
      let logInForm = new FormData();
      logInForm.append("phoneNumber", enteredPhone);
      logInForm.append("password", enteredPassword);

      Axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}login.php/`,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        data: logInForm,
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
          localStorage.setItem("accessToken", result.data.accessToken);
          setAuthState({
            id: result.data.id,
            role: result.data.role,
            status: true,
          });

          return toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          );
        }
      });
    }
    resetPhone();
    resetPassword();
  };

  const phoneClasses = phoneInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  const passwordClasses = passwordInputHasError
    ? "form-control form-control-sm is-invalid"
    : "form-control form-control-sm";

  return (
    <div>
      <Container className="d-flex flex-column vh-100 justify-content-center align-items-center">
        <Row className="w-75">
          <Col lg={4} md={4} sm={4} xs={5} className="mx-auto">
            <Logo design="img-fluid" />
          </Col>
          <Col lg={8} md={8} sm={8} xs={12}>
            <div>
              <Heading content="Sign In Now!" design="h4 mb-4 text-center" />
              <Form onSubmit={logInFormSubmissionHandler}>
                <Col sm={12} className="mb-3">
                  <label className="form-label required">Contact Number</label>
                  <input
                    type="text"
                    id="inputphone"
                    placeholder="Eg: 60XXXXXXXXX"
                    className={phoneClasses}
                    onChange={phoneChangeHandler}
                    onBlur={phoneBlurHandler}
                    value={enteredPhone}
                  />
                  {phoneInputHasError && (
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid Contact Number, eg: 60XXXXXXXXX.
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col sm={12} className="mb-3">
                  <label className="form-label required">Password</label>
                  <div className="input-group">
                  <input
                    type={currentPassword ? "text" : "password"}
                    id="inputPassword"
                    placeholder="Password"
                    className={passwordClasses}
                    onChange={passwordChangeHandler}
                    onBlur={passwordBlurHandler}
                    value={enteredPassword}
                  />
                  <span
                    className="input-group-text"
                    onClick={toggleCurrentPassword}
                  >
                    {currentPassword === true ? (
                      <BsEyeFill />
                    ) : (
                      <BsEyeSlashFill />
                    )}
                  </span>
                  </div>
                  
                  {passwordInputHasError && (
                    <Form.Control.Feedback type="invalid">
                      Must contain more than 8 characters which contain at least
                      one lowercase letter, one uppercase letter, one numeric
                      digit, and one special character.
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col sm={12} className="mb-3 text-center mx-auto">
                  <button
                    type="submit"
                    className=" btn btn-primary"
                    disabled={!loginFormIsValid}
                  >
                    Log In
                  </button>
                </Col>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default SignIn;
