import React, {
  forwardRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Helpers/AuthContext";
import { toast } from "react-toastify";

import { BiEdit, BiPlus } from "react-icons/bi";
import moment from "moment";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

function Read() {
  const navigate = useNavigate();

  const { authState, setAuthState } = useContext(AuthContext);
  const [classList, setClassList] = useState([]);
  useEffect(() => {
    let readByID = new FormData();
    readByID.append("id", authState.id);
    if (authState.role == 6) {
      Axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}Class/readbyTeacher.php/`,
        config: {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        },
        data: readByID,
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
          setClassList(
            result.data.map((classListData) => ({
              classCode: classListData.classCode,
              teacherName: classListData.teacherName,
              trainerName: classListData.trainerName,
              latestClassDate: classListData.latestClassDate,
              day: classListData.day,
              startTime: classListData.startTime,
              endTime: classListData.endTime,
              learningType: classListData.learningType,
              category: classListData.category,
              lessonPlan: classListData.lessonPlan,
              language: classListData.language,
              remark: classListData.remark,
              status: classListData.status,
              latestProgress: classListData.lpTitle,
            }))
          );
        }
      });
    } else {
      Axios({
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_DOMAIN}Class/read.php/`,
        config: {
          headers: {
            "Content-Type": "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        },
      }).then((result) => {
        if (result.data.error) {
          return toast.error(
            <Fragment>
              <span>{result.data.error}</span>
            </Fragment>
          );
        } else if (result.data.warn) {
          return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
        } else {
          setClassList(
            result.data.map((classListData) => ({
              classID: classListData.id,
              classCode: classListData.classCode,
              teacherName: classListData.teacherName,
              trainerName: classListData.trainerName,
              activeStudentNumber: classListData.activeStudentNumber,
              venueName: classListData.venueName,
              latestClassDate: classListData.latestClassDate,
              day: classListData.day,
              startTime: classListData.startTime,
              endTime: classListData.endTime,
              learningType: classListData.learningType,
              category: classListData.category,
              lessonPlan: classListData.lessonPlan,
              language: classListData.language,
              remark: classListData.remark,
              status: classListData.status,
              latestProgress: classListData.lpTitle,
            }))
          );
        }
      });
    }
  }, []);
  
  const columns = [
    { title: "Class Code", field: "classCode", editable: "never" },
    { title: "Teacher Name", field: "teacherName", editable: "never" },
    { title: "Trainer Name", field: "trainerName", editable: "never" },
    { title: "Active Student Number", field: "activeStudentNumber", editable: "never" },
    {
      title: "Last Attendance Taken",
      field: "latestClassDate",
      editable: "never",
      type: "numeric",
    },
    {
      title: "Latest Learning progress",
      field: "latestProgress",
      editable: "never",
    },
    { title: "Day", field: "day", editable: "never" },
    { title: "Start", field: "startTime", editable: "never" },
    { title: "End", field: "endTime", editable: "never" },
    {
      title: "Type",
      field: "learningType",
      lookup: { O: "Online", P: "Physical" },
    },
    { title: "Venue Name", field: "venueName", editable: "never" },
    {
      title: "Category",
      field: "category",
      lookup: { P: "Primary", E: "PEK", U: "UOC", K: "Kindergarten", I: "Internatonal School" },
    },
    { title: "Lesson Plan", field: "lessonPlan", editable: "never",
    lookup: { Kinder: "Kinder", PE123: "PE123", PE456: "PE456" },
   },
    { title: "Language", field: "language", editable: "never" },
    { title: "Remark", field: "remark", editable: "never" },
    {
      title: "Status",
      field: "status",
      editable: "never",
      lookup: { 1: "Active", 0: "Inactive" },
    },
  ];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <div id="readClass">
          <MaterialTable
            title=""
            columns={columns}
            data={classList}
            icons={tableIcons}
            options={{
              sorting: true,
              filtering: true,
              search: true,
              searchFieldAlignment: "right",
              searchAutoFocus: true,
              searchFieldVariant: "standard",
              paging: true,
              pageSize: 25,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [25, 50, 75, 100],
              exportButton: true,
              exportAllData: true,
              exportFileName: "Total Class",
              // actionsColumnIndex: -1,
              selection: false,
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              grouping: false,
              columnsButton: true,
              displayRowCheckbox: false,
            }}
            actions={[
              {
                icon: () => <BiEdit />,
                tooltip: "Edit",
                isFreeAction: false,
                onClick: (event, rowData) =>
                  navigate(`/updateClass/${rowData.classID}`),
              },
            ]}
          />
        </div>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Read;
