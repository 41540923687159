import React, { Fragment, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Helpers/AuthContext";

import Admin from "../Components/Index/Admin";
import Teacher from "../Components/Index/Teacher";
import ClassList from "../Components/Index/ClassList";

import { Container, Row } from "react-bootstrap";

function Index() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/signin");
    } else {
      navigate("/");
    }
  }, [authState.id]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container>
          <Row className="my-3">
            {/* Access by Admin */}
            {authState.role === "1" && <Admin />}

            {/* Access by Salesperson */}
            {authState.role === "2" && <Admin />}

            {/* Access by Teacher */}
            {authState.role === "6" && <Teacher userID={authState.id} />}
            {authState.role === "6" && <ClassList userID={authState.id} />}

            {/* {authState.role === "1" && <ClassList userID={authState.id} />} */}
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Index;
