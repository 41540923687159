const PaymentTypeTermOptions= [
  {
    value: "Material Fee Only",
    label: "Material Fee Only",
  },
  {
    value: "Term 1",
    label: "Term 1",
  },
  {
    value: "Term 2",
    label: "Term 2",
  },
  {
    value: "Term 3",
    label: "Term 3",
  },
  {
    value: "Term 4",
    label: "Term 4",
  },
  {
    value: "Term 5",
    label: "Term 5",
  },
  {
    value: "Term 6",
    label: "Term 6",
  },
  {
    value: "Term 7",
    label: "Term 7",
  },
  {
    value: "Term 8",
    label: "Term 8",
  },
  {
    value: "Term 9",
    label: "Term 9",
  },
  {
    value: "Term 10",
    label: "Term 10",
  },
  {
    value: "Term 11",
    label: "Term 11",
  },
  {
    value: "Term 12",
    label: "Term 12",
  },
  {
    value: "Term 13",
    label: "Term 13",
  },
];

export default PaymentTypeTermOptions;