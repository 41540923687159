import React from "react";
import Read from "./RecruitmentTeacher/Read"


function RecruitmentTeacher() {
    return (
        <>
        <Read />
        </>
    )
}

export default RecruitmentTeacher