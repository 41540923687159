import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";

import Card from "./Card";

function Teacher(props) {
    const userID = props.userID;
  const [countUser, setCountUser] = useState([
    {
      student: 0,
      class: 0
    },
  ]);

  useEffect(() => {
    let teacherID = new FormData();
    teacherID.append("userID", userID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Index/teacher.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: teacherID
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        setCountUser(result.data);
      }
    });
  }, [userID]);

  return (
    <>
      <Card
        content="Total Student"
        amount={countUser.student}
        icon={<FaUserGraduate size={35} />}
      />
      <Card
        content="Total Class"
        amount={countUser.class}
        icon={<FaChalkboardTeacher size={35} />}
      />
    </>
  );
}

export default Teacher;
