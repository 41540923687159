import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import Teacher from "../Components/Payment/Teacher"
import RecruitmentTeacher from "../Components/Payment/RecruitmentTeacher";

import { Container, Row, Col } from "react-bootstrap";

function Payment() {
  const { authState, setAuthState } = useContext(AuthContext);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
    <div>
    <Container className="my-3">
          <Row className="">
            <Col>
            {authState.role === "6" && <Heading content="Student List for Payment" design="h4" />}
            {authState.role === "2" && <Heading content="Student List for Payment" design="h4" />}
            {authState.role === "1" && <Heading content="Student List for Payment" design="h4" />}
            </Col>
          </Row>
          <Row className="my-5">
            {authState.role === "6" && <Teacher />}
            {authState.role === "2" && <RecruitmentTeacher />}
            {authState.role === "1" && <RecruitmentTeacher />}
          </Row>
          
        </Container>
    </div>
    </AuthContext.Provider>

  )
}

export default Payment