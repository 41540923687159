import React, { useContext, Fragment, useState, useEffect } from "react";
import { AuthContext } from "../../Helpers/AuthContext";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { BiUndo } from "react-icons/bi";
import Heading from "../Heading";
import TeacherType from "../../Data/TeacherType";


function Update() {
  const { accID } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const textInput = "form-control";
  const optionInput = "form-select";


  const [submitValue, setSubmitValue] = useState({
    id: authState.id,
    accID: accID,
    name: "",
    nickName: "",
    phoneNumber: "",
    role: "",
    teacherType: "",
    status: "",
  });

  useEffect(() => {
    let updateStudent = new FormData();
    updateStudent.append("accID", accID);
    Axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Account/update.php/`,
      config: {
        headers: {
          "Content-Type": "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      },
      data: updateStudent,
    }).then((result) => {
      if (result.data.error) {
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn) {
        return toast.warn(
          <Fragment>
            <span>{result.data.warn}</span>
          </Fragment>
        );
      } else {
        let phoneNumber;
        if (result.data[0].phoneNumber > 0) {
            phoneNumber = "+" + result.data[0].phoneNumber;
        } else {
            phoneNumber = "";
        }


        setSubmitValue((submitValue) => ({
          ...submitValue,
          name: result.data[0].name,
          nickName: result.data[0].nickName,
          phoneNumber: phoneNumber,
          role: result.data[0].role,
          teacherType: result.data[0].teacherType,
          status: result.data[0].status,
        }));

        if(result.data[0].nickName === null) {
            setSubmitValue((submitValue) => ({
                ...submitValue,
                nickName: "",
              }));
        }

        if(result.data[0].teacherType === null) {
            setSubmitValue((submitValue) => ({
                ...submitValue,
                teacherType: "",
              }));
        }
      }
    });
  }, [accID]);

  let teacherTypeOptions = [];
  TeacherType.map((item) => {
    teacherTypeOptions.push(
      <option value={item.value} key={item.label}>
        {item.label}
      </option>
    );
  });




  let submitBtn = false;
  if (
    submitValue.name !== "" &&
    submitValue.phoneNumber !== "" &&
    submitValue.role !== ""
  ) {
    if (submitValue.role == 6 && submitValue.teacherType === "") {
      submitBtn = false;
    } else {
      submitBtn = true;
    }
  }

  const submitForm = () => {
    setLoading(true);

    let updateAccount = new FormData();
    updateAccount.append("id", submitValue.id);
    updateAccount.append("accID", submitValue.accID);
    updateAccount.append("name", submitValue.name);
    updateAccount.append("nickName", submitValue.nickName);
    updateAccount.append("phoneNumber", submitValue.phoneNumber);
    updateAccount.append("role", submitValue.role);
    updateAccount.append("teacherType", submitValue.teacherType);
    updateAccount.append("status", submitValue.status);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_DOMAIN}Account/updateID.php/`,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: updateAccount,
    }).then((result) => {
      if (result.data.error) {
        setLoading(false);
        return toast.error(
          <Fragment>
            <span>{result.data.error}</span>
          </Fragment>
        );
      } else if (result.data.warn){
        setLoading(false);
        return toast.warn(
            <Fragment>
              <span>{result.data.warn}</span>
            </Fragment>
          );
      } else {
        setLoading(true);
        return [
          toast.success(
            <Fragment>
              <span>{result.data.success}</span>
            </Fragment>
          ),
          navigate("/account"),
        ];
      }
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <Fragment>
        <Container id="createStudent" className="my-5">
          <Row>
            <Col sm={10} md={10} lg={10}>
              <Heading content="Update Account" design="h5" />
            </Col>
            <Col sm={2} md={2} lg={2}>
              <div className="float-end">
                <Button variant="outline-danger" size="sm" href="/account">
                  <BiUndo /> Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    name: e.target.value,
                  }))
                }
                value={submitValue.name}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label">Nick Name</label>
              <input
                type="text"
                className={textInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    nickName: e.target.value,
                  }))
                }
                value={submitValue.nickName}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Phone Number</label>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="MY"
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    phoneNumber: e,
                  }))
                }
                value={submitValue.phoneNumber}
              />
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Role</label>
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    role: e.target.value,
                  }))
                }
                value={submitValue.role}
              >
                <option value="">Please Select</option>
                <option value="1">Admin</option>
                <option value="2">Enrollment Teacher</option>
                <option value="3">Scheduler</option>
                <option value="4">Guardian</option>
                <option value="5">Trainer</option>
                <option value="6">Teacher</option>
              </select>
            </Col>
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Status</label>
              <select
                className={optionInput}
                onChange={(e) =>
                  setSubmitValue((submitValue) => ({
                    ...submitValue,
                    status: e.target.value,
                  }))
                }
                value={submitValue.status}
              >
                <option value="">Please Select</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </Col>
            {submitValue.role === "6" ? (
            <Col sm={12} md={6} className="mb-3">
              <label className="form-label required">Teacher Type</label>
              <select
                  className={optionInput}
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      teacherType: e.target.value,
                    }))
                  }
                  value={submitValue.teacherType}
                >
                  <option value="">Please Select</option>
                  {teacherTypeOptions}
                </select>
            </Col>
            ) : null}
            
          </Row>
          <Row>
            <Col sm={12}>
              <div className="float-end">
                {loading ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    <span>Processing...</span>
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={!submitBtn}
                  >
                    Update{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </AuthContext.Provider>
  );
}

export default Update;
