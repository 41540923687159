import React, { useContext } from "react";
import { AuthContext } from "../Helpers/AuthContext";

import Heading from "../Components/Heading";
import Read from "../Components/Center/Read";
import ReadSalesPerson from "../Components/Center/ReadSalesPerson";

import { Container, Row, Col, Button } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";

function Center() {
  const { authState, setAuthState } = useContext(AuthContext);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <div>
        <Container className="my-3 p-3">
          <Row className="my-2">
            <Col sm={10}>
              <Heading content="Manage Center" design="h4" />
            </Col>

            {authState.id == 17 && (
              <Col sm={2}>
                <Button variant="primary" href="/createCenter">
                  <BiPlus /> Center
                </Button>
              </Col>
            )}

            {authState.role == 2 && (
              <Col sm={2}>
                <Button variant="primary" href="/createCenter">
                  <BiPlus /> Center
                </Button>
              </Col>
            )}
          </Row>
          <Row className="my-5">
            {authState.role == 2 ? <ReadSalesPerson /> : <Read />}
          </Row>
        </Container>
      </div>
    </AuthContext.Provider>
  );
}

export default Center;
